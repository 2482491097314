/* eslint-disable react/jsx-key */
import BackButton from '../../components/BackButton'
import Alarm from './components/AlarmComponent'
import DataCompleteness from './components/DataCompleteness'
import DataQuality from './components/ThirtyDaysTrend'
import LanguageStrings from '../../i18n/locales'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { type DeviceListState } from './redux/deviceListSlice'
import { type RootState } from '../../store'
import { type ConnectedProps, connect, useDispatch } from 'react-redux'
import { fetchLifeCycleBreakdownDonutData, fetchBatteryBreakdownDonutData, fetchAlarmBreakdownDonutData, fetchDeviceList, fetchEcModeDonutData, fetchFirmwareBreakdownDonutData, fetchKpiDailyData, fetchKpiUserPreference, fetchLastReadAlarmsKpi, fetchSignalBreakdownDonutData, fetchThirtyDayData, fetchTopIssuesData, fetchTotalConnectedKpi, fetchTotalReadsDonutData, fetchTotalRegisteredKpi, fetchTotalShippedKpi, fetchTotalStaleKpi, saveKpiPreference } from './redux/actionCreators'
import { useAuth } from 'react-oidc-context'
import { Grid } from '@mui/material'
import { Button } from '../../components/Button'
import { type thirtyDayState } from './redux/thirtyDaySlice'
import { changeGrandParentBreadcrumb, grandParentBreadcrumbState } from '../dashboard/redux/grandParentBreadcrumbSlice'
import { type AlarmDataState } from './redux/allAlarmsSlice'
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from 'react-grid-dnd'
import dayjs, { type Dayjs } from 'dayjs'
import { type kpiDailyState } from './redux/kpiDailySlice'
import SearchBox from '../../components/SearchBox'
import { GenXSelectableList } from '../../components/GenXSelectableList'
import { type TotalRegisteredState } from './redux/KpiSlices/totalRegisteredSlice'
import BarKPI from './components/KpiComponents/BarKPI'
import { type DateRange } from '@mui/x-date-pickers-pro'
import { type TotalConnectedState } from './redux/KpiSlices/totalConnectedSlice'
import { buildDonutChartData, buildLastReadAlarmsReportData, buildTotalConnectedReportData, buildTotalRegisteredReportData, buildTotalShippedReportData, buildTotalStaleReportData } from './components/KpiComponents/BuildKpiData'
import { AvailableKpiTypes, type KpiKey } from './redux/changeKpiType'
import { type TotalShippedState } from './redux/KpiSlices/totalShippedSlice'
import { getDonutKpiService, getKpiIdFromName, getKpiNameFromId, getKpiService } from './components/KpiComponents/kpiUtils'
import { type TotalStaleState } from './redux/KpiSlices/totalStaleSlice'
import { type KpiUserPreferenceState } from './redux/getKpiPreferenceSlice'
import { type DonutKpiState, type KpiPreferenceObj, type KpiPreferenceRequest } from './types'
import DonutKPI from './components/KpiComponents/DonutKpi'
import { type LastReadAlarmsState } from './redux/KpiSlices/lastReadAlarmsSlice'

const AMIMeterCollectionStrings = LanguageStrings().AMIDataCollectionStrings.LandingPage
const DeviceDetailsStrings = LanguageStrings().DeviceDetailsStrings

const defaultDate = {
  end: dayjs(new Date()),
  start: dayjs(new Date((new Date()).setMonth(new Date().getMonth() - 1)))
}

const kpiDefaultDate = {
  end: dayjs(new Date()),
  start: dayjs(new Date((new Date()).setDate(new Date().getDate() - 6)))
}

const AmiMeterCollection = (props: PropsFromRedux): JSX.Element => {
  // istanbul ignore next
  const fetchThirtydata = (): any => {
    if (start !== (undefined) && start !== null && end !== undefined && end !== null && start <= end) {
      const startMoment = start
        .clone()
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
      const endMoment = end
        .clone()
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59)
      props.fetchThirtyDayData(auth.user?.profile.customer as string, startMoment.format('YYYY-MM-DDTHH:mm:ssZ'), endMoment.format('YYYY-MM-DDTHH:mm:ssZ'))
    }
  }
  const fetchKpis = (): void => {
    props.fetchKpiDailyData()
  }
  const [alignment, setAlignment] = React.useState<string | null>('list')
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const dataCompRef = useRef(null)
  const [searchVal, setSearchVal] = React.useState<string>('')
  const [selectedKpi, setSelectedKpi] = useState<KpiKey[]>([])
  const [kpiThatHaveData, setKpiThatHaveData] = useState<KpiKey[]>([])
  const [kpiTable, setKpiTable] = useState<KpiKey[]>(AvailableKpiTypes)
  const [dateRange, setDateRange] = useState<DateRange<dayjs.Dayjs>>([kpiDefaultDate.start, kpiDefaultDate.end])
  const [timeoutContainer, setTimeoutContainer] = useState<NodeJS.Timeout | null>(null)
  const dashboardRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    props.fetchKpiUserPreference(auth.user?.profile.preferred_username as string)
  }, [])

  function triggerSavePreference (): void {
    // Clear previous timeout if exists
    if (timeoutContainer !== null) {
      clearTimeout(timeoutContainer)
      setTimeoutContainer(null)
    }

    let cnt = 1
    const kpiPreferences: KpiPreferenceObj[] = []
    selectedKpi.forEach((kpi) => {
      const kpiId = getKpiIdFromName(kpi)
      if (kpiId !== undefined) {
        kpiPreferences.push({ kpiId, order: cnt++ })
      }
    })

    // Set new timeout and update timeoutContainer
    const newTimeout = setTimeout(() => {
      props.saveKpiUserPreference({
        userId: auth.user?.profile.preferred_username as string,
        kpiPreferences
      })
      setTimeoutContainer(null) // Reset timeoutContainer after timeout
    }, 3000)

    setTimeoutContainer(newTimeout) // Update timeoutContainer
  }

  useEffect(() => {
    if (selectedKpi.length > 0) {
      triggerSavePreference()
    }
  }, [selectedKpi])

  // istanbul ignore next
  const handleKpiRemove = (kpi: KpiKey): void => {
    setSelectedKpi(prevSelectedKpi => prevSelectedKpi.filter(row => row !== kpi))
    setKpiTable(prevKpiTable => [...prevKpiTable, kpi])
  }

  function fetchTotalRegistered (): void {
    props.fetchTotalRegistered(dateRange[0]?.format('YYYY-MM-DD') as string, dateRange[1]?.format('YYYY-MM-DD') as string, 'total-registered', getKpiService('Total Registered'), getKpiIdFromName('Total Registered') as string)
  }

  function fetchTotalConnected (): void {
    props.fetchTotalConnected(dateRange[0]?.format('YYYY-MM-DD') as string, dateRange[1]?.format('YYYY-MM-DD') as string, 'total-connected', getKpiService('Total Connected'), getKpiIdFromName('Total Connected') as string)
  }

  function fetchTotalShipped (): void {
    props.fetchTotalShipped(dateRange[0]?.format('YYYY-MM-DD') as string, dateRange[1]?.format('YYYY-MM-DD') as string, 'total-shipped', getKpiService('Total Shipped'), getKpiIdFromName('Total Shipped') as string)
  }

  function fetchLastReadAlarms (): void {
    props.fetchLastReadAlarms(dateRange[0]?.format('YYYY-MM-DD') as string, dateRange[1]?.format('YYYY-MM-DD') as string, 'last-read-alarms', getKpiService('Last Read Alarms'), getKpiIdFromName('Last Read Alarms') as string)
  }

  function fetchTotalStale (): void {
    props.fetchTotalStale(dateRange[0]?.format('YYYY-MM-DD') as string, dateRange[1]?.format('YYYY-MM-DD') as string, 'total-stale', getKpiService('Total Stale'), getKpiIdFromName('Total Stale') as string)
  }

  function fetchTotalReads (): void {
    props.fetchTotalReadsDonutData('total-reads', getDonutKpiService('Total Reads'), getKpiIdFromName('Total Reads') as string)
  }

  function fetchEcMode (): void {
    props.fetchEcModeDonutData('ec-mode-breakdown', getDonutKpiService('Extended Coverage Level'), getKpiIdFromName('Extended Coverage Level') as string)
  }

  function fetchSignalBreakdown (): void {
    props.fetchSignalBreakdownDonutData('signal-breakdown', getDonutKpiService('Signal Breakdown'), getKpiIdFromName('Signal Breakdown') as string)
  }

  function fetchAlarmBreakdown (): void {
    props.fetchAlarmBreakdownDonutData('alarm-breakdown', getDonutKpiService('Alarm Breakdown'), getKpiIdFromName('Alarm Breakdown') as string)
  }

  function fetchFirmwareBreakdown (): void {
    props.fetchFirmwareBreakdownDonutData('firmware-breakdown', getDonutKpiService('Firmware Breakdown'), getKpiIdFromName('Firmware Breakdown') as string)
  }

  function fetchBatteryBreakdown (): void {
    props.fetchBatteryBreakdownDonutData('battery-breakdown', getDonutKpiService('Battery Breakdown'), getKpiIdFromName('Battery Breakdown') as string)
  }

  function fetchLifecycleBreakdown (): void {
    props.fetchLifeCycleBreakdownDonutData('asset-lifecycle-breakdown', getDonutKpiService('Lifecycle Breakdown'), getKpiIdFromName('Lifecycle Breakdown') as string)
  }

  const DataCompletenessMemoized = React.useMemo(() => <DataCompleteness handleKpiRemove={handleKpiRemove} dataCompRef={dataCompRef} fetchKpis={fetchKpis} />, [props.kpiDaily])
  const DataQualityMemoized = React.useMemo(() => <DataQuality handleKpiRemove={handleKpiRemove} fetchThirtydata={fetchThirtydata} fethThirtyDayData={props.fetchThirtyDayData} tenant={auth.user?.profile.customer as string}/>, [props.thirtyDay])
  const TotalRegisteredMemoized = React.useMemo(() => <BarKPI isHistory={false} retryHandler={fetchTotalRegistered} kpiName='Total Registered' buildReportData={buildTotalRegisteredReportData} handleKpiRemove={handleKpiRemove} dateRange={dateRange} setDateRange={setDateRange} state={props.totalRegistered} />, [props.totalRegistered])
  const TotalConnectedMemoized = React.useMemo(() => <BarKPI isHistory={false} retryHandler={fetchTotalConnected} kpiName='Total Connected' buildReportData={buildTotalConnectedReportData} handleKpiRemove={handleKpiRemove} dateRange={dateRange} setDateRange={setDateRange} state={props.totalConnected} />, [props.totalConnected])
  const TotalShippedMemoized = React.useMemo(() => <BarKPI isHistory={false} retryHandler={fetchTotalShipped} kpiName='Total Shipped' buildReportData={buildTotalShippedReportData} handleKpiRemove={handleKpiRemove} dateRange={dateRange} setDateRange={setDateRange} state={props.totalShipped} />, [props.totalShipped])
  const LastReadAlarmsMemoized = React.useMemo(() => <BarKPI isHistory={false} retryHandler={fetchLastReadAlarms} kpiName='Last Read Alarms' buildReportData={buildLastReadAlarmsReportData} handleKpiRemove={handleKpiRemove} dateRange={dateRange} setDateRange={setDateRange} state={props.lastReadAlarms} />, [props.lastReadAlarms])
  const TotalStaleMemoized = React.useMemo(() => <BarKPI isHistory={false} retryHandler={fetchTotalStale} kpiName='Total Stale' buildReportData={buildTotalStaleReportData} handleKpiRemove={handleKpiRemove} dateRange={dateRange} setDateRange={setDateRange} state={props.totalStale} />, [props.totalStale])
  const TotalReadsDonutMemoized = React.useMemo(() => <DonutKPI kpiName='Total Reads' retryHandler={fetchTotalReads} handleKpiRemove={handleKpiRemove} buildDonutChartData={buildDonutChartData} state={props.totalReadsDonutData} isHistory={false} />, [props.totalReadsDonutData])
  const EcModeDonutMemoized = React.useMemo(() => <DonutKPI kpiName='Extended Coverage Level' retryHandler={fetchEcMode} handleKpiRemove={handleKpiRemove} buildDonutChartData={buildDonutChartData} state={props.ecModeDonutData} isHistory={false} />, [props.ecModeDonutData])
  const SignalBreakdownDonutMemoized = React.useMemo(() => <DonutKPI kpiName='Signal Breakdown' retryHandler={fetchSignalBreakdown} handleKpiRemove={handleKpiRemove} buildDonutChartData={buildDonutChartData} state={props.signalBreakdownDonutData} isHistory={false} />, [props.signalBreakdownDonutData])
  const AlarmBreakdownDonutMemoized = React.useMemo(() => <DonutKPI kpiName='Alarm Breakdown' retryHandler={fetchAlarmBreakdown} handleKpiRemove={handleKpiRemove} buildDonutChartData={buildDonutChartData} state={props.alarmBreakdownDonutData} isHistory={false} />, [props.alarmBreakdownDonutData])
  const FirmwareBreakdownDonutMemoized = React.useMemo(() => <DonutKPI kpiName='Firmware Breakdown' retryHandler={fetchFirmwareBreakdown} handleKpiRemove={handleKpiRemove} buildDonutChartData={buildDonutChartData} state={props.firmwareBreakdownDonutData} isHistory={false} />, [props.firmwareBreakdownDonutData])

  const BatteryBreakdownDonutMemoized = React.useMemo(() => <DonutKPI kpiName='Battery Breakdown' retryHandler={fetchBatteryBreakdown} handleKpiRemove={handleKpiRemove} buildDonutChartData={buildDonutChartData} state={props.batteryBreakdownDonutData} isHistory={false} />, [props.batteryBreakdownDonutData])
  const LifecycleBreakdownDonutMemoized = React.useMemo(() => <DonutKPI kpiName='Lifecycle Breakdown' retryHandler={fetchLifecycleBreakdown} handleKpiRemove={handleKpiRemove} buildDonutChartData={buildDonutChartData} state={props.lifecycleBreakdownDonutData} isHistory={false} />, [props.lifecycleBreakdownDonutData])

  const kpiMap = {
    'Data Completeness': DataCompletenessMemoized,
    '30 Days Trend': DataQualityMemoized,
    'Total Registered': TotalRegisteredMemoized,
    'Total Connected': TotalConnectedMemoized,
    'Total Shipped': TotalShippedMemoized,
    'Total Stale': TotalStaleMemoized,
    'Total Reads': TotalReadsDonutMemoized,
    'Extended Coverage Level': EcModeDonutMemoized,
    'Signal Breakdown': SignalBreakdownDonutMemoized,
    'Alarm Breakdown': AlarmBreakdownDonutMemoized,
    'Firmware Breakdown': FirmwareBreakdownDonutMemoized,
    'Last Read Alarms': LastReadAlarmsMemoized,
    'Battery Breakdown': BatteryBreakdownDonutMemoized,
    'Lifecycle Breakdown': LifecycleBreakdownDonutMemoized
  }

  const kpiApiMap: Record<KpiKey, () => void> = {
    'Data Completeness': fetchKpis,
    '30 Days Trend': fetchThirtydata,
    'Total Registered': fetchTotalRegistered,
    'Total Connected': fetchTotalConnected,
    'Total Shipped': fetchTotalShipped,
    'Total Stale': fetchTotalStale,
    'Total Reads': fetchTotalReads,
    'Extended Coverage Level': fetchEcMode,
    'Signal Breakdown': fetchSignalBreakdown,
    'Alarm Breakdown': fetchAlarmBreakdown,
    'Firmware Breakdown': fetchFirmwareBreakdown,
    'Last Read Alarms': fetchLastReadAlarms,
    'Battery Breakdown': fetchBatteryBreakdown,
    'Lifecycle Breakdown': fetchLifecycleBreakdown
  }

  const [start] = useState<Dayjs | null | undefined>(defaultDate.start)
  const [end] = useState<Dayjs | null | undefined>(defaultDate.end)
  // target id will only be set if dragging from one dropzone to another.
  // istanbul ignore next
  function onChange (sourceId: any, sourceIndex: number, targetIndex: number): void {
    const nextState = swap(selectedKpi, sourceIndex, targetIndex)
    setSelectedKpi(nextState)
  }

  useEffect(() => {
    props.fetchDeviceList(0, 10, 'asc', 'id', auth.user?.profile.customer as string)
    props.fetchTopIssues(auth.user?.profile.customer as string)
  }, [])

  useEffect(() => {
    // istanbul ignore next
    if (props.KpiUserPreference.httpStatus === 200 && props.KpiUserPreference.data !== undefined) {
      const selectedKpiPreference: KpiKey[] = []
      props.KpiUserPreference.data.kpiPreferences.forEach((kpiItem) => {
        const kpiName: KpiKey | undefined = getKpiNameFromId(kpiItem.kpiId)
        if (kpiName !== undefined) {
          selectedKpiPreference.push(kpiName)
        }
      })
      setSelectedKpi(selectedKpiPreference)
      setKpiTable(AvailableKpiTypes.filter((kpi) => !selectedKpiPreference.includes(kpi)))
    }
  }, [props.KpiUserPreference])

  useEffect(() => {
    const newKpis: KpiKey[] = selectedKpi.filter((kpi) => !kpiThatHaveData.includes(kpi))
    setKpiThatHaveData(selectedKpi)

    newKpis.forEach((kpi) => {
      kpiApiMap[kpi]()
    })
  }, [selectedKpi])

  const handleSearch = (val: string): void => {
    setSearchVal(val)
  }

  const handleSearchAction = (): void => {
    const newKpiList = AvailableKpiTypes.filter((kpi) => kpi.toLowerCase().includes(searchVal.toLowerCase()) && !selectedKpi.includes(kpi))
    setKpiTable(newKpiList)
  }

  const renderDragCards = (): JSX.Element => {
    // istanbul ignore next
    const responsiveHeight = window.innerWidth >= 1896 ? 470 : 392
    return <>
    <GridContextProvider onChange={onChange}>
      <GridDropZone
        id="items"
        boxesPerRow={2}
        rowHeight={responsiveHeight}
        style={{ height: responsiveHeight * Math.ceil(selectedKpi.length / 2) }}
      >
        {
          selectedKpi.map((kpiCard) => {
            return (
                <GridItem style={{ opacity: 1 }} className={`kpi-${kpiCard}`} key={`kpi-${kpiCard}`}>
                  <div style={{ marginRight: 10, marginBottom: 10 }}>
                    {
                      // istanbul ignore next
                      // eslint-disable-next-line no-prototype-builtins
                      kpiMap.hasOwnProperty(kpiCard) ? kpiMap[kpiCard] : <></>
                    }
                  </div>
                </GridItem>
            )
          })
        }
      </GridDropZone>
    </GridContextProvider>

  </>
  }

  return (
        <div>
            <div className='topUtilityBar'>
                <BackButton/>
                <span id='componentTitle'>{DeviceDetailsStrings.Overview.HON_HEADINGS}</span>
                <span id='totalDevicesTitle'>{AMIMeterCollectionStrings.Total} <span>{props.deviceList.deviceCount}</span> {AMIMeterCollectionStrings.Devices}</span>
                <span id='top-right-ami-utilities'>
                  <span id='top-right-btn-span'>
                    <Button id='top-right-ami-btn' className='deviceList' sx={{ margin: 0 }} width='fit-content' onClick={() => {
                      navigate('/asset-list')
                      dispatch(changeGrandParentBreadcrumb(grandParentBreadcrumbState.honHeading))
                    }} dataTestId='asset-list-btn'>{AMIMeterCollectionStrings.List}</Button>
                  </span>
                </span>
            </div>

            <Grid>
              <Alarm height={window.innerHeight * 0.6} alignmentObj={[alignment, setAlignment]}/>
            </Grid>

            <br />

            <Grid container sx={{ backgroundColor: '#191818', borderTopLeftRadius: '10px' }}>
              <Grid item xs={2} style={{ paddingRight: 2 }}>
                <div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ fontSize: '16px', fontWeight: '550', padding: '0.8rem 1rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '90%' }}>
                      <span>KPI LIST</span>
                    </div>
                    <SearchBox immediate={true} sx={{ width: '100%', paddingRight: 0 }} crossBarStyle={{ right: '10px' }} containerStyle={{ marginLeft: 0, paddingLeft: '1.2rem', width: '100%' }}
                      placeholder={'Search KPI'}
                      searchVal={searchVal} setSearchVal={handleSearch} performAction={handleSearchAction}
                      emptyEnterFallback={handleSearchAction}
                    />
                  </div>
                  <div style={{ height: (dashboardRef?.current != null && selectedKpi.length > 2) ? `calc(${dashboardRef.current.clientHeight}px - 103.6px)` : '60vh', minHeight: '60vh', overflowY: 'scroll' }}>
                    <GenXSelectableList ulStyle={{ marginLeft: '10px' }} selected={selectedKpi} onClick={(kpi: KpiKey) => {
                      const temp = [...selectedKpi]
                      temp.push(kpi)
                      setSelectedKpi(temp)
                      setKpiTable(kpiTable.filter((item) => item !== kpi))
                    }} searchValue={searchVal} datalist={kpiTable} />
                  </div>
                </div>
              </Grid>
              <Grid ref={dashboardRef} item xs={10} sx={{ padding: '1em', backgroundColor: '#272727', borderTopRightRadius: '10px' }}>
                {
                  renderDragCards()
                }
              </Grid>
            </Grid>
            <br />
        </div>
  )
}

interface DispatchToProps {
  fetchDeviceList: (page: number, size: number, sortdir: string, sortfield: string, tenantId: string) => void
  fetchTopIssues: (tenant: string) => void
  fetchThirtyDayData: (tenantId: string, from: string, to: string) => void
  fetchKpiDailyData: () => void
  fetchTotalRegistered: (startDate: string, endDate: string, barItem: string, service: string, kpiCategoryId: string) => void
  fetchTotalConnected: (startDate: string, endDate: string, barItem: string, service: string, kpiCategoryId: string) => void
  fetchTotalShipped: (startDate: string, endDate: string, barItem: string, service: string, kpiCategoryId: string) => void
  fetchLastReadAlarms: (startDate: string, endDate: string, barItem: string, service: string, kpiCategoryId: string) => void
  fetchTotalStale: (startDate: string, endDate: string, barItem: string, service: string, kpiCategoryId: string) => void
  fetchKpiUserPreference: (emailId: string) => void
  saveKpiUserPreference: (kpiUserPreferenceRequest: KpiPreferenceRequest) => void
  fetchTotalReadsDonutData: (kpiType: string, service: string, kpiCategoryId: string) => void
  fetchEcModeDonutData: (kpiType: string, service: string, kpiCategoryId: string) => void
  fetchSignalBreakdownDonutData: (kpiType: string, service: string, kpiCategoryId: string) => void
  fetchAlarmBreakdownDonutData: (kpiType: string, service: string, kpiCategoryId: string) => void
  fetchFirmwareBreakdownDonutData: (kpiType: string, service: string, kpiCategoryId: string) => void
  fetchBatteryBreakdownDonutData: (kpiType: string, service: string, kpiCategoryId: string) => void
  fetchLifeCycleBreakdownDonutData: (kpiType: string, service: string, kpiCategoryId: string) => void
}

// istanbul ignore next
const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchDeviceList: (page: number, size: number, sortdir: string, sortfield: string, tenantId: string) => dispatch(fetchDeviceList(page, size, sortdir, sortfield, tenantId)),
  fetchTopIssues: (tenant: string) => dispatch(fetchTopIssuesData(tenant)),
  fetchThirtyDayData: (tenantId: string, from: string, to: string) => dispatch(fetchThirtyDayData(tenantId, from, to)),
  fetchKpiDailyData: () => dispatch(fetchKpiDailyData()),
  fetchTotalRegistered: (startDate: string, endDate: string, kpiType: string, service: string, kpiCategoryId: string) => dispatch(fetchTotalRegisteredKpi(startDate, endDate, kpiType, service, kpiCategoryId)),
  fetchTotalConnected: (startDate: string, endDate: string, kpiType: string, service: string, kpiCategoryId: string) => dispatch(fetchTotalConnectedKpi(startDate, endDate, kpiType, service, kpiCategoryId)),
  fetchTotalShipped: (startDate: string, endDate: string, kpiType: string, service: string, kpiCategoryId: string) => dispatch(fetchTotalShippedKpi(startDate, endDate, kpiType, service, kpiCategoryId)),
  fetchLastReadAlarms: (startDate: string, endDate: string, kpiType: string, service: string, kpiCategoryId: string) => dispatch(fetchLastReadAlarmsKpi(startDate, endDate, kpiType, service, kpiCategoryId)),
  fetchTotalStale: (startDate: string, endDate: string, kpiType: string, service: string, kpiCategoryId: string) => dispatch(fetchTotalStaleKpi(startDate, endDate, kpiType, service, kpiCategoryId)),
  fetchKpiUserPreference: (emailId: string) => dispatch(fetchKpiUserPreference(emailId)),
  saveKpiUserPreference: (kpiUserPreferenceRequest: KpiPreferenceRequest) => dispatch(saveKpiPreference(kpiUserPreferenceRequest)),
  fetchTotalReadsDonutData: (kpiType: string, service: string, kpiCategoryId: string) => dispatch(fetchTotalReadsDonutData(kpiType, service, kpiCategoryId)),
  fetchEcModeDonutData: (kpiType: string, service: string, kpiCategoryId: string) => dispatch(fetchEcModeDonutData(kpiType, service, kpiCategoryId)),
  fetchSignalBreakdownDonutData: (kpiType: string, service: string, kpiCategoryId: string) => dispatch(fetchSignalBreakdownDonutData(kpiType, service, kpiCategoryId)),
  fetchAlarmBreakdownDonutData: (kpiType: string, service: string, kpiCategoryId: string) => dispatch(fetchAlarmBreakdownDonutData(kpiType, service, kpiCategoryId)),
  fetchFirmwareBreakdownDonutData: (kpiType: string, service: string, kpiCategoryId: string) => dispatch(fetchFirmwareBreakdownDonutData(kpiType, service, kpiCategoryId)),
  fetchBatteryBreakdownDonutData: (kpiType: string, service: string, kpiCategoryId: string) => dispatch(fetchBatteryBreakdownDonutData(kpiType, service, kpiCategoryId)),
  fetchLifeCycleBreakdownDonutData: (kpiType: string, service: string, kpiCategoryId: string) => dispatch(fetchLifeCycleBreakdownDonutData(kpiType, service, kpiCategoryId))
})
interface StateToProps {
  deviceList: DeviceListState
  // eslint-disable-next-line @typescript-eslint/ban-types
  thirtyDay: thirtyDayState
  allActiveIssues: AlarmDataState
  kpiDaily: kpiDailyState
  totalRegistered: TotalRegisteredState
  totalConnected: TotalConnectedState
  totalShipped: TotalShippedState
  lastReadAlarms: LastReadAlarmsState
  totalStale: TotalStaleState
  KpiUserPreference: KpiUserPreferenceState
  totalReadsDonutData: DonutKpiState
  ecModeDonutData: DonutKpiState
  signalBreakdownDonutData: DonutKpiState
  alarmBreakdownDonutData: DonutKpiState
  firmwareBreakdownDonutData: DonutKpiState
  batteryBreakdownDonutData: DonutKpiState
  lifecycleBreakdownDonutData: DonutKpiState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  deviceList: state.deviceList,
  thirtyDay: state.thirtyDay,
  allActiveIssues: state.allActiveIssues,
  kpiDaily: state.kpiDailyData,
  totalRegistered: state.TotalRegisteredState,
  totalConnected: state.TotalConnectedState,
  totalShipped: state.TotalShippedState,
  lastReadAlarms: state.LastReadAlarmsData,
  totalStale: state.TotalStaleState,
  KpiUserPreference: state.KpiUserPreference,
  totalReadsDonutData: state.totalReadsDonutData,
  ecModeDonutData: state.ecModeDonutData,
  signalBreakdownDonutData: state.signalBreakdownData,
  alarmBreakdownDonutData: state.alarmBreakdownData,
  firmwareBreakdownDonutData: state.firmwareBreakdownData,
  batteryBreakdownDonutData: state.batteryBreakdownData,
  lifecycleBreakdownDonutData: state.lifecycleBreakdownData
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(AmiMeterCollection)
