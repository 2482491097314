import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface AssetDetails {
  attribute_name: string
  value: string
}

export interface AssetProperties {
  properties: AssetDetails[]
}

export interface AssetPropertiesByIdState {
  isLoading: boolean
  propertiesData: AssetProperties | null
  errorMessage: string | null
  httpStatus: number | null
}

const initialState: AssetPropertiesByIdState = {
  isLoading: false,
  propertiesData: null,
  errorMessage: null,
  httpStatus: null
}

export const assetPropertiesByIdSlice = createSlice({
  name: 'assetPropertiesByIdSlice',
  initialState,
  reducers: {
    assetPropertiesByIdLoadingAction: (state): AssetPropertiesByIdState => {
      return {
        isLoading: true,
        propertiesData: null,
        errorMessage: null,
        httpStatus: 0
      }
    },
    assetPropertiesByIdErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>): AssetPropertiesByIdState => {
      return {
        isLoading: false,
        propertiesData: null,
        errorMessage: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus
      }
    },
    assetPropertiesByIdUpdateAction: (state, action: PayloadAction<{ data: AssetProperties, httpStatus: number }>): AssetPropertiesByIdState => {
      return {
        isLoading: false,
        propertiesData: action.payload.data,
        errorMessage: null,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  assetPropertiesByIdLoadingAction,
  assetPropertiesByIdUpdateAction,
  assetPropertiesByIdErrorAction
} = assetPropertiesByIdSlice.actions
export default assetPropertiesByIdSlice.reducer
