import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type TopIssuesGeoJsonState, type AlarmFeatureCollection } from '../types'

const initialState: TopIssuesGeoJsonState = {
  geoJson: undefined,
  loading: false,
  error: undefined,
  httpStatus: 0
}

export const TopIssuesGeoJsonSlice = createSlice({
  name: 'TopIssuesListState',
  initialState,
  reducers: {
    TopIssuesGeoJsonLoadingAction: (state) => {
      return {
        ...initialState,
        loading: true
      }
    },
    TopIssuesGeoJsonUpdateAction: (state, action: PayloadAction<{ topIssuesGeoJson: AlarmFeatureCollection, httpStatus: number }>) => {
      return {
        ...initialState,
        geoJson: action.payload.topIssuesGeoJson,
        httpStatus: action.payload.httpStatus
      }
    },
    TopIssuesGeoJsonErrorAction: (state, action: PayloadAction<{ error: string | undefined, httpStatus: number }>) => {
      return {
        ...initialState,
        error: action.payload.error,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  TopIssuesGeoJsonLoadingAction,
  TopIssuesGeoJsonUpdateAction,
  TopIssuesGeoJsonErrorAction
} = TopIssuesGeoJsonSlice.actions

export default TopIssuesGeoJsonSlice.reducer
