import interceptedAxios from '../../../utils/interceptedAxios'
import { type BarKpiDataObject, ZBarKpiApiResponse } from '../types'

export async function getBarKpiData (
  startDate: string,
  endDate: string,
  kpiType: string,
  service: string,
  kpiCategoryId: string,
  onSuccess: (dataObj: BarKpiDataObject, httpStatus: number) => any,
  onFailure: (httpStatus: number) => any
): Promise<void> {
  const url = `/${service}/api/v1/udp/liveData/${kpiType}`

  const body = {
    kpiCategoryId,
    fromDate: startDate,
    toDate: endDate
  }
  interceptedAxios
    .post(url, body)
    .then((response) => {
      console.log('response', response)
      const parseResponse = ZBarKpiApiResponse.safeParse(response.data)
      console.log('parseResponse', parseResponse)
      if (parseResponse.success) {
        // istanbul ignore else
        if (parseResponse.data.data !== undefined) {
          onSuccess(parseResponse.data.data, parseResponse.data.http_status)
        }
      } else {
        onFailure(response.status)
      }
    })
    .catch((error) => {
      onFailure(error.status)
    })
}

export async function getDonutKpiBarGraphData (
  startDate: string,
  endDate: string,
  kpiType: string,
  barType: string,
  service: string,
  kpiCategoryId: string,
  onSuccess: (dataObj: BarKpiDataObject, httpStatus: number) => any,
  onFailure: (httpStatus: number) => any
): Promise<void> {
  const url = `/${service}/api/v1/udp/liveData/${kpiType}`
  const body = {
    kpiCategoryId,
    kpiBreakdownType: barType,
    fromDate: startDate,
    toDate: endDate
  }
  interceptedAxios
    .post(url, body)
    .then((response) => {
      const parseResponse = ZBarKpiApiResponse.safeParse(response.data)
      if (parseResponse.success) {
        // istanbul ignore else
        if (parseResponse.data.data !== undefined) {
          onSuccess(parseResponse.data.data, parseResponse.data.http_status)
        }
      } else {
        onFailure(response.status)
      }
    })
    .catch((error) => {
      onFailure(error.status)
    })
}
