import interceptedAxios from '../../../utils/interceptedAxios'
import { ZDonutKpiDataObject, type DonutKpiDataObject } from '../types'

export async function getDataCompletenessKpis (
  onSuccess: (snapshotReads: DonutKpiDataObject, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = '/kpirest/api/v1/udp/liveData/data-completeness?kpiCategoryId=54c0f6dd-3460-433c-b808-cad310fce27c'
  interceptedAxios.get(url)
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZDonutKpiDataObject.safeParse(response.data.data)
        if (parseResponse.success) {
          const parsedData: DonutKpiDataObject = parseResponse.data
          onSuccess(parsedData, response.status)
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch(
      (error) => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        const statusCode = error.response ? error.response.status : 500
        onFailure(error.message, statusCode)
      })
}
