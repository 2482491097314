import React from 'react'
import BackButton from '../../components/BackButton'
import { useNavigate } from 'react-router-dom'
import ConfigurationTable from './components/ConfigurationTable'
import { Button } from '../../components/Button'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../store'
import LanguageStrings from '../../i18n/locales'
import { type OperationPermissionState } from '../dashboard/redux/operationPermissionSlice'
import { getOperationPermissions } from '../dashboard/redux/actionCreator'
import { useAuth } from 'react-oidc-context'

const ConfigurationListStrings = LanguageStrings().AssetConfigurationStrings.ConfigurationListString

const ConfigurationList = (props: PropsFromRedux): JSX.Element => {
  const navigate = useNavigate()
  const operationPermissions: OperationPermissionState = useSelector((state: RootState) => state.operationPermissions)
  const numConfigurations = useSelector((state: RootState) => state.configurationList.configurationList !== null ? state.configurationList.configurationList.total_count : 0)
  const auth = useAuth()

  React.useEffect(() => {
    if (!operationPermissions.updated) {
      props.getOperationPermissions(auth.user?.profile.sub as string)
    }
  }, [])

  return (
    <div style={{ width: 'calc(100vw - 3.5rem)', paddingRight: '1.2rem' }}>
      <div className='link-breadcrumb'>
        <span data-testid='assetManagementbreadcrumb' onClick={ () => { navigate('/card') }} className='assetmgmtli'>{ConfigurationListStrings.ASSET_MANAGEMENT}</span>
        <span style={{ padding: '0 0.5em' }}>/</span>
        <span>{ConfigurationListStrings.CONFIGURATIONS}</span>
      </div>
      <div className='topUtilityBar'>
        <BackButton onclick={ () => { navigate('/card') }} />
        <span id='componentTitle' data-testid='configurations'>{ConfigurationListStrings.CONFIGURATIONS}</span>
        <span id='top-right-ami-utilities'>
          <span id='top-right-btn-span'>
            {
              operationPermissions.permissions.can_add_asset_config
                ? <Button sx={{ margin: 0 }} width='fit-content' onClick={() => { navigate('/createConfig') }} dataTestId='create-config-btn'>{ConfigurationListStrings.CREATE_ASSET_CONFIGURATION}</Button>
                : <></>
            }
          </span>
          </span>
      </div>
      <div>
        <div className='configurations-table-heading'>
          <h5>{ConfigurationListStrings.CONFIGURATION_LIST} <span className='config-list-box'>{numConfigurations} {ConfigurationListStrings.CONFIGURATIONS}</span></h5>
        </div>
        <ConfigurationTable />
      </div>
    </div>
  )
}

interface DispatchToProps {
  getOperationPermissions: (sub: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  getOperationPermissions: (sub: string) => dispatch(getOperationPermissions(sub))
})

const connector = connect(null, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(ConfigurationList)
