/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { kpiDataLoadingAction, kpiDataUpdateAction, kpiDataErrorAction } from './kpiDailySlice'
import { assetStatesDataLoadingAction, assetStatesDataUpdateAction, assetStatesDataErrorAction } from './assetStatesSlice'
import { deviceListErrorAction, deviceListLoadingAction, deviceListUpdateAction } from './deviceListSlice'
import { allAlarmDataErrorAction, allAlarmDataLoadingAction, allAlarmDataUpdateAction } from './allAlarmsSlice'
import { topIssuesLoadingAction, topIssuesUpdateAction, topIssuesErrorAction } from './topIssuesSlice'
import { updateAssetStateInfoDataLoading, updateAssetStateInfoDataError, updateAssetStateInfoData, resetUpdateStateParamsData } from './updateAssetStateSlice'
import { totalRegisteredErrorAction, totalRegisteredLoadingAction, totalRegisteredUpdateAction } from './KpiSlices/totalRegisteredSlice'
import { totalConnectedErrorAction, totalConnectedLoadingAction, totalConnectedUpdateAction } from './KpiSlices/totalConnectedSlice'
import { type assetStateReq } from '../services/updateAssetstate'
import * as DataCompletenessService from '../services/dataCompletenessService'
import * as KpiService from '../services/kpiServices'
import * as DeviceListService from '../services/deviceListService'
import * as SearchAssetBySerialNumberService from '../services/searchAssetBySerialNumber'
import * as AllActiveIssuesService from '../services/allAlarmService'
import * as TopIssuesService from '../services/topIssuesService'
import * as ThirtyDayService from '../services/thirtyDayService'
import * as assetStateDataService from '../services/changeAssetState'
import * as KpiHistoryService from '../services/historyTableService'
import * as DonutKpiService from '../services/donutKpiService'
import { type AllAlarmDetails } from './allAlarmsSlice'
import * as updateAssetStateDataService from '../services/updateAssetstate'
import { thirtyDayLoadingAction, thirtyDayUpdateAction, thirtyDayErrorAction } from './thirtyDaySlice'
import { historyARTableErrorAction, historyARTableLoadingAction, historyARTableResetAction, historyARTableUpdateAction } from './HistoryARTableSlice'
import { totalShippedErrorAction, totalShippedLoadingAction, totalShippedUpdateAction } from './KpiSlices/totalShippedSlice'
import { totalStaleErrorAction, totalStaleLoadingAction, totalStaleUpdateAction } from './KpiSlices/totalStaleSlice'
import { kpiUserPreferenceErrorAction, kpiUserPreferenceLoadingAction, kpiUserPreferenceUpdateAction } from './getKpiPreferenceSlice'
import { getKpiUserPreferenceService } from '../services/getKpiUserPreferenceService'
import { type KpiPreferenceRequest } from '../types'
import { saveKpiUserPreferenceErrorAction, saveKpiUserPreferenceLoadingAction, saveKpiUserPreferenceUpdateAction } from './saveKpiPreferenceSlice'
import { saveKpiUserPreference } from '../services/saveKpiUserPreferenceService'
import { totalReadsDonutErrorAction, totalReadsDonutLoadingAction, totalReadsDonutUpdateAction } from './KpiSlices/totalReadsSlice'
import { historyDonutErrorAction, historyDonutLoadingAction, historyDonutResetAction, historyDonutUpdateAction } from './historyDonutSlice'
import { historyDonutTableErrorAction, historyDonutTableLoadingAction, historyDonutTableResetAction, historyDonutTableUpdateAction } from './HistoryDonutTableSlice'
import { donutKpiBarGraphErrorAction, donutKpiBarGraphLoadingAction, donutKpiBarGraphResetAction, donutKpiBarGraphUpdateAction } from './historyDonutKpiBarGraphSlice'
import { ecModeDonutErrorAction, ecModeDonutLoadingAction, ecModeDonutUpdateAction } from './KpiSlices/ecModeSlice'
import { signalBreakdownDonutErrorAction, signalBreakdownDonutLoadingAction, signalBreakdownDonutUpdateAction } from './KpiSlices/signalBreakdownSlice'
import { alarmBreakdownDonutErrorAction, alarmBreakdownDonutLoadingAction, alarmBreakdownDonutUpdateAction } from './KpiSlices/alarmBreakdownSlice'
import { firmwareBreakdownDonutErrorAction, firmwareBreakdownDonutLoadingAction, firmwareBreakdownDonutUpdateAction } from './KpiSlices/firmwareBreakdownSlice'
import { lastReadAlarmsErrorAction, lastReadAlarmsLoadingAction, lastReadAlarmsUpdateAction } from './KpiSlices/lastReadAlarmsSlice'
import { batteryBreakdownDonutErrorAction, batteryBreakdownDonutLoadingAction, batteryBreakdownDonutUpdateAction } from './KpiSlices/batteryBreakdownSlice'
import { lifecycleBreakdownDonutErrorAction, lifecycleBreakdownDonutLoadingAction, lifecycleBreakdownDonutUpdateAction } from './KpiSlices/lifecycleBreakdownSlice'
import { MapDataErrorAction, MapDataLoadingAction, MapDataUpdateAction } from './mapDataSlice'
import { historyKSTableErrorAction, historyKSTableLoadingAction, historyKSTableUpdateAction } from './HistoryKSTableSlice'

export const fetchKpiDailyData = () => async (dispatch: any) => {
  dispatch(kpiDataLoadingAction())
  void DataCompletenessService.getDataCompletenessKpis(
    (kpiData, httpStatus) => {
      dispatch(kpiDataUpdateAction({ kpiData, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(kpiDataErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const updateAssetStateInfo = (assetStateParamter: assetStateReq, tenantId: string) => async (dispatch: any) => {
  dispatch(updateAssetStateInfoDataLoading())
  void updateAssetStateDataService.getUpdateInfoAssetStateData(assetStateParamter, tenantId, (message: string, httpStatus: number) => {
    dispatch(updateAssetStateInfoData({ message, httpStatus }))
  },
  (message: string, httpStatus: number) => {
    dispatch(updateAssetStateInfoDataError({ message, httpStatus }))
  })
}

export const resetUpdateStateParams = () => async (dispatch: any) => {
  dispatch(resetUpdateStateParamsData())
}

export const getAssetStateData = (tenantId: string) => async (dispatch: any) => {
  dispatch(assetStatesDataLoadingAction())
  void assetStateDataService.getAssetStateData((message) => {
    dispatch(assetStatesDataUpdateAction(message))
  },
  (errorMessage) => {
    dispatch(assetStatesDataErrorAction(errorMessage))
  })
}

export const fetchDeviceList =
  (page: any, size: any, sortdir: any, sortfield: any, tenantId: string) =>
    async (dispatch: any): Promise<void> => {
      dispatch(deviceListLoadingAction())

      void DeviceListService.getDeviceList(
        page,
        size,
        sortdir,
        sortfield,
        tenantId,
        (deviceList, httpStatus) => {
          dispatch(
            deviceListUpdateAction({
              data: deviceList.assets,
              count: deviceList.total_count,
              httpStatus
            })
          )
        },
        (errorMessage, httpStatus) => {
          dispatch(deviceListErrorAction({ errorMessage, httpStatus }))
        }
      )
    }

export const fetchAssetBySearchValue =
  (searchVal: string, tenantId: string, page: number, size: number, sortfield: string, sortdir: string) =>
    async (dispatch: any): Promise<void> => {
      dispatch(deviceListLoadingAction())

      void SearchAssetBySerialNumberService.getAssetBySearchValue(
        searchVal,
        tenantId,
        page,
        size,
        sortfield,
        sortdir,
        (assetList, httpStatus) => {
          dispatch(
            deviceListUpdateAction({
              data: assetList.assets,
              count: assetList.total_count,
              httpStatus
            })
          )
        },
        (errorMessage, httpStatus) => {
          dispatch(deviceListErrorAction({ errorMessage, httpStatus }))
        }
      )
    }

export const fetchAllActiveAlarms = (page: number, size: number, sortdir: string, sortfield: string, tenantId: string) => async (dispatch: any): Promise<void> => {
  dispatch(allAlarmDataLoadingAction())

  void AllActiveIssuesService.getAllAlarms(
    page, size, sortdir, sortfield, tenantId, false,
    (allAlarmData, httpStatus) => {
      dispatch(
        allAlarmDataUpdateAction({
          data: allAlarmData.alarms,
          count: allAlarmData.totalCount,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(allAlarmDataErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const fetchMapData = (tenantId: string) => async (dispatch: any): Promise<void> => {
  dispatch(MapDataLoadingAction())

  void AllActiveIssuesService.getAllAlarms(
    0, 10000, 'asc', 'assetName', tenantId, true,
    (MapData, httpStatus) => {
      dispatch(
        MapDataUpdateAction({
          data: MapData.alarms,
          count: MapData.totalCount,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(MapDataErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const fetchTopIssuesData = (tenant: string) => async (dispatch: any) => {
  dispatch(topIssuesLoadingAction())

  void TopIssuesService.getTopIssues(
    tenant,
    (topIssues: AllAlarmDetails) => {
      const fatals = topIssues.alarms.filter(item => item.severity === 'critical')
      const schedule = topIssues.alarms.filter(item => item.severity === 'Schedule')
      const noData = topIssues.alarms.filter(item => item.severity === 'noData')
      dispatch(topIssuesUpdateAction([schedule.length, noData.length, fatals.length]))
    },
    (errorMessage) => {
      dispatch(topIssuesErrorAction(errorMessage))
    }
  )
}

function parseData (jsonData: ThirtyDayService.thirtyDay) {
  const dateMap = new Map()

  jsonData.data.forEach((entry) => {
    const timestamp = entry.timestamp
    const kpiValue = entry.kpi_value

    const dateKey = new Date(timestamp).toISOString().split('T')[0]

    if (!dateMap.has(dateKey) || new Date(timestamp) > new Date(dateMap.get(dateKey).timestamp)) {
      dateMap.set(dateKey, { timestamp, kpiValue })
    }
  })

  const latestValues = Array.from(dateMap.values())
  latestValues.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())

  const timestamps = latestValues.map((entry) => entry.timestamp)
  const kpiValues = latestValues.map((entry) => entry.kpiValue)

  return { timestamps, kpiValues }
}

export const fetchThirtyDayData =
  (tenant: string, from: string, to: string) => async (dispatch: any) => {
    dispatch(thirtyDayLoadingAction())
    void ThirtyDayService.getThirtyDayData(
      tenant,
      from,
      to,
      (thirtyDayData: ThirtyDayService.thirtyDay, httpStatus) => {
        const parsedData = parseData(thirtyDayData)
        dispatch(thirtyDayUpdateAction({ data: parsedData, httpStatus, CalculatedAt: thirtyDayData.CalculatedAt ?? '' }))
      },
      (errorMessage, httpStatus) => {
        dispatch(thirtyDayErrorAction({ errorMessage, httpStatus }))
      }
    )
  }

export const fetchTotalShippedKpi = (startDate: string, endDate: string, kpiType: string, service: string, kpiCategoryId: string) => async (dispatch: any) => {
  dispatch(totalShippedLoadingAction())
  void KpiService.getBarKpiData(
    startDate,
    endDate,
    kpiType,
    service,
    kpiCategoryId,
    (data, httpStatus) => {
      dispatch(totalShippedUpdateAction({ data, httpStatus }))
    },
    (httpStatus) => {
      dispatch(totalShippedErrorAction(httpStatus))
    }
  )
}

export const fetchLastReadAlarmsKpi = (startDate: string, endDate: string, kpiType: string, service: string, kpiCategoryId: string) => async (dispatch: any) => {
  dispatch(lastReadAlarmsLoadingAction())
  void KpiService.getBarKpiData(
    startDate,
    endDate,
    kpiType,
    service,
    kpiCategoryId,
    (data, httpStatus) => {
      dispatch(lastReadAlarmsUpdateAction({ data, httpStatus }))
    },
    (httpStatus) => {
      dispatch(lastReadAlarmsErrorAction(httpStatus))
    }
  )
}

export const fetchTotalRegisteredKpi = (startDate: string, endDate: string, kpiType: string, service: string, kpiCategoryId: string) => async (dispatch: any) => {
  dispatch(totalRegisteredLoadingAction())
  void KpiService.getBarKpiData(
    startDate,
    endDate,
    kpiType,
    service,
    kpiCategoryId,
    (data, httpStatus) => {
      dispatch(totalRegisteredUpdateAction({ data, httpStatus }))
    },
    (httpStatus) => {
      dispatch(totalRegisteredErrorAction(httpStatus))
    }
  )
}

export const fetchTotalConnectedKpi = (startDate: string, endDate: string, kpiType: string, service: string, kpiCategoryId: string) => async (dispatch: any) => {
  dispatch(totalConnectedLoadingAction())
  void KpiService.getBarKpiData(
    startDate,
    endDate,
    kpiType,
    service,
    kpiCategoryId,
    (data, httpStatus) => {
      dispatch(totalConnectedUpdateAction({ data, httpStatus }))
    },
    (httpStatus) => {
      dispatch(totalConnectedErrorAction(httpStatus))
    }
  )
}

export const fetchTotalStaleKpi = (startDate: string, endDate: string, kpiType: string, service: string, kpiCategoryId: string) => async (dispatch: any) => {
  dispatch(totalStaleLoadingAction())
  void KpiService.getBarKpiData(
    startDate,
    endDate,
    kpiType,
    service,
    kpiCategoryId,
    (data, httpStatus) => {
      dispatch(totalStaleUpdateAction({ data, httpStatus }))
    },
    (httpStatus) => {
      dispatch(totalStaleErrorAction(httpStatus))
    }
  )
}

// istanbul ignore next
export const fetchKpiHistoryAR = (
  kpiType: string,
  inputDateRead: string,
  page: number,
  size: number, service: string) => async (dispatch: any) => {
  dispatch(historyARTableLoadingAction())
  void KpiHistoryService.getHistoryARTable(
    kpiType,
    inputDateRead,
    page,
    size,
    service,
    false,
    (data, httpStatus) => {
      dispatch(historyARTableUpdateAction({ data, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(historyARTableErrorAction({ errorMessage, httpStatus }))
    }
  )
}

// istanbul ignore next
export const fetchKpiHistoryKS = (
  kpiType: string,
  inputDateRead: string,
  page: number,
  size: number, service: string) => async (dispatch: any) => {
  dispatch(historyKSTableLoadingAction())
  void KpiHistoryService.getHistoryKSTable(
    kpiType,
    inputDateRead,
    page,
    size,
    service,
    false,
    (data, httpStatus) => {
      dispatch(historyKSTableUpdateAction({ data, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(historyKSTableErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const fetchKpiUserPreference = (emailId: string) => async (dispatch: any) => {
  dispatch(kpiUserPreferenceLoadingAction())
  void getKpiUserPreferenceService(
    emailId,
    (data, httpStatus) => {
      dispatch(kpiUserPreferenceUpdateAction({ data, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(kpiUserPreferenceErrorAction({ errorMessage, httpStatus }))
    }
  )
}

// istanbul ignore next
export const saveKpiPreference = (kpiUserPreferenceRequest: KpiPreferenceRequest) => async (dispatch: any) => {
  dispatch(saveKpiUserPreferenceLoadingAction())
  void saveKpiUserPreference(
    kpiUserPreferenceRequest,
    (data, httpStatus) => {
      dispatch(saveKpiUserPreferenceUpdateAction({ data, httpStatus }))
    },
    (httpStatus) => {
      dispatch(saveKpiUserPreferenceErrorAction({ httpStatus }))
    }
  )
}

// onut kpi action creators

// istanbul ignore next
export const fetchTotalReadsDonutData = (kpiType: string, service: string, kpiCategoryId: string) => async (dispatch: any) => {
  dispatch(totalReadsDonutLoadingAction())
  void DonutKpiService.getDonutKpiData(
    kpiType,
    service,
    kpiCategoryId,
    (data, httpStatus) => {
      // istanbul ignore next
      dispatch(totalReadsDonutUpdateAction({ data, httpStatus }))
    },
    (httpStatus) => {
      dispatch(totalReadsDonutErrorAction(httpStatus))
    }
  )
}

// istanbul ignore next
export const fetchEcModeDonutData = (kpiType: string, service: string, kpiCategoryId: string) => async (dispatch: any) => {
  dispatch(ecModeDonutLoadingAction())
  void DonutKpiService.getDonutKpiData(
    kpiType,
    service,
    kpiCategoryId,
    (data, httpStatus) => {
      // istanbul ignore next
      dispatch(ecModeDonutUpdateAction({ data, httpStatus }))
    },
    (httpStatus) => {
      dispatch(ecModeDonutErrorAction(httpStatus))
    }
  )
}

// istanbul ignore next
export const fetchSignalBreakdownDonutData = (kpiType: string, service: string, kpiCategoryId: string) => async (dispatch: any) => {
  dispatch(signalBreakdownDonutLoadingAction())
  void DonutKpiService.getDonutKpiData(
    kpiType,
    service,
    kpiCategoryId,
    (data, httpStatus) => {
      // istanbul ignore next
      dispatch(signalBreakdownDonutUpdateAction({ data, httpStatus }))
    },
    (httpStatus) => {
      dispatch(signalBreakdownDonutErrorAction(httpStatus))
    }
  )
}

// istanbul ignore next
export const fetchAlarmBreakdownDonutData = (kpiType: string, service: string, kpiCategoryId: string) => async (dispatch: any) => {
  dispatch(alarmBreakdownDonutLoadingAction())
  void DonutKpiService.getDonutKpiData(
    kpiType,
    service,
    kpiCategoryId,
    (data, httpStatus) => {
      // istanbul ignore next
      dispatch(alarmBreakdownDonutUpdateAction({ data, httpStatus }))
    },
    (httpStatus) => {
      dispatch(alarmBreakdownDonutErrorAction(httpStatus))
    }
  )
}

// istanbul ignore next
export const fetchFirmwareBreakdownDonutData = (kpiType: string, service: string, kpiCategoryId: string) => async (dispatch: any) => {
  dispatch(firmwareBreakdownDonutLoadingAction())
  void DonutKpiService.getDonutKpiData(
    kpiType,
    service,
    kpiCategoryId,
    (data, httpStatus) => {
      // istanbul ignore next
      dispatch(firmwareBreakdownDonutUpdateAction({ data, httpStatus }))
    },
    (httpStatus) => {
      dispatch(firmwareBreakdownDonutErrorAction(httpStatus))
    }
  )
}

// istanbul ignore next
export const fetchBatteryBreakdownDonutData = (kpiType: string, service: string, kpiCategoryId: string) => async (dispatch: any) => {
  dispatch(batteryBreakdownDonutLoadingAction())
  void DonutKpiService.getDonutKpiData(
    kpiType,
    service,
    kpiCategoryId,
    (data, httpStatus) => {
      // istanbul ignore next
      dispatch(batteryBreakdownDonutUpdateAction({ data, httpStatus }))
    },
    (httpStatus) => {
      dispatch(batteryBreakdownDonutErrorAction(httpStatus))
    }
  )
}

// istanbul ignore next
export const fetchLifeCycleBreakdownDonutData = (kpiType: string, service: string, kpiCategoryId: string) => async (dispatch: any) => {
  dispatch(lifecycleBreakdownDonutLoadingAction())
  void DonutKpiService.getDonutKpiData(
    kpiType,
    service,
    kpiCategoryId,
    (data, httpStatus) => {
      // istanbul ignore next
      dispatch(lifecycleBreakdownDonutUpdateAction({ data, httpStatus }))
    },
    (httpStatus) => {
      dispatch(lifecycleBreakdownDonutErrorAction(httpStatus))
    }
  )
}

// istanbul ignore next
export const fetchHistoryDonutData = (kpiType: string, service: string, kpiCategoryId: string) => async (dispatch: any) => {
  dispatch(historyDonutLoadingAction())
  void DonutKpiService.getDonutKpiData(
    kpiType,
    service,
    kpiCategoryId,
    (data, httpStatus) => {
      dispatch(historyDonutUpdateAction({ data, httpStatus }))
    },
    (httpStatus) => {
      dispatch(historyDonutErrorAction(httpStatus))
    }
  )
}

// istanbul ignore next
export const fetchDonutKpiHistoryTable = (
  kpiType: string,
  inputDateRead: string,
  donutType: string,
  page: number,
  size: number, service: string) => async (dispatch: any) => {
  dispatch(historyDonutTableLoadingAction())
  void KpiHistoryService.getHistoryDonutTable(
    kpiType,
    inputDateRead,
    donutType,
    page,
    size,
    service,
    false,
    (data, httpStatus) => {
      dispatch(historyDonutTableUpdateAction({ data, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(historyDonutTableErrorAction({ errorMessage, httpStatus }))
    }
  )
}

// istanbul ignore next
export const fetchDonutKpiBarGraph = (startDate: string, endDate: string, kpiType: string, barType: string, service: string, kpiCategoryId: string) => async (dispatch: any) => {
  dispatch(donutKpiBarGraphLoadingAction())
  void KpiService.getDonutKpiBarGraphData(
    startDate,
    endDate,
    kpiType,
    barType,
    service,
    kpiCategoryId,
    (data, httpStatus) => {
      dispatch(donutKpiBarGraphUpdateAction({ data, httpStatus }))
    },
    (httpStatus) => {
      dispatch(donutKpiBarGraphErrorAction(httpStatus))
    }
  )
}

// istanbul ignore next
export const resetKpiDetails = () => async (dispatch: any) => {
  dispatch(historyDonutResetAction())
  dispatch(historyDonutTableResetAction())
  dispatch(donutKpiBarGraphResetAction())
  dispatch(historyARTableResetAction())
}
