import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type DonutKpiDataObject, type DonutKpiState } from '../../types'

const initialState: DonutKpiState = {
  isLoading: true,
  error: false,
  data: undefined,
  httpStatus: 0
}

const signalBreakdownDonutSlice = createSlice({
  name: 'signalBreakdownDonut',
  initialState,
  reducers: {
    signalBreakdownDonutLoadingAction: (state) => {
      return {
        isLoading: true,
        error: false,
        data: undefined,
        httpStatus: 0
      }
    },
    signalBreakdownDonutUpdateAction: (state, action: PayloadAction<{ data: DonutKpiDataObject, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: false,
        data: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    signalBreakdownDonutErrorAction: (state, action: PayloadAction<number>) => {
      return {
        isLoading: false,
        error: true,
        data: undefined,
        httpStatus: action.payload
      }
    }
  }
})

export const {
  signalBreakdownDonutLoadingAction,
  signalBreakdownDonutUpdateAction,
  signalBreakdownDonutErrorAction
} = signalBreakdownDonutSlice.actions

export default signalBreakdownDonutSlice.reducer
