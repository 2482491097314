import interceptedAxios from '../../../utils/interceptedAxios'
import { z } from 'zod'

const ZAssetTag = z.object({
  name: z.string()
})

const ZAsset = z.object({
  id: z.string(),
  name: z.string().nullable(),
  serial_number: z.string().nullable(),
  device_type: z.string().nullable(),
  // comm_type: z.string().nullable(),
  // rem_bat_percent: z.number().nullable(),
  comm_module_serial_number: z.string().nullable(),
  state: z.string().nullable(),
  latitude: z.number().nullable(),
  longitude: z.number().nullable(),
  install_date: z.string().nullable(),
  firmware_version: z.string().nullable(),
  removal_date: z.string().nullable(),
  // ip_address: z.string().nullable(),
  // port: z.string().nullable(),
  tag: z.array(ZAssetTag).nullable()
})

const ZTopology = z.object({
  parent: ZAsset.nullable(),
  children: z.array(ZAsset).nullable()
})

export type TopologyDto = z.infer<typeof ZTopology>

export async function getTopologyById (
  id: string,
  tenantId: string,
  onSuccess: (topologyData: TopologyDto, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  let locale = 'en-US'

  // istanbul ignore next
  if (navigator.language.startsWith('es') || navigator.language.startsWith('Spanish')) {
    locale = 'es-ES'
  }
  const url = `/assetrest/api/v1/udp/topology/${id}?locale=${locale}&tenantId=${tenantId}`
  interceptedAxios.get(url)
    .then((response) => {
      // istanbul ignore else
      if (response.status === 200) {
        console.log('parsing response', response.data.data)
        const parseResponse = ZTopology.safeParse(response.data.data)
        console.log('parsed topology', parseResponse)
        if (parseResponse.success) {
          const parsedData: TopologyDto = parseResponse.data
          onSuccess(parsedData, response.status)
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        if (error.response.data !== undefined) {
          onFailure(error.response.data.error_message, error.response.status)
        } else {
          onFailure(error.message, error.response.status)
        }
      } else {
        onFailure(error.message, error.status)
      }
    })
}
