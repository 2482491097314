import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface AssetConfig {
  parameter: string | null
  configValue: string | number | boolean | null
}
export interface exportAssetConfigDetailsState {
  isLoading: boolean
  loadingError: string | null
  exportAssetConfigDetails: AssetConfig[] | null
}

// Initial state of the slice
const initialState: exportAssetConfigDetailsState = {
  isLoading: true,
  loadingError: null,
  exportAssetConfigDetails: null
}

// Slice to return from action
export const exportAssetConfigDetailsSlice = createSlice({
  name: 'exportAssetConfigDetails',
  initialState,
  reducers: {
    exportAssetConfigLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        exportAssetConfigDetails: []
      }
    },
    exportAssetConfigUpdateAction: (state, action: PayloadAction< AssetConfig[] >) => {
      return {
        isLoading: false,
        loadingError: null,
        exportAssetConfigDetails: action.payload
      }
    },
    exportAssetConfigErrorAction: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload,
        exportAssetConfigDetails: null
      }
    }
  }
})

// Actions to export
export const {
  exportAssetConfigLoadingAction,
  exportAssetConfigUpdateAction,
  exportAssetConfigErrorAction
} = exportAssetConfigDetailsSlice.actions

export default exportAssetConfigDetailsSlice.reducer
