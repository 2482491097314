// istanbul ignore file
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { type ClusterProperties } from 'supercluster'
import type Supercluster from 'supercluster'
import { FeaturesClusterMarker } from './features-cluster-marker'
import FeatureMarker from './feature-marker'
import { useSupercluster } from './hooks/use-supercluster'
import { type FeatureCollection, type GeoJsonProperties, type Point } from 'geojson'
import { type AlarmFeatureProperties, type AlarmFeatureCollection } from '../points'
import { getTopIssuesForPosition } from '../redux/actionCreator'
import { type TopIssuesForPositionState } from '../types'
import { type RootState } from '../../../store'
import { connect, type ConnectedProps } from 'react-redux'
import { type InfoDataType } from '../GeoJsonMap'
import CustomInforWindow from '../components/CustomInfoWindow'
import { InfoModalWithInfiniteLoading } from '../infoPopup'

interface ClusteredMarkersProps {
  geojson: FeatureCollection<Point> & AlarmFeatureCollection
  handleClusterClick: (position: google.maps.LatLngLiteral) => void
}

const superclusterOptions: Supercluster.Options<GeoJsonProperties, ClusterProperties> = {
  extent: 256,
  radius: 80,
  maxZoom: 12
}

const ClusteredMarkers = React.memo((props: AllProps): JSX.Element => {
  const [infowindowData, setInfowindowData] = useState<InfoDataType | null>(null)
  const [infoWindowModal, setInfoWindowModal] = useState<boolean>(false)
  const { clusters } = useSupercluster(props.geojson, superclusterOptions)
  const [selectedPosition, setSelectedPosition] = React.useState<google.maps.LatLngLiteral | null>(null)

  const handleMarkerClick = useCallback((position: google.maps.LatLngLiteral) => {
    setSelectedPosition(position)
    props.getTopIssuesForPosition(true, position.lat, position.lng)
  }, [props])

  useEffect(() => {
    if (selectedPosition !== null && props.topIssuesForPosition.topIssues !== undefined) {
      setInfowindowData({ topIssues: props.topIssuesForPosition.topIssues, position: selectedPosition })
    }
  }, [props.topIssuesForPosition, selectedPosition])

  const renderedClusters = useMemo(() => clusters.map((feature) => {
    const [lng, lat] = feature.geometry.coordinates
    const alarmCount = (feature.properties as AlarmFeatureProperties).alarmCount
    const clusterProperties = feature.properties as ClusterProperties
    const isCluster: boolean = clusterProperties.cluster

    return isCluster
      ? (
      <FeaturesClusterMarker
        totalCount={props.geojson.features.length}
        key={`${lat}-${lng}-${clusterProperties.point_count}`}
        position={{ lat, lng }}
        size={clusterProperties.point_count}
        sizeAsText={String(clusterProperties.point_count_abbreviated)}
        onMarkerClick={props.handleClusterClick}
      />
        )
      : (
      <FeatureMarker
        key={`${lat}-${lng}-${alarmCount}`}
        count={alarmCount}
        featureId={feature.id as string}
        position={{ lat, lng }}
        onMarkerClick={handleMarkerClick}
      />
        )
  }), [clusters, props.geojson.features.length, props.handleClusterClick, handleMarkerClick])

  return (
    <>
      {renderedClusters}
      {infowindowData !== null && (
        <CustomInforWindow
          position={infowindowData.position}
          selectedMarker={infowindowData.topIssues}
          setSelectedMarker={setInfowindowData}
          showInfoModal={infowindowData.topIssues}
          setInfoWindowModal={setInfoWindowModal}
        />
      )}
      {infoWindowModal && infowindowData !== null && (
        <InfoModalWithInfiniteLoading
          markerData={infowindowData?.topIssues}
          handleModalClose={() => {
            setInfoWindowModal(false)
            setInfowindowData(null)
          }}
        />
      )}
    </>
  )
})

ClusteredMarkers.displayName = 'ClusteredMarkers'

interface DispatchToProps {
  getTopIssuesForPosition: (unPaged: boolean, latitude: number, longitude: number) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  getTopIssuesForPosition: (unPaged: boolean, latitude: number, longitude: number) => dispatch(getTopIssuesForPosition(unPaged, latitude, longitude))
})

interface StateToProps {
  topIssuesForPosition: TopIssuesForPositionState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  topIssuesForPosition: state.topIssuesForPosition
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>
type AllProps = PropsFromRedux & ClusteredMarkersProps

export default connector(ClusteredMarkers)
