import React, { useEffect } from 'react'
import { Box, Breadcrumbs, Link, Tab, Tabs, Typography } from '@mui/material'
import Overview from './components/Overview'
import { type ConnectedProps, connect } from 'react-redux'
import { type RootState } from '../../store'
import { type AssetByIdState } from './redux/assetByIdSlice'
import {
  fetchAssetData,
  fetchChannelData,
  fetchAlarmsById,
  fetchSignalData,
  fetchChannelDropdownData,
  fetchWaterKpiData,
  fetchAssetAttribute,
  fetchTopology,
  fetchEventsTableData,
  fetchAlarmHistory,
  fetchAssetDetails
} from './redux/actionCreator'
import { changeLanguage } from '../../store/language'
import LanguageStrings from '../../i18n/locales'
import { changeAssetId } from './redux/assetIdSlice'
import DataSource from './components/DataSource'
import { type AlarmByIdState } from './redux/alarmsByIdSlice'
import CellularSignalStrength from './components/CellularSignalStrength'
import { type signalState } from './redux/cellularSignalSlice'
import moment from 'moment'
import { useAuth } from 'react-oidc-context'
import { type channelDropdownInterface } from './redux/channelDropdownSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { TabPanel, a11yProps } from '../../components/TabsHelper'
import Topology from './components/Topology'
import DeviceUtil from '../../utils/DeviceUtil'
import { type AssetType } from '../../utils/NetworkGraphUtils'
import { type ChannelDataState } from './redux/channelDataSlice'
import DeviceTableEvents from './components/dataSourceComponents/DeviceTableEvents'
import { type ChannelDropdownData } from './services/channelDropDownService'
import AssetAttribute from './components/assetConfiguration/AssetAttribute'
import { type topologyInterface } from './redux/topologyDataSlice'
import { type eventsInterface } from './redux/eventsSlice'
import BackButton from '../../components/BackButton'
import { type AssetPropertiesByIdState } from './redux/assetPropertiesByIdSlice'

const DeviceDetails = (props: AllProps): JSX.Element => {
  const [value, setValue] = React.useState(0)
  const [hasTopology, setHasTopology] = React.useState(false)
  const auth = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const activeStyle = { fontSize: '12px', color: '#F0F0F0' }
  const inActiveStyle = { fontSize: '12px', color: '#A0A0A0' }
  const usrlang = navigator.language
  props.changeLanguage(usrlang)
  const ipConfigState = useSelector((state: RootState) => state.ipConfigState)
  const DeviceDetailsStrings = LanguageStrings().DeviceDetailsStrings
  let paramAssetId = sessionStorage.getItem('paramAssetId')
  let paramAssetName = sessionStorage.getItem('paramAssetName')

  // istanbul ignore next
  if (paramAssetId === null) {
    paramAssetId = ''
  }
  // istanbul ignore next
  if (paramAssetName === null) {
    paramAssetName = ''
  }

  useEffect(() => {
    if (props.assetData.assetData !== null) {
      setHasTopology(DeviceUtil.getDeviceTypeByName(props.assetData.assetData.device_type)?.hasTopology)
    }
  }, [props.assetData])

  const handleFetchAssetData = (): void => {
    if (paramAssetName !== undefined) {
      props.fetchAssetData(paramAssetId as string, auth.user?.profile.customer as string)
    }
  }

  const handleFetchAssetPropertiesData = (): void => {
    if (paramAssetName !== undefined) {
      props.fetchAssetDetails(paramAssetId as string, auth.user?.profile.customer as string)
    }
  }

  useEffect(() => {
    const end = moment()
    end.set({ hour: 23, minute: 59, second: 59 })
    // istanbul ignore else
    if (paramAssetId !== undefined) {
      handleFetchAssetData()
      handleFetchAssetPropertiesData()
      props.fetchAssetAttribute(paramAssetId ?? '')
      props.fetchChannelDropdownData(paramAssetId ?? '', auth.user?.profile.customer as string)
    }
  }, [ipConfigState])

  // istanbul ignore next
  const fetchAlarmsById = (): void => {
    console.log('Api called 2')
    if (props.assetId !== '') {
      console.log('Api called 3', props.assetId)
      props.fetchAlarmsById(props.assetId, auth.user?.profile.customer as string)
    } else if (paramAssetId !== undefined) {
      console.log('Api called 4', paramAssetId)
      props.fetchAlarmsById(paramAssetId as string, auth.user?.profile.customer as string)
    }
  }

  useEffect(() => {
    console.log('Api called 1')
    fetchAlarmsById()
  }, [props.assetId])

  const handleChange = (event: React.SyntheticEvent, newValue: number): any => {
    setValue(newValue)
  }

  let assetId = ''
  // istanbul ignore else
  if (paramAssetId !== undefined) {
    assetId = paramAssetId
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getStyle = (index: number) => {
    if (index === value) return activeStyle
    return inActiveStyle
  }

  // istanbul ignore next
  function handleBreadCrumbsClick (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    event.preventDefault()
  }

  const getPreviousPath = (): string => {
    return location.state?.previousPath ?? '/asset-list'
  }

  getPreviousPath()

  const grandParentBreadcrumbRoute = props.grandParentBreadcrumb === LanguageStrings().Dashboard.Asset_Management ? '/card' : '/isense'

  return (
    <>
    <div className='devicedetails' style={{ width: 'calc(100vw - 3.5rem)' }}>
    <div onClick={handleBreadCrumbsClick} className='DeviceDetailHeading' >
        <Breadcrumbs aria-label="breadcrumb" sx={{ color: '#f0f0f0', fontSize: '12px', marginLeft: '1em', fontFamily: 'Honeywell Sans Web', marginTop: '12px' }}>
            <Link aria-label="navBreadcrumb" className='assetmgmtli' underline='none' color="inherit" onClick={() => { navigate(grandParentBreadcrumbRoute) }} >
            {props.grandParentBreadcrumb}
            </Link>

            {getPreviousPath() === '/asset-list' && <Typography color="inherit" sx={{ fontSize: '12px', fontFamily: 'Honeywell Sans Web' }} onClick={() => { navigate('/asset-list') }} className='assetmgmtli'>{DeviceDetailsStrings.Overview.Asset_List}</Typography> }
            <Typography color="inherit" sx={{ fontSize: '12px', fontFamily: 'Honeywell Sans Web', cursor: 'text' }}>{paramAssetName}</Typography>
        </Breadcrumbs>
    </div>
      <div className="topUtilityBar">
        <BackButton dataTestId='back-arrow' onclick={() => { navigate(getPreviousPath()) }} />
      <span id="componentTitle">{paramAssetName}</span>
      </div>
      <Box sx={{ padding: '1em' }}>
        <Box sx={{ borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab value={0} style={getStyle(0)} label="Overview" {...a11yProps(0)} />
            <Tab value={1} style={getStyle(1)} label="Data Sources" {...a11yProps(1)} />
            <Tab value={2} style={getStyle(2)} label="Network" {...a11yProps(2)} />
            <Tab value={3} style={getStyle(3)} label="Asset Configuration" {...a11yProps(3)} />
            <Tab value={4} style={getStyle(4)} label="Events" {...a11yProps(4)} />
            {(hasTopology) && <Tab value={5} style={getStyle(5)} label="Topology" {...a11yProps(5)} />}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} data-testid='overviewTab'>
          <Overview handleFetchAssetData={handleFetchAssetData} fetchAlarmsById={fetchAlarmsById} fetchAlarmHistory={props.fetchAlarmHistory} OverviewStrings={DeviceDetailsStrings.Overview} assetData={props.assetData} assetPropertiesData={props.propertiesData} alarmsById={props.alarmsById} />
        </TabPanel>
        <TabPanel value={value} index={1} data-testid='dataSourceTab'>
          <DataSource chartData={props.channelDataDeviceDetail} channelDropdownData={props.channelDropdownData} fetchChannelData={props.fetchChannelData} fetchWaterKpiData={props.fetchWaterKpiData} fetchChannelDropdownData={props.fetchChannelDropdownData}/>
        </TabPanel>
        <TabPanel value={value} index={2} data-testid='cellularSignalStrengthTab'>
          <CellularSignalStrength fetchSignalData={props.fetchSignalData} tenant={auth.user?.profile.customer as string} signalData={props.signalData} assetId={assetId} assetType={props.assetData.assetData?.device_type as AssetType}/>
        </TabPanel>
        <TabPanel value={value} index={3} data-testid='assetAttributeTab'>
          <AssetAttribute />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <DeviceTableEvents fetchEventsTableData={props.fetchEventsTableData} tenantId={auth.user?.profile.customer as string} tableData={props.eventsData} assetId={assetId} assetType={props.assetData.assetData?.device_type as AssetType} />
        </TabPanel>
        {hasTopology && <TabPanel value={value} index={5} data-testid='topologyTab'>
          <Topology fetchTopology={props.fetchTopology} tenant={auth.user?.profile.customer as string} topologyData={props.topology} assetId={assetId} />
        </TabPanel>}
      </Box>
    </div>
    </>
  )
}

interface DispatchToProps {
  fetchAssetData: (id: string, tenantId: string) => void
  changeLanguage: (count: string) => void
  changeAssetId: (id: string) => void
  fetchChannelData: (assetId: string, fromDate: string, toDate: string, selectedChannel: string, selectedChannelUnit: string, tenantId: string, channelAttributes: ChannelDropdownData | undefined) => void
  fetchWaterKpiData: (assetId: string, fromDate: string, toDate: string, selectedChannelUnit: string, selectedKpi: string, tenantId: string, channelAttributes: ChannelDropdownData | undefined) => void
  fetchAlarmsById: (id: string, tenantId: string) => void
  fetchSignalData: (tenant: string, assetId: string, from: string, to: string, assetType: AssetType) => void
  fetchChannelDropdownData: (assetId: string, tenantId: string) => void
  fetchAssetAttribute: (assetId: string) => void
  fetchTopology: (assetId: string, tenantId: string) => void
  fetchEventsTableData: (assetId: string, page: number, rowsPerPage: number, tenantId: string, sortdir: string, sortfield: string) => void
  fetchAlarmHistory: (assetId: string, page: number, size: number) => void
  fetchAssetDetails: (id: string, tenantId: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchAssetData: (id: string, tenantId: string) => dispatch(fetchAssetData(id, tenantId)),
  changeLanguage: (language: string) => dispatch(changeLanguage(language)),
  changeAssetId: (id: string) => dispatch(changeAssetId(id)),
  fetchChannelData: (assetId: string, fromDate: string, toDate: string, selectedChannel: string, selectedChannelUnit: string, tenantId: string, channelAttributes: ChannelDropdownData | undefined) => dispatch(fetchChannelData(assetId, fromDate, toDate, selectedChannel, selectedChannelUnit, tenantId, channelAttributes)),
  fetchWaterKpiData: (assetId: string, fromDate: string, toDate: string, selectedChannelUnit: string, selectedKpi: string, tenantId: string, channelAttributes: ChannelDropdownData | undefined) => dispatch(fetchWaterKpiData(assetId, fromDate, toDate, selectedChannelUnit, selectedKpi, tenantId, channelAttributes)),
  fetchAlarmsById: (id: string, tenantId: string) => dispatch(fetchAlarmsById(id, tenantId)),
  fetchSignalData: (tenant: string, assetId: string, from: string, to: string, assetType: AssetType) => dispatch(fetchSignalData(tenant, assetId, from, to, assetType)),
  fetchChannelDropdownData: (assetId: string, tenantId: string) => dispatch(fetchChannelDropdownData(assetId, tenantId)),
  fetchAssetAttribute: (assetId: string) => dispatch(fetchAssetAttribute(assetId)),
  fetchTopology: (assetId: string, tenantId: string) => dispatch(fetchTopology(assetId, tenantId)),
  fetchEventsTableData: (assetId: string, page: number, rowsPerPage: number, tenantId: string, sortdir: string, sortfield: string) => dispatch(fetchEventsTableData(assetId, page, rowsPerPage, tenantId, sortdir, sortfield)),
  fetchAlarmHistory: (assetId, page, size) => dispatch(fetchAlarmHistory(assetId, page, size)),
  fetchAssetDetails: (id: string, tenantId: string) => dispatch(fetchAssetDetails(id, tenantId))
})

interface StateToProps {
  assetData: AssetByIdState
  language: string
  assetId: string
  channelDataDeviceDetail: ChannelDataState
  alarmsById: AlarmByIdState
  signalData: signalState
  activeTenant: string
  channelDropdownData: channelDropdownInterface
  grandParentBreadcrumb: string
  topology: topologyInterface
  eventsData: eventsInterface
  propertiesData: AssetPropertiesByIdState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  assetData: state.assetById,
  language: state.language,
  assetId: state.assetId,
  channelDataDeviceDetail: state.channelDataDeviceDetail,
  alarmsById: state.alarmsById,
  signalData: state.signalData,
  activeTenant: state.tenant,
  channelDropdownData: state.channelDropdownData,
  grandParentBreadcrumb: state.grandParentBreadcrumb,
  topology: state.topology,
  eventsData: state.eventsData,
  propertiesData: state.assetPropertiesById
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

type AllProps = PropsFromRedux

export default connector(DeviceDetails)
