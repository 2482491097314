/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import interceptedAxios from '../../../utils/interceptedAxios'
export async function getEvents (
  assetId: string,
  page: number,
  size: number,
  tenantId: string,
  sortdir: string,
  sortfield: string,
  onSuccess: (events: any, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any

): Promise<void> {
  if (sortfield === 'eventTime') {
    sortfield = 'recordedAt'
  } else if (sortfield === 'collectedTime') {
    sortfield = 'collectionTime'
  } else if (sortfield === 'event') {
    sortfield = 'EventType_translation_translation'
  }

  const url = `/alarm-service/api/v1/udp/assets/events/${assetId}?tenantId=${tenantId}&page=${page}&size=${size}&sort=${sortfield},${sortdir}`

  interceptedAxios.get(url)
    .then((response) => {
      if (response.status === 200 && !response.data.error) {
        onSuccess(response.data.data, response.status)
      } else {
        onFailure(response.data.error, response.status)
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        if (error.response.data !== undefined) {
          onFailure(error.response.data.error_message, error.response.status)
        } else {
          onFailure(error.message, error.response.status)
        }
      } else {
        onFailure(error.message, error.status)
      }
    })
}
