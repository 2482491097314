import { type TooltipFormatterContextObject } from 'highcharts'
import type DeviceUtil from '../utils/DeviceUtil'
import { parseDateInAOHstandard } from '../customHooks/parseDate'

interface CustomSeries extends Highcharts.Series {
  processedYData: number[]
}

export interface GraphParameters {
  kpiDefinitionIds: string[]
}

export type AssetType = keyof typeof DeviceUtil.deviceTypes

export type NETWORK_KPI = 'Signal Strength' | 'Signal Quality' | 'Signal Power'

export const NETWORK_KPI_TO_ID: Record<NETWORK_KPI, string> = {
  'Signal Strength': '7bffd070-348c-4096-9852-15f729c3534d',
  'Signal Quality': '8a35f202-cdf6-4775-8639-5cf23a622262',
  'Signal Power': 'b5477914-f4dc-441b-81c1-ee09bcdd25db'
}

export const ID_TO_NETWORK_KPI: Record<string, NETWORK_KPI> = {
  '7bffd070-348c-4096-9852-15f729c3534d': 'Signal Strength',
  '8a35f202-cdf6-4775-8639-5cf23a622262': 'Signal Quality',
  'b5477914-f4dc-441b-81c1-ee09bcdd25db': 'Signal Power'
}

export const getGraphColorByName = (name: NETWORK_KPI): string => {
  switch (name) {
    case 'Signal Strength':
      return '#97E7CC'
    case 'Signal Quality':
      return '#64C3FF'
    case 'Signal Power':
      return '#F566A2'
  }
}

export const getGraphParameters = (deviceType: keyof typeof DeviceUtil.deviceTypes): GraphParameters => {
  if (deviceType === 'Water-Merlin' || deviceType === 'Agua-Merlin') {
    return {
      kpiDefinitionIds: ['7bffd070-348c-4096-9852-15f729c3534d', '8a35f202-cdf6-4775-8639-5cf23a622262']
    }
  } else if (deviceType === 'Water-Q600' || deviceType === 'Agua-Q600') {
    return {
      kpiDefinitionIds: ['7bffd070-348c-4096-9852-15f729c3534d', '8a35f202-cdf6-4775-8639-5cf23a622262', 'b5477914-f4dc-441b-81c1-ee09bcdd25db']
    }
  } else if (deviceType === 'Water-HBG3' || deviceType === 'Agua-HBG3') {
    return {
      kpiDefinitionIds: ['7bffd070-348c-4096-9852-15f729c3534d', '8a35f202-cdf6-4775-8639-5cf23a622262']
    }
  } else if (deviceType === 'Water-V200H-NBIOT' || deviceType === 'Agua-V200H-NBIOT') {
    return {
      kpiDefinitionIds: ['7bffd070-348c-4096-9852-15f729c3534d', '8a35f202-cdf6-4775-8639-5cf23a622262', 'b5477914-f4dc-441b-81c1-ee09bcdd25db']
    }
  } else {
    return {
      kpiDefinitionIds: ['7bffd070-348c-4096-9852-15f729c3534d', '8a35f202-cdf6-4775-8639-5cf23a622262']
    }
  }
}

// istanbul ignore next
export function networkDetailTooltip (kpi: NETWORK_KPI): string {
  switch (kpi) {
    case 'Signal Strength':
      return 'RSSI'
    case 'Signal Quality':
      return 'RSRQ'
    case 'Signal Power':
      return 'RSRP'
    default:
      return kpi
  }
}

// istanbul ignore next
export function getUnitForKpi (kpi: NETWORK_KPI): string {
  switch (kpi) {
    case 'Signal Strength':
      return 'dBm'
    case 'Signal Quality':
      return 'dB'
    case 'Signal Power':
      return 'dBm'
    default:
      return '#F566A2'
  }
}

export const getTooltipText = (
  point: TooltipFormatterContextObject,
  networkData: Array<{
    name: NETWORK_KPI
    color: string
    data: Array<[number, number | null]>
  }>): string => {
  if (networkData.length === 3) {
    return getThreeTooltipText(point)
  }
  return getTwoTooltipText(point)
}

function getFormatedSingleTooltip (name: NETWORK_KPI, date: string, value: string): string {
  return (
    `<span style="color:${getGraphColorByName(name)}; font-size: 18px">\u25CF</span> <b>${name}</b><br>
    <span>\u25CF</span> ${date}<br>
    <span>\u25CF</span> ${networkDetailTooltip(name)}: ${value}<br>
    `
  )
}

export const getThreeTooltipText = (point: TooltipFormatterContextObject): string => {
  const y1 = (point.series.chart.series[0] as CustomSeries).processedYData[point.point.index]
  const y2 = (point.series.chart.series[1] as CustomSeries).processedYData[point.point.index]
  const y3 = (point.series.chart.series[2] as CustomSeries).processedYData[point.point.index]
  const y1Name = point.series.chart.series[0].name as NETWORK_KPI
  const y2Name = point.series.chart.series[1].name as NETWORK_KPI
  const y3Name = point.series.chart.series[2].name as NETWORK_KPI
  const y1Unit = getUnitForKpi(y1Name)
  const y2Unit = getUnitForKpi(y2Name)
  const y3Unit = getUnitForKpi(y3Name)
  const dateText = parseDateInAOHstandard(point.x as string)
  let tooltipText = ''
  // istanbul ignore else
  // istanbul ignore next
  if (y1 === y2 && y2 === y3) {
    const y1Value = y1 !== undefined && y1 !== null ? y1.toString() + ' ' + y1Unit : 'Not Available'
    const y2Value = y2 !== undefined && y2 !== null ? y2.toString() + ' ' + y2Unit : 'Not Available'
    const y3Value = y3 !== undefined && y3 !== null ? y3.toString() + ' ' + y3Unit : 'Not Available'
    tooltipText = `
            ${getFormatedSingleTooltip(y1Name, dateText, y1Value)}
            _________________________________
            <br>
            ${getFormatedSingleTooltip(y2Name, dateText, y2Value)}
            _________________________________
            <br>
            ${getFormatedSingleTooltip(y3Name, dateText, y3Value)}`
  } else if (point.series.name === y3Name) {
    if (y1 === y3) {
      const y1Value = y1 !== undefined && y1 !== null ? y1.toString() + ' ' + y1Unit : 'Not Available'
      const y3Value = y3 !== undefined && y3 !== null ? y3.toString() + ' ' + y3Unit : 'Not Available'
      tooltipText = `
            ${getFormatedSingleTooltip(y1Name, dateText, y1Value)}
            _________________________________
            <br>
            ${getFormatedSingleTooltip(y3Name, dateText, y3Value)}`
    } else if (y2 === y3) {
      const y2Value = y2 !== undefined && y2 !== null ? y2.toString() + ' ' + y2Unit : 'Not Available'
      const y3Value = y3 !== undefined && y3 !== null ? y3.toString() + ' ' + y3Unit : 'Not Available'
      tooltipText = `
            ${getFormatedSingleTooltip(y2Name, dateText, y2Value)}
            _________________________________
            <br>
            ${getFormatedSingleTooltip(y3Name, dateText, y3Value)}`
    } else {
      const consumption = point.y !== undefined && point.y !== null ? point.y.toString() + ' ' + getUnitForKpi(point.series.name) : 'Not Available'
      tooltipText = getFormatedSingleTooltip(point.series.name, dateText, consumption)
    }
  } else if (point.series.name === y2Name) {
    if (y1 === y2) {
      const y1Value = y1 !== undefined && y1 !== null ? y1.toString() + ' ' + y1Unit : 'Not Available'
      const y2Value = y2 !== undefined && y2 !== null ? y2.toString() + ' ' + y2Unit : 'Not Available'
      tooltipText = `
            ${getFormatedSingleTooltip(y1Name, dateText, y1Value)}
            _________________________________
            <br>
            ${getFormatedSingleTooltip(y2Name, dateText, y2Value)}`
    } else if (y3 === y2) {
      const y2Value = y2 !== undefined && y2 !== null ? y2.toString() + ' ' + y2Unit : 'Not Available'
      const y3Value = y3 !== undefined && y3 !== null ? y3.toString() + ' ' + y3Unit : 'Not Available'
      tooltipText = `
            ${getFormatedSingleTooltip(y3Name, dateText, y3Value)}
            _________________________________
            <br>
            ${getFormatedSingleTooltip(y2Name, dateText, y2Value)}`
    } else {
      const consumption = point.y !== undefined && point.y !== null ? point.y.toString() + ' ' + getUnitForKpi(point.series.name) : 'Not Available'
      tooltipText = getFormatedSingleTooltip(point.series.name, dateText, consumption)
    }
  } else if (point.series.name === y1Name) {
    if (y1 === y3) {
      const y1Value = y1 !== undefined && y1 !== null ? y1.toString() + ' ' + y1Unit : 'Not Available'
      const y3Value = y3 !== undefined && y3 !== null ? y3.toString() + ' ' + y3Unit : 'Not Available'
      tooltipText = `
            ${getFormatedSingleTooltip(y1Name, dateText, y1Value)}
            _________________________________
            <br>
            ${getFormatedSingleTooltip(y3Name, dateText, y3Value)}`
    } else if (y1 === y2) {
      const y1Value = y1 !== undefined && y1 !== null ? y1.toString() + ' ' + y1Unit : 'Not Available'
      const y2Value = y2 !== undefined && y2 !== null ? y2.toString() + ' ' + y2Unit : 'Not Available'
      tooltipText = `
            ${getFormatedSingleTooltip(y1Name, dateText, y1Value)}
            _________________________________
            <br>
            ${getFormatedSingleTooltip(y2Name, dateText, y2Value)}`
    } else {
      const consumption = point.y !== undefined && point.y !== null ? point.y.toString() + ' ' + getUnitForKpi(point.series.name) : 'Not Available'
      tooltipText = getFormatedSingleTooltip(point.series.name, dateText, consumption)
    }
  } else {
    const consumption = point.y !== undefined && point.y !== null ? point.y.toString() + ' ' + getUnitForKpi(point.series.name as NETWORK_KPI) : 'Not Available'
    tooltipText = getFormatedSingleTooltip(point.series.name as NETWORK_KPI, dateText, consumption)
  }

  return tooltipText
}

export const getTwoTooltipText = (point: TooltipFormatterContextObject): string => {
  const y1 = (point.series.chart.series[0] as CustomSeries).processedYData[point.point.index]
  const y1Name = point.series.chart.series[0].name as NETWORK_KPI
  const y2 = (point.series.chart.series[1] as CustomSeries).processedYData[point.point.index]
  const y2Name = point.series.chart.series[1].name as NETWORK_KPI
  const y1Unit = getUnitForKpi(y1Name)
  const y2Unit = getUnitForKpi(y2Name)
  const dateText = parseDateInAOHstandard(point.x as string)
  let tooltipText = ''
  // istanbul ignore else
  // istanbul ignore next
  if (y1 === y2) {
    const y1Value = y1 !== undefined && y1 !== null ? y1.toString() + ' ' + y1Unit : 'Not Available'
    const y2Value = y2 !== undefined && y2 !== null ? y2.toString() + ' ' + y2Unit : 'Not Available'
    tooltipText = `
            ${getFormatedSingleTooltip(y1Name, dateText, y1Value)}
            _________________________________
            <br>
            ${getFormatedSingleTooltip(y2Name, dateText, y2Value)}`
  } else {
    const consumption = point.y !== undefined && point.y !== null ? point.y.toString() + ' ' + getUnitForKpi(point.series.name as NETWORK_KPI) : 'Not Available'
    tooltipText = getFormatedSingleTooltip(point.series.name as NETWORK_KPI, dateText, consumption)
  }

  return tooltipText
}
