/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import interceptedAxios from '../../../utils/interceptedAxios'
import { z } from 'zod'

export const ZMeterImageResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: z.string()
})

export type MeterImageResponse = z.infer<typeof ZMeterImageResponse>
export async function getMeterImage (
  assetId: string,
  onSuccess: (meterImage: MeterImageResponse, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/fileuploadservice/api/v1/udp/asset-profile/downloadMeterImage?assetId=${assetId}`
  interceptedAxios
    .get(url)
    .then((response) => {
      // istanbul ignore else
      if (response.status === 200 && !response.data.error) {
        const parseResponse = ZMeterImageResponse.safeParse(response.data)
        if (parseResponse.success) {
          onSuccess(parseResponse.data, response.status)
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      } else {
        onFailure(response.data.error, response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore else
      if (error.response !== undefined) {
        // istanbul ignore else
        if (error.response.data !== undefined) {
          onFailure(error.response.data.error_message, error.response.status)
        } else {
          onFailure(error.message, error.response.status)
        }
      } else {
        onFailure(error.message, error.status)
      }
    })
}
