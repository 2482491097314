/* eslint-disable */
import * as React from 'react'
import { Button, CircularProgress, FormHelperText, Grid, Typography, getNativeSelectUtilityClasses } from '@mui/material'
import Pagination from "../../../../components/TablePagination"
import { SnackbarOrigin } from '@mui/material/Snackbar'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import CloseIcon from '@mui/icons-material/Close'
import { fetchDeviceList, getAssetStateData, updateAssetStateInfo, resetUpdateStateParams } from '../../redux/actionCreators'
import { visuallyHidden } from '@mui/utils'
import { type RootState } from '../../../../store'
import { DeviceListState } from '../../redux/deviceListSlice'
import { type ConnectedProps, connect } from 'react-redux'
import LanguageStrings from '../../../../i18n/locales'
import { useNavigate } from 'react-router-dom'
import { changeAssetId } from '../../../device-details/redux/assetIdSlice'
import { useDispatch } from 'react-redux'
import { useAuth } from 'react-oidc-context'
import AuthError from '../../../../components/ErrorComponents/AuthError'
import { GenericDropdown, type Option } from '../../../../components/GenericDropdown'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AssetStatesTypeDataState } from '../../redux/assetStatesSlice'
import { type assetStateReq } from '../../services/updateAssetstate'
import { useSelector } from 'react-redux'
import dayjs, { type Dayjs } from "dayjs";
import { type Tag } from '../../services/deviceListService'
import { useRef } from 'react'
import { Button as GenericButton } from '../../../../components/Button'
import { UpdateAssetStateInfo } from '../../redux/updateAssetStateSlice'
import { stateUpdateModel } from './StateUpdateModel'
import { BpCheckbox } from '../../../../components/CustomCheckbox'
import { convertDateIntoAohStandardUsingDayjs } from '../../../../customHooks/parseDate'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const TableStrings = LanguageStrings().AMIDataCollectionStrings.TableComponent

export interface State extends SnackbarOrigin {
    open: boolean
}

let dateLabel = 'Installation Date'

const WhiteDatePicker = styled(DatePicker)({
    "& input": {
        color: "#b8b6b6",
        padding: '10px 14px'
    },
});

const CustomLocalizationProviderWrapper = styled("div")({
    backgroundColor: "#5c5b5bdd",
    borderRadius: "4px",
})

interface Data {
    name: string,
    serial_number: string,
    latitude: number,
    longitude: number,
    install_date: Date | null,
    removal_date: Date | null,
    firmware_version: string,
    tag: Tag[],
    state: string | null
}

const successModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 399,
    bgcolor: '#272727',
    fontFamily: 'Honeywell Sans Web',
    p: 4,
    borderRadius: '5px'
}

export type Order = 'asc' | 'desc'
interface HeadCell {
    disablePadding: boolean;
    id: any;
    label: string;
    numeric: boolean;
    sortable: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: TableStrings.Name,
        sortable: true
    },
    {
        id: 'serial_number',
        numeric: false,
        disablePadding: false,
        label: TableStrings.SerialNumber,
        sortable: true
    },
    {
        id: 'asset_type',
        numeric: false,
        disablePadding: false,
        label: TableStrings.AssetType,
        sortable: false
    },
    {
        id: 'latitude',
        numeric: false,
        disablePadding: false,
        label: TableStrings.Latitude,
        sortable: true
    },
    {
        id: 'longitude',
        numeric: false,
        disablePadding: false,
        label: TableStrings.Longitude,
        sortable: true
    },
    {
        id: 'install_date',
        numeric: false,
        disablePadding: false,
        label: TableStrings.InstallDate,
        sortable: true
    }, {
        id: 'removal_date',
        numeric: false,
        disablePadding: false,
        label: TableStrings.RemovalDate,
        sortable: true
    },
    {
        id: 'firmware_version',
        numeric: false,
        disablePadding: false,
        label: 'Firmware Version',
        sortable: true
    },
    {
        id: 'tag',
        numeric: false,
        disablePadding: false,
        label: TableStrings.Tag,
        sortable: false
    },
    {
        id: 'state',
        numeric: false,
        disablePadding: false,
        label: TableStrings.State,
        sortable: false
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        label: 'ACTION',
        sortable: false
    }
]

function EnhancedTableHead(props: any): any {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, deviceList, deviceListCount } = props
    const createSortHandler =
        (newOrderBy: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, newOrderBy)
        }
    // istanbul ignore else
    if (deviceList !== null && deviceList !== undefined) {
        return (
            <TableHead data-testid="table-header">
                <TableRow>
                    <StyledTableCell padding="checkbox">
                        {
                            deviceList.filter((row: Data) => row.state !== 'Removed').length === 0
                            ? <BpCheckbox inputProps={{ id: 'select-all-items' }} disabled={true} />
                            : <Checkbox
                                sx={{ color: "#909090" }}
                                data-testid="device-table-header-checkbox"
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={rowCount > 0 && numSelected === rowCount}
                                onChange={onSelectAllClick}
                                inputProps={{
                                    'aria-label': 'select all devices', id: 'select-all-items',
                                }}
                            />
                        }
                    </StyledTableCell>
                    {headCells.map((headCell: any) => (
                        <StyledTableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                hideSortIcon={!headCell.sortable}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={headCell.sortable ? createSortHandler(headCell.id) : undefined}
                                aria-label={headCell.label}
                                data-testid="test-sort"

                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
        )
    }
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: "#F0F0F0",
        fontWeight: "700",
        fontSize: 12,
        border: "2px solid #202020",
        padding: "5px 16px",
        fontFamily: "Honeywell Sans Web",
        lineHeight: '20px'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: "#d0d0d0",
        paddingLeft: "17px",
        borderBottom: "1px solid #202020",
        borderRight: 0,
        fontFamily: "Honeywell Sans Web",
        whiteSpace: "nowrap"
    },
}))

export interface Sort {
    order: Order
    orderBy: string
}

function DeviceList(props: AllProps) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [selectedRow, setSelectedRow] = React.useState<any>(null)
    const [showStateChangeModal, setStateChangeModal] = React.useState<boolean>(false)
    const assetStateTypeData = useSelector((state: RootState) => state.AssetStatesTypeData?.AssetStatesTypeData)
    const [reqError, setReqError] = React.useState<boolean>(false)
    const [reqDateError, setReqDateError] = React.useState<boolean>(false)
    const [AssetStateTypeOptions, setAssetStateTypeOptions] = React.useState<Option[]>([])
    const auth = useAuth()
    const [selected, setSelectedAsset] = React.useState<readonly string[]>([])
    const [selectall, setSelectedAll] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const [showMenu, setShowMenu] = React.useState(false)
    const [showStateUpdatePopup, setShowStateUpdatePopup] = React.useState<boolean>(false)
    const [previousState, SetPreviousState]  = React.useState('')
    const defaultDateAsset = dayjs(new Date())
    const [installationStateDate, setInstallationStateDate] = React.useState<Dayjs>(defaultDateAsset)
    const [showAlertPopup, setShowAlertPopup] = React.useState<boolean>(false)
    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef)

    // istanbul ignore next
    function useOutsideAlerter (ref: any) {
        React.useEffect(() => {
          function handleClickOutside (event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                setAnchorEl(null)
                setSelectedRow(null)
            }
          }
          document.addEventListener('mousedown', handleClickOutside)
          return () => {
            document.removeEventListener('mousedown', handleClickOutside)
          }
        }, [ref])
      }

    let active_id: string;

    const initialState = {
        assetStateType: '',
        assetIds: [],
        transitionDate: installationStateDate,
        confirmedUpdate: false
    }

    const [assetStateData, setAssetStateData] = React.useState<assetStateReq>(initialState)

    React.useEffect(() => {
        // istanbul ignore else
        if (props.searchVal === '') {
            fetchAllAssetList()
        } else if (props.searchVal !== '') {
            props.performAction()
        }
    }, [props.page, props.sort])

    React.useEffect(() => {
        props.getAssetStateData(auth.user?.profile.customer as string)
        props.resetUpdateStateParams()
    }, [])

    const fetchAllAssetList = () => {
        // istanbul ignore else
        if (props.page >= 1) {
            props.fetchDeviceList(props.page - 1, props.rowsPerPage, props.sort.order, props.sort.orderBy, auth.user?.profile.customer as string)
        }
    }

    // istanbul ignore next
    const closeStateChangeModal = () => {
        setStateChangeModal(false)
        setReqError(false)
        setReqDateError(false)
        setInstallationStateDate(defaultDateAsset)
        setAssetStateData(initialState)
        // props.fetchDeviceList(props.page - 1, props.rowsPerPage, props.sort.order, props.sort.orderBy, auth.user?.profile.customer as string)
        setSelectedAsset([])
    }

    const handleRequestSort =
        (event: React.MouseEvent<unknown>, newOrderBy: keyof Data) => {
            const isAsc = props.sort.orderBy === newOrderBy && props.sort.order === 'asc'
            const toggledOrder = isAsc ? 'desc' : 'asc'
            props.setSort({ order: toggledOrder, orderBy: newOrderBy })
        }

    // Enable this to include elipses
    // const handleEllipsisClick = (event: any, row: string): void => {
    //     setAnchorEl(event.currentTarget)
    //     setSelectedRow(row)
    //     setAssetStateData({ ...assetStateData, 'assetIds': [row] })
    // }

    React.useEffect(() => {
        if (assetStateTypeData !== null && assetStateTypeData?.length > 0) {
            const assetStateTypes: Option[] = []
            assetStateTypeData?.forEach((assetState: any) => {
                assetStateTypes.push({
                    value: assetState.asset_state_type_id,
                    label: assetState.asset_state_type_name
                })
                if(assetState.asset_state_type_name === 'Active'){
                    active_id = assetState.asset_state_type_id
                }
            })
            setAssetStateTypeOptions(assetStateTypes)
        }
    }, [assetStateTypeData])

    const handleChooseState = (name: string, value: string) => {
        let label = name + 'Options';
        let AssetStateTypeIdName = assetStateTypeData.find((a: { asset_state_type_id: string }) => a.asset_state_type_id == value).asset_state_type_name
        if (AssetStateTypeIdName == 'Active') {
            dateLabel = 'Activation Date'
        } else if (AssetStateTypeIdName == 'Inactive') {
            dateLabel = 'Inactivation Date'
        } else if (AssetStateTypeIdName == 'Shipped') {
            dateLabel = 'Shipping Date'
        } else if (AssetStateTypeIdName == 'Removed') {
            dateLabel = 'Removal Date'
        }
        setAssetStateData({ ...assetStateData, 'assetStateType': value })
    }

    // istanbul ignore next
    const handleInstallationDate = (val: dayjs.Dayjs) => {
        // change time to utc
        console.log(val.utc().hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ssZ[Z]'))
        const utcDate = val.utc().hour(0).minute(0).second(0)
        setInstallationStateDate(val);
        setAssetStateData({ ...assetStateData, 'transitionDate': utcDate })
    }

    const handleSubmit = (newState?: assetStateReq) => {
        if (newState !== undefined) {
            props.updateAssetStateInfo({ ...newState, assetStateType: AssetStateTypeOptions.filter(item => item.value === newState.assetStateType)[0].label }, auth.user?.profile.customer as string)
            setStateChangeModal(false)
            setShowStateUpdatePopup(true)
            setAssetStateData({ ...assetStateData, 'assetStateType': '' })
        }
        else if (assetStateData.assetStateType && assetStateData.transitionDate && assetStateData) {
            props.updateAssetStateInfo({ ...assetStateData, assetStateType: AssetStateTypeOptions.filter(item => item.value === assetStateData.assetStateType)[0].label }, auth.user?.profile.customer as string)
            setStateChangeModal(false)
            if (assetStateData.assetStateType == 'Active') {
                dateLabel = 'Activation Date'
            } else if (assetStateData.assetStateType == 'Inactive') {
                dateLabel = 'Inactivation Date'
            } else if (assetStateData.assetStateType == 'Shipped') {
                dateLabel = 'Shipping Date'
            } else if (assetStateData.assetStateType == 'Removed') {
                dateLabel = 'Removal Date'
            }
            setShowStateUpdatePopup(true)
            // props.resetUpdateStateParams()
        } else {
            setReqError(true)
            // props.resetUpdateStateParams()
        }
    }

    const handleStateChangeClick = (rowId: string, modifyOption: string): void => {
        setStateChangeModal(true)
        setSelectedRow(null)
        setAssetStateData({ ...assetStateData, 'assetStateType': '', 'transitionDate': defaultDateAsset })
    }

    // istanbul ignore next
    const handleSingleStateChangeClick = (rowId: string, rowState:string, modifyOption: string): void => {
        SetPreviousState(rowState)
        if(rowState === 'Removed'){
            setAssetStateTypeOptions([{value: active_id, label: 'Active'}])
        }
        setStateChangeModal(true)
        setSelectedRow(null)
    }

    const handleSelectAllClickAsset = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const currentPageAssets = props.deviceList?.deviceList?.map((n: { id: any }) => n.id)
        // istanbul ignore else
        if (event.target.checked) {
            const newSelected: string[] = []
            currentPageAssets?.forEach((id) => {
                // istanbul ignore else
                if (!selected.includes(id)) {
                    newSelected.push(id)
                }
            })
            const newSelectedlist = [...selected, ...newSelected]
            setSelectedAsset(newSelectedlist)
            setAssetStateData({ ...assetStateData, 'assetIds': newSelectedlist })
        } else {
            const newSelected: string[] = []
            selected.forEach((id) => {
                if (!currentPageAssets?.includes(id)) {
                    newSelected.push(id)
                }
            })
            setSelectedAsset(newSelected)
        }
    }

    const handleRowClickAsset = (id: string): void => {
        const isIdSelected = selected.includes(id)
        // istanbul ignore next
        if (!isIdSelected) {
            const arrData = [...selected, id];
            setSelectedAsset(arrData)
            setAssetStateData({ ...assetStateData, 'assetIds': arrData })
            arrData.length == props.deviceList.deviceCount ? setSelectedAll(true) : setSelectedAll(false);
        } else {
            const arrData = selected.filter((selectedId) => selectedId !== id)
            setSelectedAsset(arrData);
            setAssetStateData({ ...assetStateData, 'assetIds': arrData })
            arrData.length == props.deviceList.deviceCount ? setSelectedAll(true) : setSelectedAll(false);
        }

    }

    // istanbul ignore next
    const handleDeviceDetailClick = (assetId: string, assetName: string) => {
        dispatch(changeAssetId(assetId))
        sessionStorage.setItem('paramAssetId', assetId != null ? assetId.toString() : '')
        sessionStorage.setItem('paramAssetName', assetName != null ? assetName.toString() : '')
        navigate(`/devicedetails`)
    }

    // const handleAlert = (): boolean => {
    //     showAlertSuccess ? setShowAlertSuccess(false) : setShowAlertSuccess(true)
    //     return showAlertSuccess
    // }
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "& th": {
            border: "1px solid #202020",
        }
    }))

    const checkIfEditInfoValid = (): boolean => {
        if(reqDateError){
            return true
        }
        const assetType = AssetStateTypeOptions.filter(state => assetStateData.assetStateType === state.value)[0]
        if (assetType !== undefined) return (previousState === assetType.label)
        return false
      }

    const isSelected = (id: string): boolean => selected.includes(id)

    // istanbul ignore else
    if (props.deviceList.deviceList !== null && props.deviceList.deviceList !== undefined && props.deviceList.httpStatus === 200 && props.deviceList.deviceCount > 0) {
        return (
            <>
                <Box className="devices" sx={{ overflowX: 'auto' }}>
                    <Paper sx={{ width: '100%', mb: 2, backgroundColor: "#272727", boxShadow: "unset", color: "#F0F0F0", marginBottom: "0" }}>
                        <TableContainer className='device-table'>
                            <Table
                                sx={{ minWidth: 750, color: "#f0f0f0" }}
                                aria-labelledby="tableTitle"
                                size={'small'}
                            >
                                <EnhancedTableHead
                                    numSelected={selected.filter((id) => props.deviceList.deviceList && props.deviceList.deviceList.map((asset: any) => asset.id).includes(id)).length}
                                    order={props.sort.order}
                                    orderBy={props.sort.orderBy}
                                    onSelectAllClick={handleSelectAllClickAsset}
                                    onRequestSort={handleRequestSort}
                                    rowCount={(props.deviceList.deviceList).length}
                                    deviceList={props.deviceList.deviceList}
                                    deviceListCount={(props.deviceList.deviceList).length}
                                />
                                <TableBody>
                                    {props.deviceList.deviceList && props.deviceList.deviceList.map((row: any, index: any) => {
                                        const isItemSelected = isSelected(row.id)
                                        const labelId = `enhanced-table-checkbox-${index}`
                                        var installDate = row.install_date ? new Date(row.install_date) : null
                                        var formattedInstallDate = installDate ? convertDateIntoAohStandardUsingDayjs(installDate) : ''
                                        var removalDate = row.removal_date ? new Date(row.removal_date) : null
                                        var formattedRemovalDate = removalDate ? convertDateIntoAohStandardUsingDayjs(removalDate) : ''
                                        const state = row.state === "Removed" ? true : false;
                                        return (
                                            <StyledTableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={index}
                                                aria-label="devices-list-row"
                                                selected={isItemSelected}
                                            >
                                                <StyledTableCell padding="checkbox">
                                                    { !state ? (
                                                        <Checkbox
                                                        sx={{color: '#909090'}}
                                                        checked={isItemSelected}
                                                        onChange={() => {
                                                        handleRowClickAsset(row.id)
                                                    }}
                                                        inputProps={{
                                                        'aria-labelledby': labelId,
                                                        'aria-label': 'test-checkbox',
                                                        id: 'select-all-items'
                                                    }}
                                                        />
                                                    ):<BpCheckbox inputProps={{ id: `select-item-${index}` }} disabled/>}

                                                </StyledTableCell>
                                                <StyledTableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                    onClick={() => handleDeviceDetailClick(row.id, row.name)}
                                                    aria-label='device-name'
                                                    data-testid='device-name'
                                                    sx={{ color: "#64C3FF !important", cursor: 'pointer' }} >{row.name}</StyledTableCell>
                                                <StyledTableCell
                                                >{row.serial_number}</StyledTableCell>
                                                <StyledTableCell
                                                >{row.device_type}</StyledTableCell>
                                                <StyledTableCell
                                                >{row.latitude}</StyledTableCell>
                                                <StyledTableCell>{row.longitude}</StyledTableCell>
                                                <StyledTableCell>{formattedInstallDate}</StyledTableCell>
                                                <StyledTableCell>{formattedRemovalDate}</StyledTableCell>
                                                <StyledTableCell>{row.firmware_version}</StyledTableCell>
                                                <StyledTableCell>{row?.tag?.length > 0 ? row.tag[0].name : ''}</StyledTableCell>
                                                <StyledTableCell>{row.state}</StyledTableCell>
                                                <StyledTableCell>
                                                    { !state ? (
                                                        <span style={{
                                                            color: '#64c3ff',
                                                            textDecoration: 'none',
                                                            paddingLeft: '2px',
                                                            cursor: 'pointer'
                                                        }}
                                                              onClick={() => {
                                                                  setSelectedRow(row)
                                                                  setAssetStateData({...assetStateData,
                                                                    'assetIds': [row.id],
                                                                    'assetStateType': (row.state !== 'Shipped') ? AssetStateTypeOptions.filter(state => state.label === row.state)[0].value : assetStateData.assetStateType,
                                                                    'transitionDate': dayjs(new Date((row.state === 'Active' && row.install_date) ? row.install_date : new Date()))
                                                                })
                                                                  handleSingleStateChangeClick(row.id, row.state, 'ChangeState')
                                                                  dateLabel = 'Installation Date'
                                                              }} data-testid="change-state-button">
                                                    {TableStrings.ChangeStateTitle}
                                                    </span>
                                                        )
                                                        : null
                                                    }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {props.deviceList.deviceCount > 10 && (<Pagination count={props.deviceList.deviceCount} page={props.page} setPage={props.setPage} rowsPerPage={props.rowsPerPage} siblingCount={1}
                            boundaryCount={1} />)}
                    </Paper>
                </Box >
                {/* <StateChangeModal
                    open={showStateChangeModal}
                    closeStateChangeModal={closeStateChangeModal}
                    checkIfEditInfoValid={checkIfEditInfoValid}
                    handleInstallationDate={handleInstallationDate}
                    handleSubmit={handleSubmit}
                    assetStateData={assetStateData}
                    defaultDateAsset={defaultDateAsset}
                    reqError={reqError}
                /> */}
                <Modal className='state-update-modal' open={showStateChangeModal} onClose={() => { closeStateChangeModal() }}>
                    <Box sx={successModalStyle}>
                        <div className='title'>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    <Typography id='modal-modal-title' variant='h6' component='h2'>
                                        {TableStrings.ChangeStateTitle}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <CloseIcon style={{ cursor: 'pointer' }} data-testid='close-button' onClick={() => { closeStateChangeModal() }} />
                                </Grid>
                            </Grid>
                        </div>
                        <div className='state-div'>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={12}>
                                    <GenericDropdown dataTestId='category' allowInput={true} width={336} options={AssetStateTypeOptions} value={assetStateData.assetStateType} onChange={(e) => handleChooseState('assetStateType', e.target.value)} label={'State'} />
                                    {reqError && !assetStateData.assetStateType && <FormHelperText className='errorColor'>{'Required'}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12}>
                                    <label style={{ marginBottom: '10px', display: 'block' }}>{dateLabel}*</label>
                                    <CustomLocalizationProviderWrapper>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <WhiteDatePicker
                                                disableFuture
                                                defaultValue={defaultDateAsset}
                                                slotProps={{ textField: { fullWidth: true }, field: { readOnly: true } }}
                                                value={assetStateData.transitionDate}
                                                onAccept={(val: any) => {
                                                    handleInstallationDate(dayjs(val)) }}
                                                onError={(error) => {setReqDateError(true)}}
                                            />
                                        </LocalizationProvider>
                                    </CustomLocalizationProviderWrapper>
                                    {reqDateError && <FormHelperText className='errorColor'>{'Future date not allowed'}</FormHelperText>}
                                </Grid>
                            </Grid>
                        </div>
                        <div className='add-modal-button-container' style={{ float: 'right' }}>
                            <Button data-testid='' className='can-btn-asset'
                                onClick={() => { closeStateChangeModal() }}
                                style={{
                                    border: '1px solid #32617f',
                                    textTransform: 'capitalize',
                                    padding: '5px 27px',
                                    marginRight: '10px'
                                }}>
                                {TableStrings.Cancel}
                            </Button>
                            <GenericButton data-testid='' className='btn-asset'
                                onClick={() => { handleSubmit() }}
                                primaryColor={checkIfEditInfoValid() ? '#B0B0B0' : undefined}
                                disabled={checkIfEditInfoValid()}
                            >
                                {TableStrings.Update}
                            </GenericButton>
                        </div>
                    </Box>
                </Modal >
                {showStateUpdatePopup
                    ? stateUpdateModel(props, setShowStateUpdatePopup, assetStateData, setAssetStateData, handleSubmit, initialState, fetchAllAssetList, setSelectedAsset)
                    : <></>
                }
                {
                    (selected?.length > 1) ?
                        <>
                            <div style={{ marginTop: '40px', padding: '20px' }} className="state-button-container state-update">
                                <GenericButton dataTestId='cancel-btn-id' className='can-btn-asset' type='borderless'
                                    onClick={() => { setSelectedAsset([]) }} >
                                    {TableStrings.Cancel}
                                </GenericButton>
                                <Button data-testid='' className='btn-asset'
                                    onClick={() => {
                                        dateLabel = 'Installation Date'
                                        handleStateChangeClick('selected', 'ChangeState')
                                    }}
                                    style={{
                                        border: '1px solid #32617f',
                                        textTransform: 'capitalize',
                                        padding: '5px 27px',
                                        color: '#000000',
                                        backgroundColor: '#64c3ff',
                                        fontWeight: '700',
                                        float: 'right'
                                    }}>
                                    {TableStrings.ChangeStateTitle}
                                </Button>
                            </div>
                        </> : ''
                }
            </>

        )
    }
    else if (props.deviceList.isLoading) {
        return (
            <div className='CircularprogressAmi' data-testid = 'loading-spinner'>
                <CircularProgress />
            </div>
        )
    }
    else if (props.deviceList.httpStatus === 401) {
        return (
            <div className='authError'><AuthError errorMessage="Unauthorized" /></div>
        )
    }
    else if (props.deviceList.httpStatus === 400) {
        return (
            <div className='authError'><AuthError errorMessage="invalidSearchInput" /></div>
        )
    }
    else if (props.deviceList.httpStatus === 403) {
        return (
            <div className='authError'><AuthError errorMessage="accessForbidden" /></div>
        )
    }
    else if ((props.deviceList.deviceList !== null && props.deviceList.deviceList !== undefined && props.deviceList.deviceList.length === 0)) {
        return (
            <div className='authError'><AuthError errorMessage="NoDataPresent" /></div>
        )
    }
    else if ((props.deviceList.loadingError?.includes('No assets found') || props.deviceList.loadingError?.includes("Asset not found"))) {
        return (
            <div className='authError'><AuthError errorMessage="NoDataPresent" /></div>
        )
    }
    else {
        return (
            <div className='authError'><AuthError errorMessage="cannotFetch" retry={fetchAllAssetList} /></div>
        )
    }

}
interface DispatchToProps {
    fetchDeviceList: (page: number, size: number, sortdir: string, sortfield: string, tenantId: string) => void,
    getAssetStateData: (tenantId: string) => void,
    updateAssetStateInfo: (assetStateParamter: assetStateReq, tenantId: string) => void,
    resetUpdateStateParams: () => (tenantId: string) => void,
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
    fetchDeviceList: (page: number, size: number, sortdir: string, sortfield: string, tenantId: string) => dispatch(fetchDeviceList(page, size, sortdir, sortfield, tenantId)),
    getAssetStateData: (tenantId: string) => dispatch(getAssetStateData(tenantId)),
    updateAssetStateInfo: (assetStateParamter: assetStateReq, tenantId: string) => dispatch(updateAssetStateInfo(assetStateParamter, tenantId)),
    resetUpdateStateParams: () => dispatch(resetUpdateStateParams())
})

interface StateToProps {
    deviceList: DeviceListState,
    assetState: AssetStatesTypeDataState
    updateAssetStateData: UpdateAssetStateInfo
}

const mapStateToProps = (state: RootState): StateToProps => ({
    deviceList: state.deviceList,
    assetState: state.AssetStatesTypeData,
    updateAssetStateData: state.updateAssetStateData
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
interface ParentProps {
    searchVal: string
    performAction: () => void
    rowsPerPage: number
    page: number
    sort: Sort
    setPage: React.Dispatch<React.SetStateAction<number>>
    setSort: React.Dispatch<React.SetStateAction<Sort>>
}
type AllProps = PropsFromRedux & ParentProps

export default connector(DeviceList)