// istanbul ignore file
import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'
import { type AdvancedFilterPostRequest } from '../../asset-group/types'

export interface FirmwareCampaignFilterRequest {
  groupIds: string[]
}

const ZAssetGroup = z.object({
  group_id: z.string(),
  group_name: z.string(),
  status: z.string()
})

const ZFirmwareCampaignDetails = z.object({
  firmwareCampaignId: z.string().nullable(),
  firmwareCampaignName: z.string().nullable(),
  firmwareFileId: z.string().nullable(),
  tenantId: z.string().nullable(),
  assetType: z.string().nullable(),
  firmwareType: z.string().nullable(),
  assetGroup: z.array(ZAssetGroup),
  version: z.string().nullable(),
  status: z.string().nullable(),
  schedulerType: z.string().nullable(),
  scheduledDate: z.string(),
  startedOn: z.string().nullable(),
  createdBy: z.string().nullable(),
  createdAt: z.string()
})

const ZData = z.object({
  totalCount: z.number(),
  campaignDetails: ZFirmwareCampaignDetails.array()
})

const ZFirmwareCampaignApiResponse = z.object({
  error: z.boolean(),
  http_status: z.number(),
  data: z.optional(ZData),
  error_message: z.optional(z.string())
})

const ZFirmwareCampaignApiErrorResponse = z.object({
  error: z.boolean(),
  http_status: z.number(),
  error_message: z.optional(z.string())
})

export type FirmwareCampaignDetails = z.infer<typeof ZFirmwareCampaignDetails>
export type GroupParaObj = z.infer<typeof ZAssetGroup>
export type FirmwareCampaignListDataObj = z.infer<typeof ZData>

export async function getFirmwareCampaignDetails (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  search: string,
  tenantId: string,
  onSuccess: (firmwareCampaignListData: FirmwareCampaignListDataObj, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = `asset-config-service/api/v1/udp/firmwareCampaign/details?tenantId=${tenantId}&sort=${sortfield},${sortdir}&page=${page}&size=${size}`

  interceptedAxios.get(url)
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZFirmwareCampaignApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          if (
            parseResponse.data.data !== null &&
            parseResponse.data.data !== undefined
          ) {
            onSuccess(parseResponse.data.data, response.status)
          } else {
            onFailure('Error occurred while processing response', response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      } else {
        onFailure(response.statusText, response.status)
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        const parseResponse = ZFirmwareCampaignApiErrorResponse.safeParse(error.response.data)
        if (parseResponse.success) {
          onFailure(parseResponse.data.error_message !== undefined ? parseResponse.data.error_message : '', error.response.status)
        } else {
          onFailure('Parsing Error', error.response.status)
        }
      } else {
        onFailure('Network Error', 0)
      }
    })
}

export async function postFirmwareCampaignSearchDetails (
  advancedFilterPostRequest: AdvancedFilterPostRequest,
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  onSuccess: (firmwareCampaignListData: FirmwareCampaignListDataObj, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = `search-service/api/v1/udp/search/firmware-campaign?page=${page}&size=${size}&sort=${sortfield},${sortdir}`

  interceptedAxios.post(url, advancedFilterPostRequest)
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZFirmwareCampaignApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          if (
            parseResponse.data.data !== null &&
          parseResponse.data.data !== undefined
          ) {
            onSuccess(parseResponse.data.data, response.status)
          } else {
            onFailure('Error occurred while processing response', response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      } else {
        onFailure(response.statusText, response.status)
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        const parseResponse = ZFirmwareCampaignApiErrorResponse.safeParse(error.response.data)
        if (parseResponse.success) {
          onFailure(parseResponse.data.error_message !== undefined ? parseResponse.data.error_message : '', error.response.status)
        } else {
          onFailure('Parsing Error', error.response.status)
        }
      } else {
        onFailure('Network Error', 0)
      }
    })
}

export async function postFirmwareCampaignFilterDetails (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  search: string,
  tenantId: string,
  filterParams: FirmwareCampaignFilterRequest,
  onSuccess: (firmwareCampaignListData: FirmwareCampaignListDataObj, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = `/asset-config-service/api/v1/udp/firmwareCampaign/filter?tenantId=${tenantId}&sort=${sortfield},${sortdir}&page=${page}&size=${size}`

  interceptedAxios.post(url, filterParams)
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZFirmwareCampaignApiResponse.safeParse(response.data)

        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
        if (parseResponse.success) {
          if (parseResponse.data.data !== undefined) {
            onSuccess(parseResponse.data.data, parseResponse.data.http_status)
          } else {
            onFailure('Error occurred while processing response', response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      } else {
        onFailure(response.statusText, response.status)
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        const parseResponse = ZFirmwareCampaignApiErrorResponse.safeParse(error.response.data)
        if (parseResponse.success) {
          onFailure(parseResponse.data.error_message !== undefined ? parseResponse.data.error_message : '', error.response.status)
        } else {
          onFailure('Parsing Error', error.response.status)
        }
      } else {
        onFailure('Network Error', 0)
      }
    })
}
