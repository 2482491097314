import { TopIssuesForPositionErrorAction, TopIssuesForPositionLoadingAction, TopIssuesForPositionUpdateAction } from './TopIssuesForPositionSlice'
import * as MapService from '../services/topIssueListService'
import { type AlarmFeatureCollection, type TopIssuesForPosition } from '../types'
import { TopIssuesGeoJsonErrorAction, TopIssuesGeoJsonLoadingAction, TopIssuesGeoJsonUpdateAction } from './TopIssuesListSlice'

export const getTopIssuesGeoJson = (unPaged: boolean, limit: number) => async (dispatch: any) => {
  dispatch(TopIssuesGeoJsonLoadingAction())
  void MapService.getTopIssuesGeoJson(unPaged, limit, (topIssuesGeoJson: AlarmFeatureCollection, httpStatus: number) => {
    dispatch(TopIssuesGeoJsonUpdateAction({ topIssuesGeoJson, httpStatus }))
  }
  , (errorMessage: string | undefined, httpStatus: number) => {
    dispatch(TopIssuesGeoJsonErrorAction({ error: errorMessage, httpStatus }))
  }
  )
}

export const getTopIssuesForPosition = (unPaged: boolean, latitude: number, longitude: number) => async (dispatch: any) => {
  dispatch(TopIssuesForPositionLoadingAction())
  void MapService.getTopIssuesForPosition(unPaged, latitude, longitude, (topIssuesForPosition: TopIssuesForPosition[], httpStatus: number) => {
    dispatch(TopIssuesForPositionUpdateAction({ topIssuesForPosition, httpStatus }))
  }
  , (errorMessage: string | undefined, httpStatus: number) => {
    dispatch(TopIssuesForPositionErrorAction({ error: errorMessage, httpStatus }))
  }
  )
}
