// istanbul ignore file
import React, { useState, useEffect } from 'react'
import Modal from '../../components/Modal'
import { type TopIssuesForPosition } from './types'
import { nortBadge } from './GeoJsonMap'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { changeAssetId } from '../device-details/redux/assetIdSlice'

interface InfoModelProps {
  markerData: TopIssuesForPosition[]
  handleModalClose: () => void
}

export const InfoModalWithInfiniteLoading: React.FC<InfoModelProps> = ({ markerData, handleModalClose }) => {
  const [displayData, setDisplayData] = useState<TopIssuesForPosition[]>([])
  const [itemsToShow, setItemsToShow] = useState(50)
  const [isFetching, setIsFetching] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleDeviceDetailClick = (
    assetId: string,
    assetName: string
  ): void => {
    handleModalClose()
    dispatch(changeAssetId(assetId))
    sessionStorage.setItem(
      'paramAssetId',
      assetId != null ? assetId.toString() : ''
    )
    sessionStorage.setItem(
      'paramAssetName',
      assetName != null ? assetName.toString() : ''
    )
    navigate('/devicedetails')
  }

  useEffect(() => {
    console.log(markerData.length, itemsToShow)
    setDisplayData(markerData.slice(0, itemsToShow))
    if (itemsToShow >= markerData.length) {
      setIsFetching(false)
    }
  }, [markerData, itemsToShow])

  const loadMoreItems = (): void => {
    if (!isFetching) {
      setIsFetching(true)
      setItemsToShow(prev => prev + 50)
      setIsFetching(false)
    }
  }

  const handleScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    const bottom = e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight
    if (bottom && markerData.length > itemsToShow) {
      loadMoreItems()
    }
  }

  const tdrStyle: React.CSSProperties = {
    padding: '10px 0'
  }

  return (
    <Modal sx={{ paddingBottom: '10px', zIndex: 1000000 }} height='auto' width='50vw' title={`Top Issues (${markerData.length})`} onClose={handleModalClose}>
      <div style={{ maxHeight: '300px', overflowY: 'auto' }} onScroll={handleScroll}>
        <table style={{ width: '100%', padding: '10px 5px 0 0' }}>
          <thead>
            <tr>
              <td style={{ margin: '1em 0 0 0', fontSize: '14px', ...tdrStyle, borderBottom: '1px solid #000', textAlign: 'center', fontWeight: 700 }}>Asset Name</td>
              <td style={{ margin: '1em 0 0 0', fontSize: '14px', ...tdrStyle, borderBottom: '1px solid #000', textAlign: 'center', fontWeight: 700 }}>Alarm Name</td>
              <td style={{ margin: '1em 0 0 0', fontSize: '14px', ...tdrStyle, borderBottom: '1px solid #000', textAlign: 'center', fontWeight: 700 }}>Severity</td>
              <td style={{ margin: '1em 0 0 0', fontSize: '14px', ...tdrStyle, borderBottom: '1px solid #000', textAlign: 'center', fontWeight: 700 }}>Action</td>
            </tr>
          </thead>
          <tbody>
            {
              displayData.length > 0
                ? displayData.map((row, index: number) => (
                    <tr key={index}>
                      <td style={{ margin: '1em 0 0 0', fontSize: '14px', ...tdrStyle, borderBottom: '1px solid #000', textAlign: 'center' }}>{row.assetName}</td>
                      <td style={{ margin: '1em 0 0 0', fontSize: '14px', ...tdrStyle, borderBottom: '1px solid #000', textAlign: 'center' }}>{row.alarmName}</td>
                      <td style={{ margin: '1em 0 0 0', fontSize: '14px', ...tdrStyle, borderBottom: '1px solid #000', textAlign: 'center' }}><img src={nortBadge(row.severity)} /></td>
                      <td onClick={() => { handleDeviceDetailClick(row.assetId, row.assetName) }} style={{ margin: '1em 0 0 0', fontSize: '14px', ...tdrStyle, borderBottom: '1px solid #000', textAlign: 'center', color: '#64c3ff', cursor: 'pointer' }}>View Details</td>
                    </tr>
                ))
                : ''
            }
          </tbody>
        </table>
        {isFetching && <p>Loading more items...</p>}
      </div>
    </Modal>
  )
}
