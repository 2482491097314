import { z } from 'zod'

// Top issues list with geoJson data -> markers lat and lng

const ZAlarmFeatureProperties = z.object({
  alarmCount: z.number()
})

const ZAlarmFeatureCollection = z.object({
  type: z.literal('FeatureCollection'),
  features: z.array(
    z.object({
      type: z.literal('Feature'),
      geometry: z.object({
        type: z.literal('Point'),
        coordinates: z.tuple([z.number(), z.number()])
      }),
      properties: ZAlarmFeatureProperties
    })
  )
})

export const ZApiResponseTopIssuesGeoJson = z.object({
  http_status: z.number(),
  data: ZAlarmFeatureCollection,
  error: z.boolean(),
  error_message: z.optional(z.string())
})

export type AlarmFeatureCollection = z.infer<typeof ZAlarmFeatureCollection>

export interface TopIssuesGeoJsonState {
  geoJson: AlarmFeatureCollection | undefined
  loading: boolean
  error: string | undefined
  httpStatus: number
}

// Top Issues For Position -> marker details for particular position

const ZTopIssuesForPosition = z.object({
  assetId: z.string(),
  alarmId: z.string(),
  assetName: z.string(),
  alarmName: z.string(),
  severity: z.string(),
  lastReadingTime: z.string()
})

export const ZApiResponseTopIssuesForPosition = z.object({
  http_status: z.number(),
  data: z.array(ZTopIssuesForPosition),
  error: z.boolean(),
  error_message: z.optional(z.string())
})

export type TopIssuesForPosition = z.infer<typeof ZTopIssuesForPosition>

export interface TopIssuesForPositionState {
  topIssues: TopIssuesForPosition[]
  loading: boolean
  error: string | undefined
  httpStatus: number
}
