// istanbul ignore file
import interceptedAxios from '../../../utils/interceptedAxios'
import { type AlarmFeatureCollection, type TopIssuesForPosition, ZApiResponseTopIssuesForPosition, ZApiResponseTopIssuesGeoJson } from '../types'

export async function getTopIssuesGeoJson (
  unpaged: boolean,
  limit: number,
  onSuccess: (topIssuesGeoJson: AlarmFeatureCollection, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = `/alarm-service/api/v1/udp/assets/alarms/top-issues-features?unPaged=${unpaged ? 'true' : 'false'}&limit=${limit}`

  interceptedAxios
    .get(url)
    .then(response => {
      const parseResponse = ZApiResponseTopIssuesGeoJson.safeParse(response.data)
      if (parseResponse.success) {
        onSuccess(parseResponse.data.data, response.status)
      } else {
        onFailure(parseResponse.error, response.status)
      }
    })
    .catch((error) => {
      if (error.response?.data !== undefined) {
        onFailure(error.response.data.error_message, error.response.status)
      } else {
        onFailure(
          error.response !== undefined
            ? error.response.statusText
            : 'Network Error',
          error.response !== undefined ? error.response.status : 404
        )
      }
    })
}

export async function getTopIssuesForPosition (
  unpaged: boolean,
  latitude: number,
  longitude: number,
  onSuccess: (topIssues: TopIssuesForPosition[], httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = `/alarm-service/api/v1/udp/assets/alarms/top-issues-by-coordinates?latitude=${latitude}&longitude=${longitude}&unPaged=${unpaged ? 'true' : 'false'}`

  interceptedAxios
    .get(url)
    .then(response => {
      const parseResponse = ZApiResponseTopIssuesForPosition.safeParse(response.data)
      if (parseResponse.success) {
        onSuccess(parseResponse.data.data, response.status)
      } else {
        onFailure(parseResponse.error, response.status)
      }
    })
    .catch((error) => {
      if (error.response?.data !== undefined) {
        onFailure(error.response.data.error_message, error.response.status)
      } else {
        onFailure(
          error.response !== undefined
            ? error.response.statusText
            : 'Network Error',
          error.response !== undefined ? error.response.status : 404
        )
      }
    })
}
