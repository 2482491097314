import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type HistoryKSDataObject } from '../types'

export interface HistoryKSTableState {
  isLoading: boolean
  errorMessage: string | undefined
  data: HistoryKSDataObject | undefined
  httpStatus: number
}

const initialState: HistoryKSTableState = {
  isLoading: true,
  errorMessage: undefined,
  data: undefined,
  httpStatus: 0
}

const historyKSTableSlice = createSlice({
  name: 'KpiHistoryKSTable',
  initialState,
  reducers: {
    historyKSTableLoadingAction: (state) => {
      return {
        isLoading: true,
        errorMessage: undefined,
        data: undefined,
        httpStatus: 0
      }
    },
    historyKSTableUpdateAction: (state, action: PayloadAction<{ data: HistoryKSDataObject, httpStatus: number }>) => {
      return {
        isLoading: false,
        errorMessage: undefined,
        data: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    historyKSTableErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        errorMessage: action.payload.errorMessage,
        data: undefined,
        httpStatus: action.payload.httpStatus
      }
    },
    historyKSTableResetAction: () => {
      return initialState
    }
  }
})

export const {
  historyKSTableLoadingAction,
  historyKSTableUpdateAction,
  historyKSTableErrorAction,
  historyKSTableResetAction
} = historyKSTableSlice.actions

export default historyKSTableSlice.reducer
