import { type parametersData } from '../features/asset-configuration/components/AssetConfiguration'

export function regexChecker (value: string): boolean {
  const regexValidator = /^[.+\-()\w\s]+$/
  const checkResult: RegExpExecArray | null = regexValidator.exec(value)
  if (checkResult != null) {
    return true
  }
  return false
}

export function validateParametersData (parameters: parametersData[]): boolean {
  for (const parameter of parameters) {
    if (parameter.parameterName === '') return false
    else if ((parameter.value === '') && parameter.sampleValue !== null) return false
    else if ((parameter.value !== '' && parameter.parameterName.toUpperCase().includes('IP') && isValidIp(parameter.value))) {
      const ipaddr = parameter.value.split('.')
      for (const section of ipaddr) {
        if (section.length >= 2 && section[0] === '0') return false
      }
    } else if ((parameter.value !== '' && parameter.parameterName.toUpperCase().includes('DLMS'))) return true
    else if ((parameter.value !== '' && parameter.parameterName.toUpperCase().includes('IP') && !isValidIp(parameter.value))) return false
    else if ((parameter.value !== '' && parameter.parameterName.toUpperCase().includes('PORT') && !isValidPort(parameter.value))) return false
    else if ((parameter.value !== '' && !regexChecker(parameter.value))) return false
  }
  return true
}

export function isValidName (value: string): boolean {
  const regexValidator = /^[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)?$/
  return regexValidator.test(value)
}

export function isValidUserName (value: string): boolean {
  // Updated regex to start with a letter followed by letters, digits, underscores, hyphens, and dots.
  const regexValidator = /^[a-zA-Z][a-zA-Z0-9_.-]*$/

  // Check if the username matches the regex and does not contain consecutive special characters
  const noConsecutiveSpecialChars = !/(.*[_.-]{2,}.*|.*--.*|.*\\.\\..*)/.test(value)

  // Check if the username ends with a valid character (not a special character)
  const notEndingWithSpecialChar = !/[_.-]$/.test(value)

  // Return true if all conditions are satisfied
  return regexValidator.test(value) && noConsecutiveSpecialChars && notEndingWithSpecialChar
}
export function isValidVersionCharacter (value: string): boolean {
  const regexValidator1 = /^\d{1,3}$/
  const regexValidator = /^\d{1,3}\.$/
  const regexValidatorTwoDigit1 = /^\d{1,3}\.\d{1,3}$/
  const regexValidatorTwoDigit = /^\d{1,3}\.\d{1,3}\.$/
  const versionRegex = /^\d{1,3}\.\d{1,3}\.\d{1,3}$/
  return (regexValidator.test(value) || versionRegex.test(value) || regexValidatorTwoDigit.test(value) || regexValidator1.test(value) || regexValidatorTwoDigit1.test(value))
}

export function isValidVersion (value: string): boolean {
  if (value === '' || value === null) {
    return true
  }
  const regexValidator = /^\d{1,3}\.\d{1,3}\.\d{1,3}$/
  return regexValidator.test(value)
}

export function isValidIp (value: string): boolean {
  const ipAddressRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
  return ipAddressRegex.test(value)
}

export function isValidPort (port: string): boolean {
  if (!/^\d+$/.test(port)) {
    return false
  }

  const portNumber = parseInt(port, 10)
  return !isNaN(portNumber) && portNumber >= 1 && portNumber <= 65535
}

// this method is not too efficient for longer strings. use isValidDesc for such cases
export function isValidText (value: string): boolean {
  const regexValidator = /^[a-zA-Z0-9]+(\s?[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+(\s?[a-zA-Z0-9]+)?)?$/
  return regexValidator.test(value.trim())
}

export function isValidDesc (value: string): boolean {
  const regexValidator = /^[a-zA-Z0-9\s.]*$/
  return regexValidator.test(value.trim())
}

export function isValidGName (value: string): boolean {
  const regexValidator = /^[a-zA-Z0-9\s.]*$/
  return regexValidator.test(value.trim())
}

export function isValidGroupNameText (value: string): boolean {
  const regexValidator = /^[a-zA-Z0-9]+(\s*[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+(\s*[a-zA-Z0-9]+)?)?$/
  return regexValidator.test(value.trim())
}

export function isValidNameEdit (value: string): boolean {
  const regexValidator = /^[a-zA-Z0-9 ]+$/ // Allow alphabets, numbers, and spaces
  return regexValidator.test(value)
}

export function isValidGroupName (value: string): boolean {
  const regexValidator = /^[a-zA-Z0-9_ ]+$/ // Allow alphabets, numbers, and underscores
  return regexValidator.test(value)
}

// istanbul ignore next
export function isValidFolderPath (value: string): boolean {
  // eslint-disable-next-line no-useless-escape
  const sftpPathRegex = /^.+\/$/ // '/home/user/file.txt'
  return sftpPathRegex.test(value)
}

export const validateSeedValue = (value: string): boolean => {
  return value.length > 0 && value.length <= 32 && /^[a-zA-Z0-9]+$/.test(value)
}

// istanbul ignore next
export function isValidGenericName (name: string): boolean {
  if (!/^[a-zA-Z0-9\s]{1,550}$/.test(name)) {
    return false
  }
  return true
}

// istanbul ignore next
export function isValidTimeBwZeroToSeven (time: string): boolean {
  if (!/^[0-7]$/.test(time)) {
    return false
  }
  return true
}

// istanbul ignore next
export function isValidAlphanumericName10Char (name: string): boolean {
  // This regular expression checks for exactly 10 alphanumeric characters
  const regex = /^[a-zA-Z0-9]{1,10}$/

  return regex.test(name)
}

// istanbul ignore next
export function isValidAlphanumericName40Char (name: string): boolean {
  // This regular expression checks for exactly 10 alphanumeric characters
  const regex = /^[a-zA-Z0-9]{1,40}$/

  return regex.test(name)
}

// istanbul ignore next
export function isValidAlphanumericName20Char (name: string): boolean {
  // This regular expression checks for exactly 10 alphanumeric characters
  const regex = /^[a-zA-Z0-9]{1,20}$/

  return regex.test(name)
}

// istanbul ignore next
export function isValidNumericName10Char (name: string): boolean {
  // This regular expression checks for only numeric characters and length less than or equal to 10
  const regex = /^[0-9]{1,10}$/

  return regex.test(name)
}

// istanbul ignore next
export function isValidDomainName30Char (name: string): boolean {
  // This regular expression checks for a combination of alphanumeric characters and dots
  // with a maximum length of 30 characters
  const regex = /^[a-zA-Z0-9.]{1,30}$/

  // Check the length of the name and the regex match
  return name.length <= 30 && regex.test(name)
}
