import interceptedAxios from '../../../utils/interceptedAxios'
import { z } from 'zod'

const ZChannelDropdownData = z.object({
  id: z.string(),
  name: z.string(),
  unit: z.string(),
  mainTitle: z.string(),
  verticalTitle: z.string(),
  toolTip: z.array(z.string())
})

const ZmainData = z.object({
  assetId: z.string(),
  total_count: z.number(),
  channels: z.array(ZChannelDropdownData)
})

const ZData = z.object({
  error: z.boolean(),
  http_status: z.number(),
  data: ZmainData
})

export type ChannelDropdownData = z.infer<typeof ZChannelDropdownData>
export type Data = z.infer<typeof ZData>

export type MainData = z.infer<typeof ZmainData>

export async function getChannelDropdownData (
  assetId: string,
  tenantId: string,
  onSuccess: (data: ChannelDropdownData[], httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/asset-data-service/api/v1/udp/assets/data/channel/tooltip?assetId=${assetId}&tenantId=${tenantId}`

  interceptedAxios.get(url)
    .then((response) => {
      // istanbul ignore else
      if (response.status === 200) {
        const parseResponse = ZData.safeParse(response.data)
        // istanbul ignore else
        if (parseResponse.success) {
          const parsedData: ChannelDropdownData[] = parseResponse.data.data.channels
          onSuccess(parsedData, parseResponse.data.http_status)
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      onFailure(error.message, error.response)
    })
}
