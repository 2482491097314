import React from 'react'
import ReactApexChart, { type Props } from 'react-apexcharts'
import { kpiColorMapDonut } from './BuildKpiData'
import { type KpiKey, type KpiKeyDonut } from '../../redux/changeKpiType'

export interface DonutSeriesData {
  name: string[]
  data: number[]
  color: string[]
}

export function getReactApexPieChart (kpiData: DonutSeriesData, height: string, setSelectedDonut?: React.Dispatch<React.SetStateAction<number>>, setBarColor?: React.Dispatch<React.SetStateAction<string>>, selectedKpi?: KpiKey): JSX.Element {
  // calculate the center average
  const chartOptions: Props = {
    series: kpiData.data.map(value => Number(value.toFixed(2))),
    options: {
      grid: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }
      },
      chart: {
        type: 'donut',
        width: '10px',
        events: {
          dataPointSelection: function (event, chartContext, config) {
            if (setSelectedDonut !== undefined) {
              setSelectedDonut(config.dataPointIndex)
            }
            if (setBarColor !== undefined && selectedKpi !== undefined) {
              setBarColor(kpiColorMapDonut[selectedKpi as KpiKeyDonut][config.dataPointIndex])
            }
          }
        }
      },
      stroke: {
        colors: ['#00000000']
      },
      plotOptions: {
        pie: {
          donut: {
            size: '75%'
          }
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              show: false
            }
          }
        }
      ],
      colors: kpiData.color,
      dataLabels: {
        enabled: false
      },
      fill: {
        colors: kpiData.color
      },
      legend: {
        show: false,
        position: 'right'
      },
      tooltip: {
        enabled: true,
        fillSeriesColor: true,
        custom: function (options) {
          return `<span style="background-color: #f0f0f0; color: #272727; padding: 5px">${kpiData.name[options.seriesIndex]} - ${kpiData.data[options.seriesIndex].toFixed(2)}%</span>`
        }
      }
    }
  }
  return (
    <ReactApexChart
      options={chartOptions.options}
      series={chartOptions.series}
      type='donut' height={height} width={height}
    />
  )
}
