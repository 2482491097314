import type * as GeoJSON from 'geojson'

export interface AlarmFeatureProperties {
  alarmCount: number
}

export type AlarmFeature = GeoJSON.Feature<GeoJSON.Point, AlarmFeatureProperties>

export interface AlarmFeatureCollection extends GeoJSON.FeatureCollection<GeoJSON.Point, AlarmFeatureProperties> {
  features: AlarmFeature[]
}

export const getClusterColor = (count: number, total: number): string => {
  const percent = count / total
  if (percent > 0.3) {
    return '#FF644C'
  } else if (percent > 0.1) {
    return '#FF9847'
  }
  return '#64C3FF'
}
