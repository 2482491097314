/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Box, Checkbox, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, styled, tableCellClasses } from '@mui/material'
import Pagination from '../../../../components/TablePagination'
import React, { useEffect } from 'react'
import { visuallyHidden } from '@mui/utils'
import { useAuth } from 'react-oidc-context'
import { type UpdateGroupRequest, type Asset, type GroupsAssetObj, type AdvancedFilterPostRequest, type ConjuctionOptions } from '../../types'
import LanguageStrings from '../../../../i18n/locales'
import SearchBox from '../../../../components/SearchBox'
import { type AssetAssignedListState } from '../../redux/AssetAssignedListSlice'
import { type RootState } from '../../../../store'
import AuthError from '../../../../components/ErrorComponents/AuthError'
import { type ConnectedProps, connect } from 'react-redux'
import { advancedAssignedAssetListFilter, fetchAssetAssignedList, updateGroup } from '../../redux/actionCreator'
import { Button } from '../../../../components/Button'
import Modal from '../../../../components/Modal'
import { type UpdateGroupState } from '../../redux/updateGroupSlice'
import { getTemplateModal } from '../createGroupComponents/utils/getTemplateModal'
import dayjs from 'dayjs'
import AdvancedFilter from '../../../../components/AdvancedFilterComponents/AdvancedFilter'
import Clearfilter from '../../../../assets/Clearfilter.svg'

const AssetAssignedStrings = LanguageStrings().AssetGroupStrings.GroupDetailsComponent.AssignedAssetList

type Order = 'asc' | 'desc'

interface Sort {
  order: Order
  orderBy: string
}

const style = {
  width: '99%',
  bgcolor: '#313030',
  border: '1px solid #101010',
  borderRadius: '10px',
  boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
  marginBottom: '16px',
  padding: 0
}
interface EnhancedTableProps {
  numSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  rowCount: number
  dataList: GroupsAssetObj[]
  order: Order
  orderBy: string
  onRequestSort: (event: React.MouseEvent<unknown>, newOrderBy: keyof Asset) => void
}

function EnhancedTableHead (props: EnhancedTableProps): any {
  const { onSelectAllClick, order, orderBy, onRequestSort, numSelected, rowCount, dataList } = props
  const createSortHandler =
        (newOrderBy: keyof Asset) => (event: React.MouseEvent<unknown>) => {
          onRequestSort(event, newOrderBy)
        }
  return (dataList !== null && dataList !== undefined && <>
      <TableHead data-testid='table-header'>
        <TableRow>
          <StyledTableCell padding="checkbox">
            <Checkbox
                color='primary'
                sx={{ color: '#909090' }}
                data-testid="device-table-header-checkbox"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'Select all Assets',
                  id: 'select-all-items'
                }}
            />
          </StyledTableCell>
          {headCells.map((headCell: HeadCell) => (
              <StyledTableCell
                  key={headCell.id}
                  align='left'
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                    hideSortIcon={!headCell.sortable}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={headCell.sortable ? createSortHandler(headCell.id) : undefined}
                    aria-label={headCell.label}
                    data-testid="test-sort"
                >
                    {headCell.label}
                    {orderBy === headCell.id
                      ? (
                        <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                        )
                      : null}
                </TableSortLabel>
              </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    </>)
}

interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
  sortable: boolean
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 16px',
    fontFamily: 'Honeywell Sans Web',
    lineHeight: '20px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web',
    whiteSpace: 'nowrap'
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& th': {
    border: '1px solid #202020'
  }
}))

const headCells: readonly HeadCell[] = [
  {
    id: 'assetId',
    numeric: false,
    disablePadding: false,
    label: AssetAssignedStrings.AssetId,
    sortable: true
  },
  {
    id: 'serialNumber',
    numeric: false,
    disablePadding: false,
    label: AssetAssignedStrings.SerialNumber,
    sortable: true
  },
  {
    id: 'assetType',
    numeric: false,
    disablePadding: false,
    label: AssetAssignedStrings.AssetType,
    sortable: false
  },
  {
    id: 'assetConfiguration',
    numeric: false,
    disablePadding: false,
    label: AssetAssignedStrings.AssetConfiguration,
    sortable: false
  },
  {
    id: 'state',
    numeric: false,
    disablePadding: false,
    label: AssetAssignedStrings.State,
    sortable: false
  },
  {
    id: 'firmware_version',
    numeric: false,
    disablePadding: false,
    label: 'Firmware Version',
    sortable: false
  },
  {
    id: 'tag',
    numeric: false,
    disablePadding: false,
    label: AssetAssignedStrings.Tag,
    sortable: false
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: AssetAssignedStrings.Action,
    sortable: false
  }
]

export interface AdvFilterParameterConditions {
  parameter: string
  operator: string
  value: string | string[]
  dataType: string
}

const AssignedAssetListTable = (props: AllProps): JSX.Element => {
  const [assetAssignedList, setAssetAssignedList] = React.useState<GroupsAssetObj[]>([])
  const [selected, setSelected] = React.useState<string[]>([])
  const [assetId, setAssetId] = React.useState<string | undefined>(undefined)
  const [page, setPage] = React.useState(1)
  const [searchInput, setSearchInput] = React.useState<string>('')
  const [sort, setSort] = React.useState<Sort>({ order: 'asc', orderBy: 'id' })
  const [unassignPopup, setUnassignPopup] = React.useState(false)
  const [showConfirmation, setShowConfirmation] = React.useState(false)
  const rowsPerPage = 10
  const auth = useAuth()
  const [showTemplates, setShowTemplates] = React.useState<string[]>([])
  // Advance Filter states
  const [showAdvancedFilter, setShowAdvancedFilter] = React.useState(false)
  const [conditions, setConditions] = React.useState<AdvFilterParameterConditions[]>([])
  const [conjuction, setConjuction] = React.useState<ConjuctionOptions>('AND')
  const [triggerAdvFilter, setTriggerAdvFilter] = React.useState(false)

  const resetCurrentComponent = (): void => {
    setSelected([])
    setAssetId(undefined)
    setPage(1)
    setSearchInput('')
    setSort({ order: 'asc', orderBy: 'id' })
    setUnassignPopup(false)
    setShowConfirmation(false)
    setShowTemplates([])
  }

  // istanbul ignore next
  const resetFilters = (): void => {
    setConditions([])
    setTriggerAdvFilter(prev => !prev)
  }

  // istanbul ignore next
  const openAdvancedFilter = (): void => {
    setShowAdvancedFilter(true) // Show the modal
  }

  const handleModifyGroup = (): void => {
    let groupId = sessionStorage.getItem('selectedGroupId')
    // istanbul ignore next
    if (groupId === null) {
      groupId = ''
    }
    let desc = sessionStorage.getItem('currGroupDesc')
    // istanbul ignore next
    if (desc === null) {
      desc = ''
    }
    if (assetId !== undefined) {
      props.updateGroup(groupId, {
        description: desc,
        assign_assets: [],
        un_assign_assets: [assetId]
      })
    } else {
      props.updateGroup(groupId, {
        description: desc,
        assign_assets: [],
        un_assign_assets: selected
      })
    }
  }

  const handleUnassignAssetWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (props.updateGroupDetailsState.httpStatus === 201) {
      return (
        <Modal buttonTypeNegative='hollow' title='Successfully Unassigned' LabelPositive='Ok'
          onClickPositive={() => {
            resetCurrentComponent()
          }}
          onClose={() => {
            resetCurrentComponent()
          }}>
          <p style={{ paddingTop: '1.2em' }}>{(assetId !== undefined || selected.length === 1 || selected.length === 0) ? 'Asset has been unassigned from the group successfully.' : `${selected.length} Assets has been unassigned from the group successfully.`}</p>
        </Modal>
      )
    } else if (props.updateGroupDetailsState.isLoading) {
      return (
        <Modal title='Modifying Group Details'>
          <div className='CircularprogressAmi'>
              <CircularProgress />
          </div>
        </Modal>
      )
    } else if (props.updateGroupDetailsState.httpStatus === 401) {
      return (
                <Modal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
                    onClickNegative={() => { setUnassignPopup(false) }}
                    onClose={() => { setUnassignPopup(false) }} title='Modifying Group Details'>
                    <div className='authError'>
                        <AuthError className={'errorCompInsideModal'} errorMessage="Unauthorized"/>
                    </div>
                </Modal>
      )
    } else if (props.updateGroupDetailsState.httpStatus === 403) {
      return (
                <Modal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
                    onClickNegative={() => { setUnassignPopup(false) }}
                    onClose={() => { setUnassignPopup(false) }} title='Modifying Group Details'>
                    <div className='authError'>
                        <AuthError className={'errorCompInsideModal'} errorMessage="accessForbidden"/>
                    </div>
                </Modal>
      )
    } else {
      return (
        <Modal width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close' LabelPositive='Retry'
          onClickNegative={() => { setUnassignPopup(false) }}
          onClose={() => { setUnassignPopup(false) }} title='Modifying Group Details'
          onClickPositive={handleModifyGroup}>
          <div className='authError'>
            <AuthError className={'errorCompInsideModal'} customError={props.updateGroupDetailsState.error}/></div>
        </Modal>
      )
    }
  }

  // istanbul ignore next
  function getShortenedName (name: string): JSX.Element {
    if (name.length > 15) {
      return <abbr style={{ textDecoration: 'none' }} title={name}>{name.slice(0, 15)}...</abbr>
    }
    return <>{name}</>
  }

  /* istanbul ignore next */
  const handleRowClick = (id: string): void => {
    const isIdSelected = selected.includes(id)
    if (!isIdSelected) {
      setSelected([...selected, id])
    } else {
      setSelected(selected.filter((selectedId) => selectedId !== id))
    }
  }

  // istanbul ignore next
  const handleAdvanceFilter = (page: number, groupId: string): void => {
    setSearchInput('')
    const advancedFilterPostRequest: AdvancedFilterPostRequest = {
      conditions: conditions.map(cond => {
        let rowVal: string | string[] = cond.value
        if (cond.operator === 'Between') {
          if (cond.dataType === 'time') {
            rowVal = [dayjs(cond.value[0]).format('HH:mm:ss'), dayjs(cond.value[1]).format('HH:mm:ss')]
          } else {
            rowVal = [cond.value[0], cond.value[1]]
          }
        } else {
          if (cond.dataType === 'time') {
            rowVal = dayjs(cond.value[0]).format('HH:mm:ss')
          } else {
            if (typeof cond.value === 'object') {
              rowVal = cond.value[0]
            } else {
              rowVal = cond.value
            }
          }
        }
        return {
          key: cond.parameter,
          operation: cond.operator,
          value: rowVal
        }
      }),
      conjuction
    }

    props.advancedFilter(advancedFilterPostRequest, page - 1, rowsPerPage, groupId, sort.order, sort.orderBy, 'ASSET_LIST_GROUP_DETAILS_PAGE', 'asset')
  }

  // istanbul ignore next
  const handleFetchAssetAssignedList = (): void => {
    let groupId = sessionStorage.getItem('selectedGroupId')
    if (groupId === null) {
      groupId = ''
    }
    if (conditions.length > 0) {
      handleAdvanceFilter(page, groupId)
    } else {
      page >= 1 && props.fetchAssetAssignedList(page - 1, rowsPerPage, sort.order, sort.orderBy, auth.user?.profile.customer as string, groupId, searchInput)
    }
  }

  useEffect(() => {
    handleFetchAssetAssignedList()
  }, [page, sort, props.reset, triggerAdvFilter])

  /* istanbul ignore next */
  const handleRequestSort =
        (event: React.MouseEvent<unknown>, newOrderBy: keyof Asset): void => {
          const isAsc = sort.orderBy === newOrderBy && sort.order === 'asc'
          const toggledOrder = isAsc ? 'desc' : 'asc'
          setSort({ order: toggledOrder, orderBy: newOrderBy })
        }

  useEffect(() => {
    if (props.assetAssignedListState.assetAssignedList !== null && props.assetAssignedListState.httpStatus === 200) {
      setAssetAssignedList(props.assetAssignedListState.assetAssignedList.group_details)
    }
  }, [props.assetAssignedListState.assetAssignedList])

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const currentPageAssets = assetAssignedList.map((asset: GroupsAssetObj) => asset.asset_id)
    // istanbul ignore else
    if (event.target.checked) {
      const newSelected: string[] = []
      currentPageAssets.forEach((id) => {
        // istanbul ignore else
        if (!selected.includes(id)) {
          newSelected.push(id)
        }
      })
      setSelected([...selected, ...newSelected])
    } else {
      const newSelected: string[] = []
      selected.forEach((id) => {
        if (!currentPageAssets.includes(id)) {
          newSelected.push(id)
        }
      })
      setSelected(newSelected)
    }
  }

  const isSelected = (id: string): boolean => selected.includes(id)

  const getAssetTableWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (props.assetAssignedListState.assetAssignedList !== null && props.assetAssignedListState.assetAssignedList.group_details.length > 0 && props.assetAssignedListState.httpStatus === 200) {
      return (
        <>
          <Box sx={style} className="devices">
            <Paper
              sx={{
                width: '100%',
                mb: 2,
                backgroundColor: '#272727',
                boxShadow: 'unset',
                color: '#F0F0F0',
                marginBottom: '0'
              }}
            >
              <TableContainer className="device-table group-table">
                <Table
                  sx={{ minWidth: 750, color: '#f0f0f0' }}
                  aria-labelledby="tableTitle"
                  size={'small'}
                >
                  <EnhancedTableHead
                    numSelected={selected.filter((id) => assetAssignedList.map((asset: GroupsAssetObj) => asset.asset_id).includes(id)).length}
                    order={sort.order}
                    orderBy={sort.orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={assetAssignedList.length}
                    dataList={assetAssignedList}
                  />
                  <TableBody>
                    {assetAssignedList.length > 0 &&
                      assetAssignedList.map((row: GroupsAssetObj, index: number) => {
                        const isItemSelected = isSelected(row.asset_id)
                        const labelId = `enhanced-table-checkbox-${row.asset_id}`
                        return (
                          <StyledTableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            aria-label="groups-list-row"
                            selected={isItemSelected}
                          >
                            <StyledTableCell padding="checkbox">
                                <Checkbox
                                    sx={{ color: '#909090' }}
                                    checked={isItemSelected}
                                    onChange={() => { handleRowClick(row.asset_id) }}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                      'aria-label': 'test-checkbox',
                                      id: `select-item-${index}`
                                    }}
                                />
                            </StyledTableCell>
                            <StyledTableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              aria-label="group-name"
                              data-testid="group-name"
                              sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '25vw'
                              }}
                            >
                              {row.asset_id}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.serial_number}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.asset_type}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.template_name === null || row.template_name.length === 0
                                ? ''
                                : row.template_name.length === 1
                                  ? row.template_name[0]
                                  : <span onClick={() => { setShowTemplates(row.template_name === null ? [] : row.template_name) }} style={{ color: '#64C3FF' }}>{row.template_name[0]}+{row.template_name.length - 1}</span>
                              }
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.state}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.firmware_version}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.tag.length > 0 ? getShortenedName(row.tag[0]) : ''}
                            </StyledTableCell>
                            <StyledTableCell>
                              <span onClick={() => {
                                setAssetId(row.asset_id)
                                setShowConfirmation(true)
                              }} style={{ color: '#64c3ff', cursor: 'pointer' }}>{AssetAssignedStrings.Unassign}</span>
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {props.assetAssignedListState.assetAssignedList.total_count > 10 && (<Pagination count={props.assetAssignedListState.assetAssignedList.total_count} page={page} setPage={setPage} rowsPerPage={rowsPerPage} siblingCount={1}
                          boundaryCount={1} />)}
            </Paper>
            {
              selected.length === 0
                ? <></>
                : <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#272727', borderTop: '2px solid #202020' }}>
                <Button type='borderless' dataTestId='cancel-btn' onClick={() => {
                  setSelected([])
                }}>Cancel</Button>
                <Button dataTestId='delete-btn' onClick={() => {
                  setShowConfirmation(true)
                }}>{AssetAssignedStrings.Unassign}</Button>
              </div>
            }
          </Box>
          {
          showTemplates.length > 0
            ? getTemplateModal(showTemplates, () => { setShowTemplates([]) }, 'Configurations')
            : <></>
          }
        </>
      )
    } else if (props.assetAssignedListState.isLoading) {
      return (
        <div className='CircularprogressAmi'>
          <CircularProgress />
        </div>
      )
    } else if (props.assetAssignedListState.httpStatus === 401) {
      return (
              <div className='authError'><AuthError errorMessage="Unauthorized"/></div>
      )
    } else if (props.assetAssignedListState.httpStatus === 403) {
      return (
          <div className='authError'><AuthError errorMessage="accessForbidden"/></div>
      )
    } else if (((props.assetAssignedListState.assetAssignedList === null || props.assetAssignedListState.assetAssignedList.group_details.length === 0) && props.assetAssignedListState.httpStatus === 200) || (props.assetAssignedListState.httpStatus === 404 && props.assetAssignedListState.error === 'No Assets Found')) {
      return (
          <div className='authError'><AuthError errorMessage="NoDataPresent"/></div>
      )
    } else if (props.assetAssignedListState.httpStatus === 404) {
      return (
        <div className='authError'><AuthError errorMessage="NoDataPresent"/></div>
      )
    } else if (props.assetAssignedListState.httpStatus === 400) {
      return <div className="authError"><AuthError errorMessage="invalidSearchInput" /></div>
    } else {
      return (
          <div className='authError'><AuthError errorMessage="cannotFetch" retry={handleFetchAssetAssignedList}/></div>
      )
    }
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span style={{ display: 'flex' }}>
        <Button
          type='hollow'
          dataTestId='advanced-filter-btn'
          width='200px'
          height='35px'
          onClick={openAdvancedFilter}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginLeft: '13px' }}>{AssetAssignedStrings.AdvancedFilter} ({conditions.length})</div>
          <span onClick={(e) => {
            e.stopPropagation()
            resetFilters()
          }}>
            <img src={Clearfilter} alt='clear filter' style={{ marginLeft: '25px', width: '18px', paddingTop: '5px' }} />
            </span>
          </div>
        </Button>
          {conditions.length === 0
            ? <SearchBox sx={{ width: '420px' }}
            placeholder='Search using Serial Number, State, Tag, Firmware'
            immediate={true}
            debounce={true}
            emptyEnterFallback={() => {
              if (conditions.length === 0) {
                handleFetchAssetAssignedList()
              }
            }}
            searchVal={searchInput} setSearchVal={setSearchInput}
            performAction={handleFetchAssetAssignedList}
          />
            : <></>}
        </span>
        <span>
          {
            props.isEditEnabled
              ? <Button dataTestId='add-asset-btn' onClick={() => { props.setShowAddAssetWindow(true) }}>{AssetAssignedStrings.AddAsset}</Button>
              : <></>
          }
        </span>
      </div>
      {getAssetTableWithFailure()}
      {
        unassignPopup
          ? handleUnassignAssetWithFailure()
          : <></>
      }
      { showConfirmation &&
        <Modal buttonTypeNegative='hollow' title='Unassign Asset' LabelNegative='Cancel' LabelPositive='Unassign' onClickPositive={() => {
          handleModifyGroup()
          setShowConfirmation(false)
          setUnassignPopup(true)
        }} onClose={() => {
          setAssetId(undefined)
          setShowConfirmation(false)
        }} onClickNegative={() => {
          setAssetId(undefined)
          setShowConfirmation(false)
        }}>
          <p style={{ paddingTop: '1.2em' }}>{(assetId !== undefined || selected.length === 1) ? 'Do you want to unassign an asset from the group?' : `Do you want to unassign ${selected.length} assets from the group ?`}</p>
        </Modal>
      }
      { showAdvancedFilter && <AdvancedFilter
        conditions={conditions}
        setConditions={setConditions}
        conjuction={conjuction}
        setConjuction={setConjuction}
        setPageSize={setPage}
        setShowAdvancedFilter= {setShowAdvancedFilter}
        triggerAdvFilter={triggerAdvFilter}
        setTriggerAdvFilter={setTriggerAdvFilter}
        searchKey='asset'
        disableOverflow={true}
      />}
    </>
  )
}

interface DispatchToProps {
  updateGroup: (groupId: string, updateGroupRequest: UpdateGroupRequest) => void
  fetchAssetAssignedList: (page: number, size: number, sortdir: string, sortfield: string, tenantId: string, groupId: string, searchInput: string) => void
  advancedFilter: (advancedFilterPostRequest: AdvancedFilterPostRequest, page: number, size: number, groupId: string, sortdir: string, sortfield: string, searchPage: string, searchKey: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchAssetAssignedList: (page: number, size: number, sortdir: string, sortfield: string, tenantId: string, groupId: string, searchInput: string) => dispatch(fetchAssetAssignedList(page, size, sortdir, sortfield, tenantId, groupId, searchInput)),
  updateGroup: (groupId: string, updateGroupRequest: UpdateGroupRequest) => dispatch(updateGroup(groupId, updateGroupRequest)),
  advancedFilter: (advancedFilterPostRequest: AdvancedFilterPostRequest, page: number, size: number, groupId: string, sortdir: string, sortfield: string, searchPage: string, searchKey: string) =>
    dispatch(advancedAssignedAssetListFilter(advancedFilterPostRequest, page, size, groupId, sortdir, sortfield, searchPage, searchKey))
})

interface StateToProps {
  assetAssignedListState: AssetAssignedListState
  updateGroupDetailsState: UpdateGroupState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  assetAssignedListState: state.assetAssignedList,
  updateGroupDetailsState: state.updateGroupDetailsState
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
interface ParentProps {
  reset: boolean
  resetComponent: React.Dispatch<React.SetStateAction<boolean>>
  addedAssets: GroupsAssetObj[]
  isEditEnabled: boolean
  setShowAddAssetWindow: React.Dispatch<React.SetStateAction<boolean>>
}

type AllProps = ParentProps & PropsFromRedux
export default connector(AssignedAssetListTable)
