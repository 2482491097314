import React, { useEffect } from 'react'
import DarkUnica from 'highcharts/themes/dark-unica'
import * as HighCharts from 'highcharts'
import { Card, CardContent, CircularProgress, Grid } from '@mui/material'
import { type ReportData, getReactApexChartBar } from './BarCharts'
import { useBarGraphDim } from '../../../../customHooks/barGraphDim'
import AuthError from '../../../../components/ErrorComponents/AuthError'
import type dayjs from 'dayjs'
import { type DateRange } from '@mui/x-date-pickers-pro'
import deleteIcon from '../../../../assets/deleteIcon.svg'
import { type KpiKey } from '../../redux/changeKpiType'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { isClickableKpiKey } from '../../redux/changeKpiType'
import { type BarChartState } from '../../types'
import { getKpiInfo } from './kpiInfo'
import i18Strings from '../../../../i18n/en-US.json'

interface Props {
  isHistory: boolean
  kpiName: KpiKey
  buildReportData: (reportData: ReportData, setReportData: React.Dispatch<React.SetStateAction<ReportData>>, kpiData: BarChartState) => void
  state: BarChartState
  handleKpiRemove: (kpi: KpiKey) => void
  dateRange: DateRange<dayjs.Dayjs>
  setDateRange: React.Dispatch<React.SetStateAction<DateRange<dayjs.Dayjs>>>
  retryHandler: () => void
}

const dataCompletenessStrings = i18Strings.AMIDataCollectionStrings.dataCompleteness

function BarKPI (props: Props): JSX.Element {
  DarkUnica(HighCharts)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [height] = useBarGraphDim()
  const [count, setCount] = React.useState<number>(0)

  const kpiClickHandler = (kpiName: KpiKey): void => {
    if (!props.isHistory && isClickableKpiKey(kpiName)) {
      navigate('/kpi-details')
      dispatch({ type: 'kpiTypeSlice/changeKpiType', payload: kpiName })
    }
  }

  const reportDataTemp: ReportData = {
    name: props.kpiName,
    type: 'bar',
    categories: [],
    series: [],
    colors: ['#1271B2FF', '#e3d659'],
    suffix: ''
  }

  const [reportData, setReportData] = React.useState<ReportData>(reportDataTemp)

  function lastUpdatedTime (): string {
    const timestamp = props.state.data?.CalculatedAt !== undefined ? new Date(props.state.data?.CalculatedAt) : ''
    let lastUpdated = ''

    if (timestamp !== '') {
      const gmtTimestamp = new Date(timestamp.toUTCString())

      // Format the hours and minutes
      const hour = gmtTimestamp.getUTCHours() < 10 ? `0${gmtTimestamp.getUTCHours()}` : gmtTimestamp.getUTCHours()
      const minutes = gmtTimestamp.getUTCMinutes() < 10 ? `0${gmtTimestamp.getUTCMinutes()}` : gmtTimestamp.getUTCMinutes()

      // Format the date
      const day = gmtTimestamp.getUTCDate() < 10 ? `0${gmtTimestamp.getUTCDate()}` : gmtTimestamp.getUTCDate()
      const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const month = monthNames[gmtTimestamp.getUTCMonth()]

      const lastUpdatedTime = `${day} ${month}, ${hour}:${minutes} GMT`

      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      lastUpdated = ` ${dataCompletenessStrings.lastUpdatedStart}${lastUpdatedTime}`
    }

    return lastUpdated
  }

  useEffect(() => {
    props.buildReportData(reportData, setReportData, props.state)
    if (props.state.data !== undefined) {
      setCount(props.state.data.totalCount)
    }
  }, [props.state])

  const handleWithFailure = (): JSX.Element => {
    if (props.state.isLoading) {
      return (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></div>
      )
    } else if (props.state.httpStatus === 401) {
      return (
        <div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="Unauthorized"/></div>
      )
    } else if (props.state.httpStatus === 403) {
      return (<div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="accessForbidden"/></div>)
    } else if (props.state.httpStatus === 200 && props.state.data === undefined) {
      return (
        <div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="NoDataPresent"/></div>
      )
    } else if (props.state.data !== undefined && props.state.httpStatus === 200) {
      return getReactApexChartBar(reportData, height, props.isHistory)
    } else {
      return (
        <div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="cannotFetch" retry={props.retryHandler}/></div>
      )
    }
  }

  return (
    <Card className='kpi-card' sx={{ height: window.innerWidth >= 1896 ? 457 : 382, borderRadius: '10px', backgroundColor: '#313030', color: '#f0f0f0' }}>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div onClick={() => { kpiClickHandler(props.kpiName) }} style={{ fontSize: window.innerWidth > 1300 ? '16px' : '13px', padding: '15px', paddingBottom: '0px', letterSpacing: '1px', textTransform: 'uppercase', color: isClickableKpiKey(props.kpiName) ? '#64C3FF' : '#F0F0F0', cursor: 'pointer' }}>{props.kpiName} ({count}) {getKpiInfo(props.kpiName)}</div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <img src={deleteIcon} onClick={() => { props.handleKpiRemove(props.kpiName) }} style={{ marginRight: 15, cursor: 'pointer', marginLeft: 5 }} alt='delete'/>
          </div>
        </div>
        <div style={{ padding: '0px 1px 15px 15px', fontSize: 14, fontWeight: 300 }}>
          <span>
            {lastUpdatedTime()}
          </span>
        </div>
      </div>
      <CardContent>
        <Grid>
          <div id='data-comp-container' className='centerChart'>
            <div className='circleChartDataComp'>
              {
                handleWithFailure()
              }
            </div>
          </div>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default BarKPI
