/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// istanbul ignore file
import React, { useCallback } from 'react'
import {
  AdvancedMarker,
  AdvancedMarkerAnchorPoint,
  Pin,
  useAdvancedMarkerRef
} from '@vis.gl/react-google-maps'

interface FeatureMarkerProps {
  count: number
  position: google.maps.LatLngLiteral
  featureId: string
  onMarkerClick?: (position: google.maps.LatLngLiteral) => void
}

const FeatureMarker = React.memo(({
  count,
  position,
  featureId,
  onMarkerClick
}: FeatureMarkerProps): JSX.Element => {
  const [markerRef, marker] = useAdvancedMarkerRef()
  const handleClick = useCallback(
    () => {
      if (marker && onMarkerClick) {
        onMarkerClick(position)
      }
    },
    [onMarkerClick, marker, featureId]
  )

  const abbreviate = (n: number): string => {
    if (n < 1e3) return n.toString()
    if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(1)}k`
    if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)}m`
    if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(1)}b`
    return `${+(n / 1e12).toFixed(1)}t`
  }

  return (
    <AdvancedMarker
      ref={markerRef}
      title={'Feature'}
      position={position}
      onClick={handleClick}
      anchorPoint={AdvancedMarkerAnchorPoint.CENTER}
      >
        <Pin background={'#FF644C'} glyphColor={'#000000'} borderColor={'none'} scale={1.3}>
          {abbreviate(count)}
        </Pin>
    </AdvancedMarker>
  )
})

FeatureMarker.displayName = 'FeatureMarker'

export default FeatureMarker
