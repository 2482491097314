/* eslint-disable @typescript-eslint/prefer-optional-chain */
// istanbul ignore file
import React, { useEffect } from 'react'
import { CircularProgress, styled } from '@mui/material'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { type ReportData, getReactApexChartBar } from './KpiComponents/BarCharts'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../../store'
import { fetchDonutKpiBarGraph, fetchDonutKpiHistoryTable } from '../redux/actionCreators'
import { buildDonutKpiHistoryBarGraphData, type GeneratedData } from './KpiComponents/BuildKpiHistory'
import { type KpiKeyDonut, type KpiKey, isDonutKpiWihtBar } from '../redux/changeKpiType'
import { getDonutType, getKpiIdFromName, getKpiService } from './KpiComponents/kpiUtils'
import HistoryDonutChart from './HistoryDonutChart'
import { type DonutKpiBarGraphState } from '../redux/historyDonutKpiBarGraphSlice'
import { type DonutKpiState } from '../types'
import { type BarGraphParameter } from '../KpiDetails'
import { kpiColorMapDonut } from './KpiComponents/BuildKpiData'
import useWindowSize from '../../../customHooks/windowResize'

export interface KpiSeriesData {
  name: string
  data: number[]
}
const nintyDayDate = dayjs(new Date((new Date()).setMonth(new Date().getMonth() - 3)))
export type KpiDispatchLoader = (startDate: string, endDate: string, kpiType: string, barType: string, service: string, kpiCategoryId: string) => void

export const getKpiType = (kpiType: KpiKey): string => {
  switch (kpiType) {
    case 'Total Registered':
      return 'total-registered'
    case 'Total Connected':
      return 'total-connected'
    case 'Total Shipped':
      return 'total-shipped'
    case 'Total Stale':
      return 'total-stale'
    case 'Total Reads':
      return 'total-reads-in-range'
    case 'Extended Coverage Level':
      return 'ec-mode-in-range'
    case 'Signal Breakdown':
      return 'signal-strength-in-range'
    case 'Alarm Breakdown':
      return 'alarms-in-range'
    case 'Battery Breakdown':
      return 'battery-percent-in-range'
    case 'Lifecycle Breakdown':
      return 'asset-lifecycle-in-range'
    default:
      return 'total-shipped'
  }
}

export interface KpiHistoryState <T> {
  isLoading: boolean
  error: boolean
  data: T[] | undefined
  httpStatus: number
}

interface ParentProps {
  startDate: dayjs.Dayjs
  endDate: dayjs.Dayjs
  setStartDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>
  setEndDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>
  barGraphParameter: BarGraphParameter
  setBarGraphParameter: React.Dispatch<React.SetStateAction<BarGraphParameter>>
  selectedDonut: number
  setSelectedDonut: React.Dispatch<React.SetStateAction<number>>
}

const WhiteDatePicker = styled(DatePicker)({
  '& input': {
    color: '#D0D0D0',
    fontSize: '14px',
    paddingRight: 0
  }
})

const CustomLocalizationProviderWrapper = styled('div')({
  backgroundColor: '#505050',
  border: 'none',
  borderRadius: '4px'
})

export const createReportDataTemp = (kpiName: KpiKeyDonut, selectedBar: string, kpiData: () => GeneratedData, barColor: string): ReportData => {
  const series = kpiData().seriesData
  const colors = [barColor]

  const categories = kpiData().category
  return {
    name: kpiName,
    type: 'bar',
    categories,
    series,
    colors,
    suffix: ''
  }
}

function HistoryDonutKpiBarGraph (props: AllProps): JSX.Element {
  const initialReportData: ReportData = {
    name: '',
    type: 'bar',
    categories: [],
    series: [],
    colors: [],
    suffix: ''
  }
  const [reportData, setReportData] = React.useState<ReportData>(initialReportData)
  const { width } = useWindowSize()
  const selectedKpi = useSelector((state: RootState) => state.kpiType)
  const [barColor, setBarColor] = React.useState<string>(kpiColorMapDonut[selectedKpi as KpiKeyDonut][0])

  const [barChart, setBarChart] = React.useState(getReactApexChartBar(reportData, '300px', true, props.setBarGraphParameter, selectedKpi))

  useEffect(() => {
    setBarChart(getReactApexChartBar(reportData, '350px', true, props.setBarGraphParameter, selectedKpi))
  }, [width, reportData])

  function kpiData (): GeneratedData {
    return buildDonutKpiHistoryBarGraphData(props.donutKpiBarGraphData)
  }

  const kpiLoader = (): KpiDispatchLoader => {
    return props.fetchDonutKpiBarGraph
  }

  const state = (): DonutKpiBarGraphState => {
    return props.donutKpiBarGraphData
  }

  const loadKpiData = (): void => {
    const loader = kpiLoader()
    const donutType = getDonutType(selectedKpi as KpiKeyDonut, props.selectedDonut, props.historyDonutState)
    loader(props.startDate.format('YYYY-MM-DD'), props.endDate.format('YYYY-MM-DD'), getKpiType(selectedKpi), donutType, getKpiService(selectedKpi), getKpiIdFromName(selectedKpi) ?? '')
  }

  useEffect(() => {
    if (isDonutKpiWihtBar(selectedKpi as KpiKeyDonut) && props.historyDonutState.httpStatus === 200) {
      loadKpiData()
    }
  }, [props.startDate, props.endDate, props.selectedDonut, props.historyDonutState])

  useEffect(() => {
    setReportData(createReportDataTemp(selectedKpi as KpiKeyDonut, props.barGraphParameter.bar.value, kpiData, barColor))
  }, [state().data])

  const handleGraphWithFailure = (): JSX.Element => {
    const kpiState = state()
    if (kpiState.isLoading) {
      return (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></div>
      )
    } else if (kpiState.httpStatus === 401) {
      return (
        <div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="Unauthorized"/></div>
      )
    } else if (kpiState.httpStatus === 403) {
      return (<div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="accessForbidden"/></div>)
    } else if (kpiState.httpStatus === 200 && kpiState.data === undefined) {
      return (
        <div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="NoDataPresent"/></div>
      )
    } else if (kpiState.data !== undefined && kpiState.httpStatus === 200) {
      return (
        barChart
      )
    } else {
      return (
        <div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="cannotFetch" retry={loadKpiData}/></div>
      )
    }
  }

  const handleDateChange = (type: string, date: dayjs.Dayjs): void => {
    if (type === 'start') {
      if (date.isAfter(props.endDate) || date.isSame(props.endDate)) {
        props.setStartDate(date)
        props.setEndDate(date)
      } else {
        props.setStartDate(date)
      }
    } else {
      if (date.isBefore(props.startDate) || date.isSame(props.startDate)) {
        props.setEndDate(date)
        props.setStartDate(date)
      } else {
        props.setEndDate(date)
      }
    }
  }

  return (
    <div className='kpiHistoryContainer' style={{ width: '100%' }}>
      <div className='kpiHistoryHeader'>
        <span style={{ fontSize: 18 }}>{selectedKpi}</span>
        {
          isDonutKpiWihtBar(selectedKpi as KpiKeyDonut)
            ? (
            <div style={{ display: 'flex' }}>
              <span style={{ paddingRight: '1em', display: 'flex', alignItems: 'center' }}>
                <span style={{ paddingRight: '6px', fontSize: '14px' }}>
                Start Date
                </span>
                <span className='datePicker-deviceDetails'>
                  <CustomLocalizationProviderWrapper>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <WhiteDatePicker minDate={nintyDayDate} disableFuture slotProps={{ textField: { size: 'small' }, field: { readOnly: true } }} value={props.startDate} onAccept={(val: any) => { handleDateChange('start', val) }} />
                    </LocalizationProvider>
                  </CustomLocalizationProviderWrapper>
                </span>
              </span>
              <span style={{ paddingRight: '1em', display: 'flex', alignItems: 'center' }}>
                <span style={{ paddingRight: '6px', fontSize: '14px' }}>
                End Date
                </span>
                <span className='datePicker-deviceDetails'>
                  <CustomLocalizationProviderWrapper>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <WhiteDatePicker minDate={nintyDayDate} disableFuture slotProps={{ textField: { size: 'small' }, field: { readOnly: true } }} value={props.endDate} onAccept={(val: any) => { handleDateChange('end', val) }} />
                    </LocalizationProvider>
                  </CustomLocalizationProviderWrapper>
                </span>
              </span>
            </div>
              )
            : <></>
        }
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: isDonutKpiWihtBar(selectedKpi as KpiKeyDonut) ? 'fit-content' : '35%' }}>
          <HistoryDonutChart selectedDonut={props.selectedDonut} setSelectedDonut={props.setSelectedDonut} setBarColor={setBarColor} />
        </div>
        {
          isDonutKpiWihtBar(selectedKpi as KpiKeyDonut)
            ? (
            <div style={{ width: isDonutKpiWihtBar(selectedKpi as KpiKeyDonut) ? '100%' : '65%', borderLeft: '1px solid #222222' }}>
              {handleGraphWithFailure()}
            </div>
              )
            : <></>
        }
      </div>
    </div>
  )
}

interface DispatchToProps {
  fetchDonutKpiHistoryTable: (kpiType: string, inputDateRead: string, donutType: string, page: number, size: number, service: string) => void
  fetchDonutKpiBarGraph: (startDate: string, endDate: string, kpiType: string, barType: string, service: string, kpiCategoryId: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchDonutKpiHistoryTable: (kpiType: string, inputDateRead: string, donutType: string, page: number, size: number, service: string) => dispatch(fetchDonutKpiHistoryTable(kpiType, inputDateRead, donutType, page, size, service)),
  fetchDonutKpiBarGraph: (startDate: string, endDate: string, kpiType: string, barType: string, service: string, kpiCategoryId: string) => dispatch(fetchDonutKpiBarGraph(startDate, endDate, kpiType, barType, service, kpiCategoryId))
})
interface StateToProps {
  donutKpiBarGraphData: DonutKpiBarGraphState
  historyDonutState: DonutKpiState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  donutKpiBarGraphData: state.donutKpiBarGraphData,
  historyDonutState: state.historyDonutState
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type AllProps = ParentProps & PropsFromRedux
export default connector(HistoryDonutKpiBarGraph)
