import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZAssetAttrParameter = z.object({
  parameter: z.string(),
  value: z.string()
})

const ZAssetAttrParameterData = z.object({
  total_count: z.number(),
  parameters: ZAssetAttrParameter.array()
})

const ZAssetAttrParameterResponse = z.object({
  http_status: z.number(),
  data: z.optional(ZAssetAttrParameterData),
  error: z.boolean(),
  error_message: z.optional(z.string())
})

export type AssetAttrParameter = z.infer<typeof ZAssetAttrParameter>
export type AssetAttrParameterData = z.infer<typeof ZAssetAttrParameterData>
export type AssetAttrParameterResponse = z.infer<typeof ZAssetAttrParameterResponse>

export async function getAssetParameter (
  assetId: string,
  configurationId: string,
  page: number,
  size: number,
  onSuccess: (assetParameterData: AssetAttrParameterData, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/assetrest/api/v1/udp/assets/asset-configuration/parameters/${assetId}?configurationId=${configurationId}&page=${page}&size=${size}`
  interceptedAxios
    .get(url)
    .then((response) => {
      const parseResponse = ZAssetAttrParameterResponse.safeParse(response.data)
      if (parseResponse.success) {
        if (
          parseResponse.data.data !== null &&
            parseResponse.data.data !== undefined
        ) {
          onSuccess(parseResponse.data.data, response.status)
        } else {
          onFailure('Error occurred while processing response', response.status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore else
      if (error.response !== undefined) {
        onFailure(error.response.data.error_message, error.response.data.http_status)
      } else {
        onFailure('Network Error', error.status)
      }
    })
}
