import { type AppDispatch } from '../../../store'
import * as ConfigurationsForStatus from '../services/configurationServiceForStatus'
import * as ConfigurationService from '../services/configurationDetailsService'
import * as ConfigurationForStatusSearch from '../services/configurationServiceForStatusSearch'
import { type Configuration } from '../services/configurationServiceForStatus'
import { type ConfigurationDetails } from '../services/configurationDetailsService'
import { configurationsForStatusErrorAction, configurationsForStatusLoadingAction, configurationsForStatusUpdateAction } from './configurationListForStatusSlice'
import {
  configurationDetailsLoadingAction,
  configurationDetailsUpdateAction,
  configurationDetailsErrorAction
} from './configurationDetailsSlice'
import { type AdvancedFilterPostRequest } from '../../asset-group/types'

export const fetchConfigurationsForStatus = (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  tenantId: string
) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(configurationsForStatusLoadingAction())

  void ConfigurationsForStatus.getConfigurationListForStatus(
    page, size, sortdir, sortfield, tenantId,
    (configurationList: Configuration, httpStatus: number) => {
      dispatch(
        configurationsForStatusUpdateAction({
          data: configurationList,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(configurationsForStatusErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const fetchConfigurationsForStatusSearch = (
  advancedAssignedAssetListFilter: AdvancedFilterPostRequest,
  page: number,
  size: number
) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(configurationsForStatusLoadingAction())
  void ConfigurationForStatusSearch.getConfigurationServiceForStatusSearch(
    advancedAssignedAssetListFilter,
    page,
    size,
    (configurationList: Configuration, httpStatus: number) => {
      dispatch(
        configurationsForStatusUpdateAction({
          data: configurationList,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(configurationsForStatusErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const fetchConfigurationDetails =
  (templateId: string, page: number, size: number, sortdir: string, sortfield: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
      dispatch(configurationDetailsLoadingAction())

      void ConfigurationService.getConfigurationList(
        templateId,
        page,
        size,
        sortdir,
        sortfield,
        (configurationList: ConfigurationDetails, httpStatus: number) => {
          dispatch(
            configurationDetailsUpdateAction({
              data: configurationList,
              httpStatus
            })
          )
        },
        (errorMessage, httpStatus) => {
          dispatch(configurationDetailsErrorAction({ errorMessage, httpStatus }))
        }
      )
    }
