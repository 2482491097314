// istanbul ignore file
import { InfoWindow } from '@vis.gl/react-google-maps'
import { type TopIssuesForPosition } from '../types'
import { useMemo } from 'react'
import { type InfoDataType, nortBadge } from '../GeoJsonMap'
import { Link, useNavigate } from 'react-router-dom'
import { changeAssetId } from '../../device-details/redux/assetIdSlice'
import { useDispatch } from 'react-redux'

interface CustomInfoWindowProps {
  position: google.maps.LatLngLiteral
  selectedMarker: TopIssuesForPosition[]
  setSelectedMarker: React.Dispatch<React.SetStateAction<InfoDataType | null>>
  showInfoModal: TopIssuesForPosition[]
  setInfoWindowModal: React.Dispatch<React.SetStateAction<boolean>>
}

const CustomInforWindow = (props: CustomInfoWindowProps): JSX.Element => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  function getInfoBar (): JSX.Element {
    const itemsToShow = props.selectedMarker.length > 3 ? props.selectedMarker.slice(0, 3) : props.selectedMarker
    return (
        <>
          <ul style={{ margin: 0, padding: 0 }}>
            {itemsToShow.map((asset, index) => {
              return (
                <li
                  style={{
                    listStyleType: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    padding: 5,
                    borderBottom: index < 2 ? '1px solid #e0e0e0' : 'none'
                  }}
                  key={`${asset.assetId} - ${index}`}
                >
                  <div id="popupDivAmi">
                    <div>
                      Asset Name: <span>{asset.assetName}</span>
                    </div>
                    <div>
                      Alarm Name: <span>{asset.alarmName}</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        style={{
                          paddingTop: 5,
                          paddingBottom: 5,
                          paddingRight: 10
                        }}
                        src={nortBadge(asset.severity.toLowerCase())}
                      />
                      <a
                        style={{ color: '#64c3ff', fontWeight: 600, cursor: 'pointer' }}
                        onClick={
                          // istanbul ignore next
                          () => {
                            handleDeviceDetailClick(
                              asset.assetId,
                              asset.assetName
                            )
                          }
                        }
                      >
                        View Details
                      </a>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
          {
            props.selectedMarker.length > 3 &&
            <span
              onClick={() => {
                props.setInfoWindowModal(true)
              }}
              style={{ color: '#64c3ff', fontWeight: 600, cursor: 'pointer' }}
            >
              +{props.selectedMarker.length - 3} more
            </span>
          }
        </>
    )
  }

  const getInfoBarMemoized = useMemo(() => getInfoBar(), [props.selectedMarker])

  const handleDeviceDetailClick = (
    assetId: string,
    assetName: string
  ): void => {
    props.setInfoWindowModal(false)
    dispatch(changeAssetId(assetId))
    sessionStorage.setItem(
      'paramAssetId',
      assetId != null ? assetId.toString() : ''
    )
    sessionStorage.setItem(
      'paramAssetName',
      assetName != null ? assetName.toString() : ''
    )
    navigate('/devicedetails')
  }

  if (props.selectedMarker.length === 1) {
    return (
      <InfoWindow
        onCloseClick={
          // istanbul ignore next
          () => {
            props.setSelectedMarker(null)
          }
        }
        position={{
          lat: props.position.lat + 0.00001,
          lng: props.position.lng
        }}
      >
        <>
          <div id="popupDivAmi">
            <div>
              Asset Name: <span>{props.selectedMarker[0].assetName}</span>
            </div>
            <div>
              Alarm Name: <span>{props.selectedMarker[0].alarmName}</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                style={{
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 10
                }}
                src={nortBadge(props.selectedMarker[0].severity ?? '')}
              />
              <Link
                style={{ color: '#64c3ff', fontWeight: 600 }}
                to={'/devicedetails'}
                onClick={
                  // istanbul ignore next
                  () => {
                    handleDeviceDetailClick(
                      props.selectedMarker[0].assetId ?? '',
                      props.selectedMarker[0].assetName ?? ''
                    )
                  }
                }
              >
                View Details
              </Link>
            </div>
          </div>
        </>
      </InfoWindow>
    )
  } else if (props.selectedMarker.length > 1) {
    return (
      <InfoWindow
        onCloseClick={
          // istanbul ignore next
          () => {
            props.setSelectedMarker(null)
          }
        }
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        position={{ lat: props.position.lat + 0.00001, lng: props.position.lng }}
      >
        {getInfoBarMemoized}
      </InfoWindow>
    )
  }
  return <></>
}

export default CustomInforWindow
