import { type AppDispatch } from '../../../store'
import * as ParameterListService from '../services/parameterListService'
import * as AssociateAssetsService from '../services/associateAssetService'
import * as DisassociateAssetsService from '../services/disassociateAssetService'
import * as ConfigurationService from '../services/configurationService'
import * as ConfigurationServiceBySearch from '../services/configurationListBySearch'
import * as AssetTypeService from '../services/assetTypeService'
import * as CreateConfigurationService from '../services/createConfigurationService'
import * as AssetListService from '../services/unAssignedAssetListService'
import * as AssetListServiceBySearch from '../services/unAssignedAssetListBySearchService'
import * as UnassignedButtonAssetService from '../services/assignedAssetListService'
import * as assignedAssetListBySearchService from '../services/assignedAssetListBySearchService'
import * as ConfigurationDetailsByIdService from '../services/configDetailsService'
import { parameterListDataErrorAction, parameterListDataLoadingAction, parameterListDataUpdateAction } from './parameterListSlice'
import { associateAssetsErrorAction, associateAssetsLoadingAction, associateAssetsUpdateAction } from './associateAssetsSlice'
import { disassociateAssetsErrorAction, disassociateAssetsLoadingAction, disassociateAssetsUpdateAction } from './disassociateAssetsSlice'
import { configurationListErrorAction, configurationListLoadingAction, configurationListUpdateAction } from './configurationListSlice'
import { assetTypesErrorAction, assetTypesLoadingAction, assetTypesUpdateAction } from './assetTypeSlice'
import { createConfigurationErrorAction, createConfigurationLoadingAction, createConfigurationUpdateAction } from './createConfigurationSlice'
import { assetListErrorAction, assetListLoadingAction, assetListUpdateAction } from './unAssignedAssetsListSlice'
import { assignedAssetListErrorAction, assignedAssetListLoadingAction, assignedAssetListUpdateAction } from './assignedAssetListSlice'
import { configurationDetailsErrorAction, configurationDetailsLoadingAction, configurationDetailsUpdateAction } from './configDetailsSlice'
import { type Configuration } from '../services/configurationService'
import { type AssociateAssetsRequest } from '../services/associateAssetService'
import { type DisassociateAssetsRequest } from '../services/disassociateAssetService'
import { type AssetTypes } from '../services/assetTypeService'
import { type ConfigurationTemplateRequest } from '../services/createConfigurationService'
import { type AssetList } from '../services/unAssignedAssetListService'
import { type ConfigurationDetails } from '../services/configDetailsService'
import { type AssignGroupRequest, postModifyConfigurationGroup, type GroupAssignConfigObj } from '../services/configAssignGroupService'
import { configurationGroupAssignErrorAction, configurationGroupAssignLoadingAction, configurationGroupAssignUpdateAction } from './configAssignGroupSlice'
import { uploadAssetProfileDataError, uploadAssetProfileDataLoading, uploadAssetProfileDataUpdate } from './updateAssetProfileSlice'
import { uploadAssetProfile, type uploadAssetProfileReq } from '../services/uploadAssetProfile'
import { type AdvancedFilterPostRequest } from '../../asset-group/types'

export const fetchParameterList = (assetTypeId: string) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(parameterListDataLoadingAction())

  void ParameterListService.getParameterList(
    assetTypeId,
    (parameterList, httpStatus) => {
      dispatch(
        parameterListDataUpdateAction({
          data: parameterList,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(parameterListDataErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const fetchAssetList = (
  assetTypeId: string,
  templateId: string,
  page: number,
  size: number,
  sortdir: string,
  sortfield: string
) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(assetListLoadingAction())

  void AssetListService.getUnassignedAssetList(
    assetTypeId,
    templateId,
    page,
    size,
    sortdir,
    sortfield,
    (assetList: AssetList, httpStatus) => {
      dispatch(
        assetListUpdateAction({
          data: assetList.assets,
          count: assetList.total_count,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(assetListErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const fetchAssetListBySearch = (
  assetTypeId: string,
  templateId: string,
  page: number,
  size: number,
  parameter: string,
  sortdir: string,
  sortfield: string
) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(assetListLoadingAction())

  void AssetListServiceBySearch.getUnassignedAssetListBySearch(
    assetTypeId,
    templateId,
    page,
    size,
    parameter,
    sortdir,
    sortfield,
    (assetList: AssetList, httpStatus) => {
      dispatch(
        assetListUpdateAction({
          data: assetList.assets,
          count: assetList.total_count,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(assetListErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const fetchAssignedAssetList = (
  templateId: string,
  page: number,
  size: number
) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(assignedAssetListLoadingAction())

  void UnassignedButtonAssetService.getAssignedAssetList(
    templateId,
    page,
    size,
    (assetList: AssetList, httpStatus) => {
      dispatch(
        assignedAssetListUpdateAction({
          data: assetList.assets,
          count: assetList.total_count,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(assignedAssetListErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const fetchAssignedAssetListBySearch = (
  templateId: string,
  page: number,
  size: number,
  parameter: string,
  sortdir: string,
  sortfield: string
) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(assignedAssetListLoadingAction())

  void assignedAssetListBySearchService.getAssignedAssetListBySearch(
    templateId,
    page,
    size,
    parameter,
    sortdir,
    sortfield,
    (assetList: AssetList, httpStatus) => {
      dispatch(
        assignedAssetListUpdateAction({
          data: assetList.assets,
          count: assetList.total_count,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(assignedAssetListErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const postAssetsForConfiguration = (associateAssets: AssociateAssetsRequest) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(associateAssetsLoadingAction())

  void AssociateAssetsService.assignAssetsToConfiguration(
    associateAssets,
    (associateAssetsResponse) => {
      dispatch(associateAssetsUpdateAction({ data: associateAssetsResponse }))
    },
    (errorMessage, httpStatus) => {
      dispatch(associateAssetsErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const postAssetsForRemovalFromConfiguration = (disassociateAssets: DisassociateAssetsRequest) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(disassociateAssetsLoadingAction())

  void DisassociateAssetsService.unAssignAssetsFromConfiguration(
    disassociateAssets,
    (disassociateAssetsResponse) => {
      dispatch(disassociateAssetsUpdateAction({ data: disassociateAssetsResponse }))
    },
    (errorMessage, httpStatus) => {
      dispatch(disassociateAssetsErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const fetchConfigurationList = (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  tenantId: string
) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(configurationListLoadingAction())

  void ConfigurationService.getConfigurationList(
    page, size, sortdir, sortfield, tenantId,
    (configurationList: Configuration, httpStatus: number) => {
      dispatch(
        configurationListUpdateAction({
          data: configurationList,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(configurationListErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const fetchConfigurationListBySearch = (
  advancedFilterPostRequest: AdvancedFilterPostRequest,
  page: number,
  size: number,
  sortdir: string,
  sortfield: string
) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(configurationListLoadingAction())

  void ConfigurationServiceBySearch.getConfigurationListBySearch(
    advancedFilterPostRequest, page, size, sortdir, sortfield,
    (configurationList: Configuration, httpStatus: number) => {
      dispatch(
        configurationListUpdateAction({
          data: configurationList,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(configurationListErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const fetchAssetTypes = () => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(assetTypesLoadingAction())

  void AssetTypeService.getAssetTypes(
    (assetTypes: AssetTypes[], httpStatus: number) => {
      dispatch(
        assetTypesUpdateAction({
          data: assetTypes,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(assetTypesErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const postConfigurationTemplate = (tenantId: string, configurationTemplate: ConfigurationTemplateRequest) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(createConfigurationLoadingAction())

  void CreateConfigurationService.createConfiguration(
    tenantId,
    configurationTemplate,
    (createConfigurationResponse) => {
      dispatch(createConfigurationUpdateAction({ data: createConfigurationResponse }))
    },
    (errorMessage, httpStatus) => {
      dispatch(createConfigurationErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const getConfigurationDetailsById = (templateId: string) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(configurationDetailsLoadingAction())

  void ConfigurationDetailsByIdService.getConfigurationDetailsById(
    templateId,
    (configurationDetails: ConfigurationDetails, httpStatus: number) => {
      dispatch(configurationDetailsUpdateAction({ data: configurationDetails, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(configurationDetailsErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const modifyConfigurationGroup = (configurationGroupAssignBody: AssignGroupRequest, tenantId: string, assign: boolean) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(configurationGroupAssignLoadingAction())

  await postModifyConfigurationGroup(
    configurationGroupAssignBody, tenantId, assign,
    (groupData: GroupAssignConfigObj[], httpStatus: number) => {
      dispatch(configurationGroupAssignUpdateAction({ data: groupData, httpStatus }))
    },
    (errorMessage: string, httpStatus: number) => {
      dispatch(configurationGroupAssignErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const uploadData = (uploadAssetProfileRequestParams: uploadAssetProfileReq, file: File) => async (dispatch: any) => {
  dispatch(uploadAssetProfileDataLoading())
  void uploadAssetProfile(
    uploadAssetProfileRequestParams,
    file,
    (message: string, httpStatus: number) => {
      dispatch(uploadAssetProfileDataUpdate({ httpStatus, message }))
    },
    (message, httpStatus) => {
      dispatch(uploadAssetProfileDataError({ httpStatus, message }))
    }
  )
}
