/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Card, CardContent, Grid, Paper, type SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, styled, tableCellClasses, FormHelperText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { GenericDropdown, type Option } from '../../../components/GenericDropdown'
import { ParameterWarning } from './warnings'
import { type ParameterListState } from '../redux/parameterListSlice'
import { Button } from '../../../components/Button'
import LanguageStrings from '../../../i18n/locales'
import { isValidIp, isValidName, regexChecker, isValidPort, isValidGenericName, isValidTimeBwZeroToSeven, isValidAlphanumericName10Char, isValidAlphanumericName40Char, isValidAlphanumericName20Char, isValidNumericName10Char, isValidDomainName30Char } from '../../../utils/validator'

const AssetConfigurationStrings = LanguageStrings().AssetConfigurationStrings.components.AssetConfiguration
const AssetConfigurationError = LanguageStrings().AssetConfigurationStrings.components.warnings

const StyledTableCell = styled(TableCell)({
  paddingTop: 10,
  paddingBottom: 5
})

export interface parametersData {
  parameterName: string
  cimCode: string
  value: string
  sampleValue: string | null
  param_mapping_id: string
}

function isParameterInvalid (parameter: parametersData): boolean {
  console.log('isParameterInvalid', parameter)
  if (parameter.parameterName === '' && parameter.value === '') return false
  else if ((parameter.value === '') && parameter.sampleValue !== null) return true
  else if ((parameter.value !== '' && parameter.parameterName.toUpperCase().includes('IP') && isValidIp(parameter.value))) {
    const ipaddr = parameter.value.split('.')
    for (const section of ipaddr) {
      if (section.length >= 2 && section[0] === '0') return true
    }
  } else if (parameter.value !== '' && parameter.parameterName.toUpperCase().includes('DLMS')) return false
  else if ((parameter.value !== '' && parameter.parameterName.toUpperCase().includes('IP') && !isValidIp(parameter.value))) return true
  else if ((parameter.value !== '' && parameter.parameterName.toUpperCase().includes('PORT') && !isValidPort(parameter.value))) return true
  else if ((parameter.value !== '' && parameter.parameterName.toUpperCase().includes('GENERIC COMMAND') && !isValidGenericName(parameter.value))) return true
  else if ((parameter.value !== '' && parameter.parameterName.toUpperCase().includes('NRT START HOUR CONFIGURATION') && !isValidTimeBwZeroToSeven(parameter.value))) return true
  else if ((parameter.value !== '' && (parameter.parameterName.toUpperCase().includes('SET INTERMEDIARY OPERATOR') || parameter.parameterName.toUpperCase().includes('SET INTERMEDIARY APN')) && !isValidAlphanumericName10Char(parameter.value))) return true
  else if ((parameter.value !== '' && parameter.parameterName.toUpperCase().includes('SET FINAL APN') && !isValidAlphanumericName40Char(parameter.value))) return true
  else if ((parameter.value !== '' && (parameter.parameterName.toUpperCase().includes('SET NETWORK USER') || parameter.parameterName.toUpperCase().includes('PSK')) && !isValidAlphanumericName20Char(parameter.value))) return true
  else if ((parameter.value !== '' && (parameter.parameterName.toUpperCase().includes('TRANSMISSION') || parameter.parameterName.toUpperCase().includes('READING')) && !isValidNumericName10Char(parameter.value))) return true
  else if ((parameter.value !== '' && (parameter.parameterName.toUpperCase().includes('DNS') || parameter.parameterName.toUpperCase().includes('NTP')) && !isValidDomainName30Char(parameter.value))) return true
  else if ((parameter.value !== '' && !regexChecker(parameter.value))) return true
  return false
}

export function getErrorMessage (parameterName: string): string {
  if (parameterName.toUpperCase().includes('PORT')) {
    return AssetConfigurationStrings.Port_out_of_range
  } else if (parameterName.toUpperCase().includes('IP')) {
    return AssetConfigurationStrings.Please_enter_a_valid_IP
  } else if (parameterName.toUpperCase().includes('GENERIC COMMAND')) {
    return AssetConfigurationStrings.Generic_validation
  } else if (parameterName.toUpperCase().includes('NRT START HOUR CONFIGURATION')) {
    return AssetConfigurationStrings.Please_enter_a_valid_time
  } else if ((parameterName.toUpperCase().includes('SET INTERMEDIARY OPERATOR')) || (parameterName.toUpperCase().includes('SET INTERMEDIARY APN'))) {
    return AssetConfigurationStrings.Valid_Alphanumeric
  } else if (parameterName.toUpperCase().includes('SET FINAL APN')) {
    return AssetConfigurationStrings.Valid_Alphanumeric_40
  } else if ((parameterName.toUpperCase().includes('SET NETWORK USER') || parameterName.toUpperCase().includes('PSK'))) {
    return AssetConfigurationStrings.Valid_Alphanumeric_20
  } else if ((parameterName.toUpperCase().includes('TRANSMISSION') || parameterName.toUpperCase().includes('READING'))) {
    return AssetConfigurationStrings.Only_Numeric
  } else if ((parameterName.toUpperCase().includes('DNS') || parameterName.toUpperCase().includes('NTP'))) {
    return AssetConfigurationStrings.Valid_Domain
  } else {
    return AssetConfigurationStrings.Please_select_a_value
  }
}

const AssetConfiguration = ({ parameterList, parameters, name, setParameters, setName }: { parameterList: ParameterListState, parameters: parametersData[], name: string, setParameters: any, setName: any }): JSX.Element => {
  const [nameErrorInit, setNameErrorInit] = useState(false)
  const [parameterListErrorInit, setParameterListErrorInit] = useState<string[]>([])
  const handleAddParameter = (): void => {
    const newParameter = { parameterName: '', cimCode: '', value: '', sampleValue: '', param_mapping_id: '' }
    setParameters([...parameters, newParameter])
  }

  const getParameterInputType = (sampleValue: string, index: number, row: parametersData): JSX.Element => {
    const booleanOptions: Option[] = [
      {
        label: 'True',
        value: 'True'
      },
      {
        label: 'False',
        value: 'False'
      }
    ]
    const utcOptions: Option[] = []
    const minutesOptions: Option[] = [60, 30, 15].map((item) => ({ label: `${item} Minutes`, value: `${item} Minutes` }))
    const utcNumbers = [-8, -7, -6, -5, 0, 1, 2, 3, 4]
    for (const i of utcNumbers) {
      let val = ''
      if (i > 0) val = '+'
      else if (i === 0) val = '-'
      const obj: Option = {
        label: `UTC${i !== 0 ? val : ''}${i !== 0 ? i : ''}`,
        value: `UTC${i !== 0 ? val : ''}${i !== 0 ? i : ''}`
      }
      utcOptions.push(obj)
    }
    const GENERIC_SEPARATOR = '|'
    if (sampleValue.toUpperCase().includes('TRUE') || sampleValue.toUpperCase().includes('FALSE')) {
      return (
        <GenericDropdown dataTestId={`parameter-bool-${index}`} width={200} options={booleanOptions} value={parameters[index].value} onChange={(e) => { setParameters(parameters.map((item, i) => { return i === index ? { ...item, value: e.target.value } : item })) }} label={''} />
      )
    } else if (sampleValue.toUpperCase().includes('UTC')) {
      return (
        <GenericDropdown dataTestId={`parameter-utc-${index}`} width={200} options={utcOptions} value={parameters[index].value} onChange={(e) => { setParameters(parameters.map((item, i) => { return i === index ? { ...item, value: e.target.value } : item })) }} label={''} />
      )
    } else if (sampleValue.toUpperCase().includes('MINUTES')) {
      return (
        <GenericDropdown dataTestId={`parameter-minutes-${index}`} width={200} options={minutesOptions} value={parameters[index].value} onChange={(e) => { setParameters(parameters.map((item, i) => { return i === index ? { ...item, value: e.target.value } : item })) }} label={''} />
      )
    } else if (sampleValue.toUpperCase().includes(GENERIC_SEPARATOR)) {
      const optionValues: Option[] = sampleValue.split(GENERIC_SEPARATOR).map(part => ({ value: part, label: part }))
      return (
          <GenericDropdown dataTestId={`parameter-details-${index}`} width={200} options={optionValues} value={parameters[index].value} onChange={(e) => { setParameters(parameters.map((item, i) => { return i === index ? { ...item, value: e.target.value } : item })) }} label={''} />
      )
    } else {
      return (
        <>
          <TextField
            id={`custom-textfield-${index}`}
            variant="outlined"
            placeholder={row.parameterName === '' ? AssetConfigurationStrings.Select_Parameter : (row.sampleValue === null ? AssetConfigurationStrings.Value_Not_Required : `${AssetConfigurationStrings.eg} ${row.sampleValue}`)}
            disabled={parameters[index].parameterName === '' || row.sampleValue === null || parameters[index].parameterName === 'DefaultPushSchedule'}
            InputLabelProps={{
              disabled: true
            }}
            value={parameters[index].value}
            onChange={(e) => {
              setParameters(parameters.map((item, i) => { return i === index ? { ...item, value: e.target.value } : item }))
              const newParamError = [...parameterListErrorInit]
              if (!newParamError.includes(parameters[index].parameterName)) {
                newParamError.push(parameters[index].parameterName)
                setParameterListErrorInit(newParamError)
              }
            }}
            size='small'
            autoComplete='off'
            className='custom-textfield'
            sx={{
              ...textStyle.root,
              width: '222px',
              border: (isParameterInvalid(row) && parameterListErrorInit.includes(row.parameterName)) ? '1px solid #EE3426' : 'none'
            }}
          />
        </>
      )
    }
  }

  const parseParameters = (): Option[] => {
    const tempParsedParameters: Option[] = []
    parameterList.parameterList.forEach((parameter) => {
      tempParsedParameters.push({ value: parameter.param_name, label: parameter.param_name })
    })
    return tempParsedParameters
  }

  const [parameterOptionsAvailable, setParameterOptionsAvailable] = useState<Option[]>(parseParameters())

  useEffect(() => {
    const filteredParamOptions: Option[] = parseParameters().filter((param) => {
      return !parameters.some(parameter => parameter.parameterName === param.value)
    })
    setParameterOptionsAvailable(filteredParamOptions)
  }, [parameters])

  // istanbul ignore next
  const handleRemoveParameter = (index: number): void => {
    const newParameters = [...parameters]
    newParameters.splice(index, 1)
    const newParamError = [...parameterListErrorInit]
    newParamError.splice(parameterListErrorInit.indexOf(parameters[index].parameterName), 1)
    setParameterListErrorInit(newParamError)
    setParameters(newParameters)
  }

  const getOptions = (index: number): Option[] => {
    const options = [{ label: parameters[index].parameterName, value: parameters[index].parameterName }, ...parameterOptionsAvailable]
    return options
  }

  const handleChange = (event: SelectChangeEvent<string>, parameterIndex: number): void => {
  // const handleChange = (event: React.ChangeEvent<HTMLSelectElement>, parameterIndex: number): void => {
    setParameters(parameters.map((parameter, index) => {
      // istanbul ignore else
      if (index === parameterIndex) {
        parameter.parameterName = event.target.value
        for (let i = 0; i < parameterList.parameterList.length; i++) {
          if (parameterList.parameterList[i].param_name === event.target.value) {
            parameter.cimCode = parameterList.parameterList[i].param_id
            parameter.sampleValue = parameterList.parameterList[i].sample_value
            parameter.param_mapping_id = parameterList.parameterList[i].param_mapping_id
            parameter.value = parameter.parameterName === 'DefaultPushSchedule' ? parameter.sampleValue ?? '' : ''
          }
        }
      }
      return parameter
    }))
  }

  const textStyle = {
    root: {
      backgroundColor: '#272727',
      '& .MuiInputBase-input': {
        color: '#F0F0F0',
        backgroundColor: '#404040'
      },
      '& .MuiInputLabel-root': {
        color: '#F0F0F0',
        '&.Mui-focused': {
          color: '#F0F0F0'
        }
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#00000000'
        },
        '&:hover fieldset': {
          borderColor: '#00000000'
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00000000'
        }
      }
    }
  }

  const grayRowStyle = {
    color: '#F0F0F0',
    fontSize: '12px'
  }

  const headerRowStyle = {
    color: '#F0F0F0',
    fontSize: '12px',
    borderRight: '1px solid #000',
    borderBottom: 'none'
  }

  const blueRowStyle = {
    color: '#64C3FF'
  }

  const isNameNotValid = (): boolean => {
    return (!isValidName(name) || (name.length > 30))
  }

  return (
    <Card sx={{ borderRadius: '10px', backgroundColor: '#272727', color: '#F0F0F0 !important', padding: '15px', marginTop: '20px' }}>
        <h4 style={{ padding: 10 }}>{AssetConfigurationStrings.ASSET_CONFIGURATION}</h4>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
            <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>{AssetConfigurationStrings.Name}<sup style={{ color: '#EE3426' }}>*</sup></span>
            <TextField
              id='name-textfield'
              variant="outlined"
              placeholder='Enter'
              InputLabelProps={{
                disabled: true
              }}
              size='small'
              className='custom-textfield'
              value={name}
              onChange={(e) => {
                setName(e.target.value)
                setNameErrorInit(true)
              }}
              sx={{
                ...textStyle.root,
                width: '450px',
                border: (isNameNotValid() && nameErrorInit) ? '1px solid #EE3426' : 'none'
              }}
            />
            {(isNameNotValid() && nameErrorInit) && (
                <FormHelperText sx={{ color: '#EE3426', paddingLeft: '10px', whiteSpace: 'nowrap' }} className="errorColor">
                {AssetConfigurationError.Validate_textfield}
                </FormHelperText>
            )}
            </Grid>
          </Grid>
        </CardContent>
        <div style={{ width: 'calc(100% - 20)', height: 2, border: '1px solid black', margin: 20 }}></div>
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: 20 }}>
            <h4 style={{ padding: 10 }}>{AssetConfigurationStrings.PARAMETER_LIST} ({parameters.length})</h4>
            <Button primaryColor={parameters.length >= parameterList.parameterList.length ? '#B0B0B0' : undefined} disabled={parameters.length >= parameterList.parameterList.length} dataTestId='param-add-btn' sx={{ margin: 0 }} onClick={handleAddParameter}>Add</Button>
          </div>
          <div>
          <div className='parameters-table-heading'>
            <h5>{AssetConfigurationStrings.Parameters}</h5>
          </div>
            <TableContainer
              component={Paper}
              sx={{
                maxHeight: 400,
                '&::-webkit-scrollbar': {
                  width: 10
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#272727'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#4f4f4f',
                  borderRadius: 0
                }
              }}
            >
              <Table
                sx={{
                  minWidth: 650,
                  backgroundColor: '#272727',
                  alignItems: 'center',
                  height: 'max-content',
                  position: 'relative',
                  border: '1px solid black'
                }}
                aria-label='simple table'
              >
                <TableHead>
                  <TableRow
                    sx={{
                      position: 'sticky',
                      top: 0,
                      zIndex: 100,
                      backgroundColor: '#272727',
                      borderBottom: '1px solid black'
                    }}
                  >
                    <TableCell sx={headerRowStyle} align='left'>
                      {AssetConfigurationStrings.PARAMETER_NAME}
                    </TableCell>
                    <TableCell sx={headerRowStyle} align='left'>
                      {AssetConfigurationStrings.CIM_CODE}
                    </TableCell>
                    <TableCell sx={headerRowStyle} align='left'>
                      {AssetConfigurationStrings.VALUE}
                    </TableCell>
                    <TableCell sx={headerRowStyle} align='left'>
                      {AssetConfigurationStrings.ACTION}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ borderTop: 'none' }}>
                    {
                      parameters.length > 0
                        ? (
                            parameters.map((row, index) => (
                          <TableRow
                          key={index}
                          sx={{
                            [`& .${tableCellClasses.root}`]: {
                              borderBottom: '1px solid black'
                            },
                            paddingTop: '0',
                            paddingBottom: '0'
                          }}
                        >
                          <StyledTableCell
                            sx={blueRowStyle}
                            align='left'
                            component='th'
                            scope='row'
                          >
                            <GenericDropdown dataTestId={`parameter-${index}`} width={200} options={getOptions(index)} value={parameters[index].parameterName} onChange={(e) => { handleChange(e, index) }} label={''} />
                          </StyledTableCell>
                          <StyledTableCell sx={grayRowStyle} align='left'>
                          <TextField
                              variant="outlined"
                              placeholder='Text'
                              InputLabelProps={{
                                disabled: true
                              }}
                              value={parameters[index].parameterName !== '' ? parameters[index].cimCode : ''}
                              disabled
                              size='small'
                              className='custom-textfield'
                              sx={
                                textStyle.root
                              }
                            />
                          </StyledTableCell>
                          <StyledTableCell sx={{ ...grayRowStyle, paddingTop: `${(isParameterInvalid(row) && parameterListErrorInit.includes(row.parameterName)) ? '16px' : 'auto'}` }} align='left'>
                            { getParameterInputType(row.sampleValue !== null ? row.sampleValue : '', index, row) }
                            {(isParameterInvalid(row) && parameterListErrorInit.includes(row.parameterName)) && (
                              <FormHelperText sx={{ color: '#EE3426', paddingLeft: '5px' }} className="errorColor">
                              {getErrorMessage(row.parameterName)}
                              </FormHelperText>
                            )}
                          </StyledTableCell>
                          <StyledTableCell data-testid={`delete-${index}`} className='elementOnHover' sx={blueRowStyle} align='left' onClick={() => { handleRemoveParameter(index) }}>
                          {AssetConfigurationStrings.Delete}
                          </StyledTableCell>
                        </TableRow>
                            ))
                          )
                        : (
                        <ParameterWarning />
                          )
                    }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Card>
  )
}

export default AssetConfiguration
