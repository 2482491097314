import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type HistoryARDataObject } from '../types'

export interface HistoryARTableState {
  isLoading: boolean
  errorMessage: string | undefined
  data: HistoryARDataObject | undefined
  httpStatus: number
}

const initialState: HistoryARTableState = {
  isLoading: true,
  errorMessage: undefined,
  data: undefined,
  httpStatus: 0
}

const historyARTableSlice = createSlice({
  name: 'KpiHistoryARTable',
  initialState,
  reducers: {
    historyARTableLoadingAction: (state) => {
      return {
        isLoading: true,
        errorMessage: undefined,
        data: undefined,
        httpStatus: 0
      }
    },
    historyARTableUpdateAction: (state, action: PayloadAction<{ data: HistoryARDataObject, httpStatus: number }>) => {
      return {
        isLoading: false,
        errorMessage: undefined,
        data: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    historyARTableErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        errorMessage: action.payload.errorMessage,
        data: undefined,
        httpStatus: action.payload.httpStatus
      }
    },
    historyARTableResetAction: () => {
      return initialState
    }
  }
})

export const {
  historyARTableLoadingAction,
  historyARTableUpdateAction,
  historyARTableErrorAction,
  historyARTableResetAction
} = historyARTableSlice.actions

export default historyARTableSlice.reducer
