// istanbul ignore file
import React, { useCallback } from 'react'
import {
  AdvancedMarker,
  AdvancedMarkerAnchorPoint,
  useAdvancedMarkerRef
} from '@vis.gl/react-google-maps'
import { getClusterColor } from '../points'
import { ClusterSvg } from './cluster-svg'

interface FeaturesClusterMarkerProps {
  totalCount: number
  onMarkerClick?: (
    position: google.maps.LatLngLiteral
  ) => void
  position: google.maps.LatLngLiteral
  size: number
  sizeAsText: string
}

export const FeaturesClusterMarker = React.memo(({ totalCount, onMarkerClick, position, size, sizeAsText }: FeaturesClusterMarkerProps) => {
  const [markerRef, marker] = useAdvancedMarkerRef()
  const handleClick = useCallback(
    () => {
      if (onMarkerClick != null && (position != null)) {
        onMarkerClick(position)
      }
    },
    [onMarkerClick, marker, position]
  )

  return (
    <AdvancedMarker
      ref={markerRef}
      position={position}
      zIndex={size}
      onClick={handleClick}
      anchorPoint={AdvancedMarkerAnchorPoint.CENTER}>
      <ClusterSvg uniqueId={`${position.lat}-${position.lng}`} color={getClusterColor(size, totalCount)} name={sizeAsText} size={'50'} fontSize={'50'} />
    </AdvancedMarker>
  )
})

FeaturesClusterMarker.displayName = 'FeaturesClusterMarker'

export default FeaturesClusterMarker
