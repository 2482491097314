/* istanbul ignore file */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type HistoryKSDataObject } from '../types'

export interface ExportKSKpiDetailsState {
  isLoading: boolean
  error: string | undefined
  exportKSKpiDetails: HistoryKSDataObject | undefined
  httpStatus: number
}

// Initial state of the slice
const initialState: ExportKSKpiDetailsState = {
  isLoading: true,
  error: undefined,
  exportKSKpiDetails: undefined,
  httpStatus: 0
}

// Slice to return from action
export const exportKSKpiDetailsSlice = createSlice({
  name: 'exportKSKpiDetails',
  initialState,
  reducers: {
    exportKSKpiDetailsLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        exportKSKpiDetails: undefined,
        httpStatus: 0
      }
    },
    exportKSKpiDetailsUpdateAction: (state, action: PayloadAction<{ data: HistoryKSDataObject, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: undefined,
        exportKSKpiDetails: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    exportKSKpiDetailsErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        exportKSKpiDetails: undefined,
        httpStatus: action.payload.httpStatus
      }
    },
    // istanbul ignore next
    exportKSKpiDetailsResetAction: () => {
      return {
        isLoading: false,
        error: undefined,
        exportKSKpiDetails: undefined,
        httpStatus: 0
      }
    }
  }
})

// Actions to export
export const {
  exportKSKpiDetailsLoadingAction,
  exportKSKpiDetailsUpdateAction,
  exportKSKpiDetailsErrorAction,
  exportKSKpiDetailsResetAction
} = exportKSKpiDetailsSlice.actions

export default exportKSKpiDetailsSlice.reducer
