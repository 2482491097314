import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type BarKpiDataObject } from '../../types'

export interface TotalStaleState {
  isLoading: boolean
  error: boolean
  data: BarKpiDataObject | undefined
  httpStatus: number
}

const initialState: TotalStaleState = {
  isLoading: true,
  error: false,
  data: undefined,
  httpStatus: 0
}

const totalStaleSlice = createSlice({
  name: 'totalStale',
  initialState,
  reducers: {
    totalStaleLoadingAction: (state) => {
      return {
        isLoading: true,
        error: false,
        data: undefined,
        httpStatus: 0
      }
    },
    totalStaleUpdateAction: (state, action: PayloadAction<{ data: BarKpiDataObject, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: false,
        data: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    totalStaleErrorAction: (state, action: PayloadAction<number>) => {
      return {
        isLoading: false,
        error: true,
        data: undefined,
        httpStatus: action.payload
      }
    }
  }
})

export const {
  totalStaleLoadingAction,
  totalStaleUpdateAction,
  totalStaleErrorAction
} = totalStaleSlice.actions

export default totalStaleSlice.reducer
