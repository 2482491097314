import * as React from 'react'
import { type SelectChangeEvent } from '@mui/material/Select'
import { useNavigate } from 'react-router-dom'
import { type SnackbarOrigin } from '@mui/material/Snackbar'
import Typography from '@mui/material/Typography'
import EnhancedTable from './DeviceTable'
import { type DeviceListState } from '../../redux/deviceListSlice'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../../../store'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import LanguageStrings from '../../../../i18n/locales'
import UploadFile from '../../../upload-file/components/uploadFile'
import { Button } from '../../../../components/Button'
import SearchBox from '../../../../components/SearchBox'
import { fetchAssetBySearchValue, fetchDeviceList } from '../../redux/actionCreators'
import { useAuth } from 'react-oidc-context'
import { type Sort } from './DeviceTable'
import { type OperationPermissionState } from '../../../dashboard/redux/operationPermissionSlice'
import { getOperationPermissions } from '../../../dashboard/redux/actionCreator'
import BackButton from '../../../../components/BackButton'

export interface State extends SnackbarOrigin {
  open: boolean
}
const DeviceListStrings = LanguageStrings().AMIDataCollectionStrings.DeviceListComponent

function EnhancedTableToolbar (props: any): JSX.Element {
  const { numSelected } = props
  return (

        <div>
            {numSelected > 0
              ? (
                <Typography
                    sx={{ flex: '1 1 100%', paddingLeft: '16px', fontFamily: 'Honeywell Sans Web' }}
                    color='inherit'
                    variant='subtitle1'
                    component='div'
                >
                    {numSelected} {DeviceListStrings.selected}
                </Typography>
                )
              : (
                <div className='configurations-table-heading'>
                    <h5>{DeviceListStrings.DeviceList} <span className='config-list-box'>{props.deviceCount} {DeviceListStrings.devices}</span></h5>
                </div>
                )}
        </div>
  )
}

const style: React.CSSProperties = {
  width: '99%',
  backgroundColor: '#272727',
  border: '1px solid #101010',
  borderRadius: '10px',
  boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
  marginBottom: '16px',
  padding: 0,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0
}

function DeviceList (props: PropsFromRedux): JSX.Element {
  const [selected] = React.useState<readonly string[]>([])
  const [sortCondition, setSortCondition] = React.useState('')
  const [showUploadModal, setShowUploadModal] = React.useState(false)
  const [searchValue, setSearchValue] = React.useState('')
  const rowsPerPage = 10
  const [page, setPage] = React.useState<number>(1)
  const [sort, setSort] = React.useState<Sort>({ order: 'asc', orderBy: 'id' })
  const operationPermissions: OperationPermissionState = useSelector((state: RootState) => state.operationPermissions)
  const auth = useAuth()

  const navigate = useNavigate()

  const handleChange = (event: SelectChangeEvent): void => {
    setSortCondition(event.target.value)
  }

  /* istanbul ignore next */
  const handleSearch = (): void => {
    setPage(1)
    props.fetchAssetBySearchValue(searchValue, auth.user?.profile.customer as string, 0, rowsPerPage, sort.orderBy, sort.order)
  }

  function handleBreadCrumbsClick (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    event.preventDefault()
  }

  React.useEffect(() => {
    if (!operationPermissions.updated) {
      props.getOperationPermissions(auth.user?.profile.sub as string)
    }
  }, [])

  const grandParentBreadcrumbRoute = props.grandParentBreadcrumb === LanguageStrings().Dashboard.Asset_Management ? '/card' : '/isense'

  return (
        <div style={{ width: 'calc(100vw - 3.5rem)', paddingRight: '1rem' }}>
          <div role='presentation' data-testid='breadcrumbs' onClick={handleBreadCrumbsClick} className='AmiHeading' >
              <Breadcrumbs aria-label='breadcrumb' sx={{ color: '#f0f0f0', fontSize: '12px', marginBottom: '14px', fontFamily: 'Honeywell Sans Web', marginTop: '12px' }}>
                  <Link aria-label='navBreadcrumb' className='assetmgmtli' underline='none' color='inherit' onClick={() => { navigate(grandParentBreadcrumbRoute) }} >
                  {props.grandParentBreadcrumb}
                  </Link>

                  <Typography color='inherit' sx={{ fontSize: '12px', fontFamily: 'Honeywell Sans Web', cursor: 'text' }}>{DeviceListStrings.DeviceList}</Typography>
              </Breadcrumbs>
              </div>
              <div className='ArrowDeviceList'>
                  <div>
                      <span style={{ paddingRight: '10px' }}>
                        <BackButton className='assetmgmtli' ariaLabel='navArrow' onclick={() => { navigate(grandParentBreadcrumbRoute) }} />
                      </span>
                      <span data-testid='deviceHistoryDetails' className='DevicelistDeviceComponent'>{DeviceListStrings.DeviceList}</span>
                      <span className='TotalDeviceComponent'> {DeviceListStrings.Total} <b>{props.deviceList.deviceCount}</b> {DeviceListStrings.devices}</span>
                  </div>
                  {
                    operationPermissions.permissions.can_add_asset
                      ? <div>
                      <Button sx={{ marginRight: '1em' }} width='fit-content' onClick={() => { setShowUploadModal(true) }} dataTestId='import-asset-list-btn'>{DeviceListStrings.ImportDeviceList}</Button>
                    </div>
                      : <></>
                  }
              </div>
          <EnhancedTableToolbar numSelected={selected.length} handleChange={handleChange}
                          sortCondition={sortCondition} deviceList={props.deviceList.deviceList}
                          deviceCount={props.deviceList.deviceCount}
                      />
          <div style={style}>
            <SearchBox sx={{ width: '480px' }} debounce={true} placeholder={DeviceListStrings.searchPlaceholder} immediate={true} emptyEnterFallback={() => { props.fetchDeviceList(0, 10, 'asc', 'id', auth.user?.profile.customer as string) }} searchVal={searchValue} setSearchVal={setSearchValue}
                  performAction={handleSearch} inputStyle={{ paddingRight: 30 }} />
            <EnhancedTable searchVal={searchValue} page={page} rowsPerPage={rowsPerPage} sort={sort} setPage={setPage} setSort={setSort} performAction={() => { props.fetchAssetBySearchValue(searchValue, auth.user?.profile.customer as string, page - 1, rowsPerPage, sort.orderBy, sort.order) }}/>
          </div>
          {showUploadModal && <UploadFile open={showUploadModal} handleClose={() => { setShowUploadModal(false) }} />}
        </div>
  )
}

interface DispatchToProps {
  fetchDeviceList: (page: number, size: number, sortdir: string, sortfield: string, tenantId: string) => void
  fetchAssetBySearchValue: (searchVal: string, tenantId: string, page: number, size: number, sortfield: string, sortdir: string) => void
  getOperationPermissions: (sub: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchDeviceList: (page: number, size: number, sortdir: string, sortfield: string, tenantId: string) => dispatch(fetchDeviceList(page, size, sortdir, sortfield, tenantId)),
  fetchAssetBySearchValue: (searchVal: string, tenantId: string, page: number, size: number, sortfield: string, sortdir: string) => dispatch(fetchAssetBySearchValue(searchVal, tenantId, page, size, sortfield, sortdir)),
  getOperationPermissions: (sub: string) => dispatch(getOperationPermissions(sub))
})

interface StateToProps {
  deviceList: DeviceListState
  grandParentBreadcrumb: string
}

const mapStateToProps = (state: RootState): StateToProps => ({
  deviceList: state.deviceList,
  grandParentBreadcrumb: state.grandParentBreadcrumb
})
const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(DeviceList)
