import { exportFileFormat } from '../../../utils/ExportFileUtils'
import interceptedAxios from '../../../utils/interceptedAxios'

export async function exportProvisioningHistory (
  tenantId: string,
  onSuccess: (exportFile: any) => any,
  onFailure: (errorMessage: string) => any
): Promise<void> {
  const url = `/fileprocessingservice/api/v1/udp/provisioningHistory/download?tenantId=${tenantId}`
  interceptedAxios
    .get(url, { responseType: 'blob' })
    .then((response) => {
      exportFileFormat(response, 'ProvisioningHistory_')
      onSuccess(response)
    })
    .catch((error) => {
      onFailure(error)
    })
}
