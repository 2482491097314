/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import interceptedAxios from '../../../utils/interceptedAxios'
import { type IntervalReading, ZIntervalReadingResponse } from './intervalReadingsService'

export async function getKpiIntervalReadings (
  assetId: string,
  kpiId: string,
  fromDate: string,
  toDate: string,
  tenantId: string,
  onSuccess: (intervalReadings: IntervalReading, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const encodedFrom = fromDate.replace('+', '%2B')
  const encodedTo = toDate.replace('+', '%2B')
  const url = `/kpirest/api/v1/udp/kpis/flows?kpiId=${kpiId}&assetId=${assetId}&from=${encodedFrom}&to=${encodedTo}&tenantId=${tenantId}`
  interceptedAxios
    .get(url)
    .then((response) => {
      // istanbul ignore else
      if (response.status === 200 && !response.data.error) {
        const parseResponse = ZIntervalReadingResponse.safeParse(response.data)
        if (parseResponse.success) {
          onSuccess(parseResponse.data.data, response.status)
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      } else {
        onFailure(response.data.error, response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore else
      if (error.response !== undefined) {
        // istanbul ignore else
        if (error.response.data !== undefined) {
          onFailure(error.response.data.error_message, error.response.status)
        } else {
          onFailure(error.message, error.response.status)
        }
      } else {
        onFailure(error.message, error.status)
      }
    })
}
