import { type AxiosResponse } from 'axios'

// istanbul ignore next
export function exportFileFormat (response: AxiosResponse<any, any>, filePrefix: string): void {
  const url = window.URL.createObjectURL(
    new Blob([response.data], { type: 'text/csv' })
  )
  const link = document.createElement('a')
  link.href = url
  link.setAttribute(
    'download', formatDownloadName(filePrefix))
  document.body.appendChild(link)
  link.click()
  link.parentNode?.removeChild(link)
}

export function formatDownloadName (filePrefix: string): string {
  const today = new Date()

  const year = today.getFullYear()
  const month = (today.getMonth() + 1).toString().padStart(2, '0') // Month is 0-based
  const day = today.getDate().toString().padStart(2, '0') // Ensure day is two digits
  const hours = today.getHours().toString().padStart(2, '0') // Retrieve hours
  const minutes = today.getMinutes().toString().padStart(2, '0') // Retrieve minutes

  const dateString = `${month}-${day}-${year} ${hours}_${minutes}` // Modify the format

  // Remove trailing underscore if present
  const prefix = filePrefix.endsWith('_') ? filePrefix.slice(0, -1) : filePrefix

  return `${prefix}_${dateString}.csv`
}
