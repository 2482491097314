export interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  numeric: boolean
  isDate: boolean
}

export const getColumnsByKpi = (kpiDetailName: string): HeadCell[] => {
  switch (kpiDetailName) {
    case 'total-shipped-details':
      return [
        {
          id: 'serialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Meter Serial Number',
          isDate: false
        },
        {
          id: 'assetType',
          numeric: false,
          disablePadding: false,
          label: 'Asset Type',
          isDate: false
        },
        {
          id: 'cliponSerialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Clip-on Serial Number',
          isDate: false
        },
        {
          id: 'installedDate',
          numeric: false,
          disablePadding: false,
          label: 'Install Date',
          isDate: true
        },
        {
          id: 'shippedDate',
          numeric: false,
          disablePadding: false,
          label: 'Shipment Date',
          isDate: true
        }
      ]
    case 'total-registered-shipped-details':
      return [
        {
          id: 'serialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Meter Serial Number',
          isDate: false
        },
        {
          id: 'assetType',
          numeric: false,
          disablePadding: false,
          label: 'Asset Type',
          isDate: false
        },
        {
          id: 'cliponSerialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Clip-on Serial Number',
          isDate: false
        },
        {
          id: 'installedDate',
          numeric: false,
          disablePadding: false,
          label: 'Install Date',
          isDate: true
        },
        {
          id: 'shippedDate',
          numeric: false,
          disablePadding: false,
          label: 'Shipment Date',
          isDate: true
        }
      ]
    case 'total-registered-communicated-details':
      return [
        {
          id: 'serialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Meter Serial Number',
          isDate: false
        },
        {
          id: 'assetType',
          numeric: false,
          disablePadding: false,
          label: 'Asset Type',
          isDate: false
        },
        {
          id: 'cliponSerialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Clip-on Serial Number',
          isDate: false
        },
        {
          id: 'installedDate',
          numeric: false,
          disablePadding: false,
          label: 'Install Date',
          isDate: true
        },
        {
          id: 'firstCommDate',
          numeric: false,
          disablePadding: false,
          label: 'Communicated Date',
          isDate: true
        }
      ]
    case 'total-connected-details':
      return [
        {
          id: 'serialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Meter Serial Number',
          isDate: false
        },
        {
          id: 'assetType',
          numeric: false,
          disablePadding: false,
          label: 'Asset Type',
          isDate: false
        },
        {
          id: 'cliponSerialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Clip-on Serial Number',
          isDate: false
        },
        {
          id: 'firstCommDate',
          numeric: false,
          disablePadding: false,
          label: 'Communicated Date',
          isDate: true
        },
        {
          id: 'installationDate',
          numeric: false,
          disablePadding: false,
          label: 'Install Date',
          isDate: true
        },
        {
          id: 'lastCommunicationDate',
          numeric: false,
          disablePadding: false,
          label: 'Last Communicated Date',
          isDate: true
        }
      ]
    case 'total-stale-details':
      return [
        {
          id: 'serialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Meter Serial Number',
          isDate: false
        },
        {
          id: 'assetType',
          numeric: false,
          disablePadding: false,
          label: 'Asset Type',
          isDate: false
        },
        {
          id: 'cliponSerialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Clip-on Serial Number',
          isDate: false
        },
        {
          id: 'firstCommDate',
          numeric: false,
          disablePadding: false,
          label: 'Communicated Date',
          isDate: true
        },
        {
          id: 'installedDate',
          numeric: false,
          disablePadding: false,
          label: 'Install Date',
          isDate: true
        },
        {
          id: 'lastCommDate',
          numeric: false,
          disablePadding: false,
          label: 'Last Communicated Date',
          isDate: true
        }
      ]
    case 'last-reads-alarms-details':
      return [
        {
          id: 'serialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Meter Serial Number',
          isDate: false
        },
        {
          id: 'assetType',
          numeric: false,
          disablePadding: false,
          label: 'Asset Type',
          isDate: false
        },
        {
          id: 'cliponSerialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Clip-on Serial Number',
          isDate: false
        },
        {
          id: 'installationDate',
          numeric: false,
          disablePadding: false,
          label: 'Install Date',
          isDate: true
        },
        {
          id: 'lastCommunicationDate',
          numeric: false,
          disablePadding: false,
          label: 'Last Communicated Date',
          isDate: true
        },
        {
          id: 'valueString',
          numeric: false,
          disablePadding: false,
          label: 'Alarm Reported Date',
          isDate: true
        }
      ]
    case 'total-reads-details-in-range':
      return [
        {
          id: 'serialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Meter Serial Number',
          isDate: false
        },
        {
          id: 'assetType',
          numeric: false,
          disablePadding: false,
          label: 'Asset Type',
          isDate: false
        },
        {
          id: 'cliponSerialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Clip-on Serial Number',
          isDate: false
        },
        {
          id: 'installationDate',
          numeric: false,
          disablePadding: false,
          label: 'Install Date',
          isDate: true
        },
        {
          id: 'lastCommunicationDate',
          numeric: false,
          disablePadding: false,
          label: 'Last Communicated Date',
          isDate: true
        },
        {
          id: 'firstCommDate',
          numeric: false,
          disablePadding: false,
          label: 'Communicated Date',
          isDate: true
        },
        {
          id: 'intervalLength',
          numeric: true,
          disablePadding: false,
          label: 'Interval Length',
          isDate: false
        }
      ]
    case 'ec-mode-details':
      return [
        {
          id: 'serialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Meter Serial Number',
          isDate: false
        },
        {
          id: 'assetType',
          numeric: false,
          disablePadding: false,
          label: 'Asset Type',
          isDate: false
        },
        {
          id: 'cliponSerialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Clip-on Serial Number',
          isDate: false
        },
        {
          id: 'installationDate',
          numeric: false,
          disablePadding: false,
          label: 'Install Date',
          isDate: true
        },
        {
          id: 'lastCommunicationDate',
          numeric: false,
          disablePadding: false,
          label: 'Last Communicated Date',
          isDate: true
        },
        {
          id: 'firstCommDate',
          numeric: false,
          disablePadding: false,
          label: 'Communicated Date',
          isDate: true
        },
        {
          id: 'value',
          numeric: false,
          disablePadding: false,
          label: 'ECL Mode',
          isDate: false
        }
      ]
    case 'signal-strength-details':
      return [
        {
          id: 'serialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Meter Serial Number',
          isDate: false
        },
        {
          id: 'assetType',
          numeric: false,
          disablePadding: false,
          label: 'Asset Type',
          isDate: false
        },
        {
          id: 'cliponSerialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Clip-on Serial Number',
          isDate: false
        },
        {
          id: 'installationDate',
          numeric: false,
          disablePadding: false,
          label: 'Install Date',
          isDate: true
        },
        {
          id: 'lastCommunicationDate',
          numeric: false,
          disablePadding: false,
          label: 'Last Communicated Date',
          isDate: true
        },
        {
          id: 'firstCommDate',
          numeric: false,
          disablePadding: false,
          label: 'Communicated Date',
          isDate: true
        },
        {
          id: 'valueString',
          numeric: false,
          disablePadding: false,
          label: 'Signal Strength',
          isDate: false
        }
      ]
    case 'alarms-breakdown-details':
      return [
        {
          id: 'serialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Meter Serial Number',
          isDate: false
        },
        {
          id: 'assetType',
          numeric: false,
          disablePadding: false,
          label: 'Asset Type',
          isDate: false
        },
        {
          id: 'cliponSerialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Clip-on Serial Number',
          isDate: false
        },
        {
          id: 'installationDate',
          numeric: false,
          disablePadding: false,
          label: 'Install Date',
          isDate: true
        },
        {
          id: 'lastCommunicationDate',
          numeric: false,
          disablePadding: false,
          label: 'Last Communicated Date',
          isDate: true
        },
        {
          id: 'numAlarms',
          numeric: false,
          disablePadding: false,
          label: 'No. of Alarms',
          isDate: false
        },
        {
          id: 'status',
          numeric: false,
          disablePadding: false,
          label: 'Alarm Status',
          isDate: false
        },
        {
          id: 'selectedDate',
          numeric: false,
          disablePadding: false,
          label: 'Alarm Raised Date',
          isDate: true
        },
        {
          id: 'valueString',
          numeric: false,
          disablePadding: false,
          label: 'Severity',
          isDate: false
        }
      ]
    case 'firmware-details':
      return [
        {
          id: 'serialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Meter Serial Number',
          isDate: false
        },
        {
          id: 'assetType',
          numeric: false,
          disablePadding: false,
          label: 'Asset Type',
          isDate: false
        },
        {
          id: 'cliponSerialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Clip-on Serial Number',
          isDate: false
        },
        {
          id: 'installationDate',
          numeric: false,
          disablePadding: false,
          label: 'Install Date',
          isDate: true
        },
        {
          id: 'lastCommunicationDate',
          numeric: false,
          disablePadding: false,
          label: 'Last Communicated Date',
          isDate: true
        },
        {
          id: 'lcState',
          numeric: false,
          disablePadding: false,
          label: 'Lifecycle State',
          isDate: false
        },
        {
          id: 'firmwareVersion',
          numeric: false,
          disablePadding: false,
          label: 'FW Version',
          isDate: false
        }
      ]
    case 'battery-percent-details':
      return [
        {
          id: 'serialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Meter Serial Number',
          isDate: false
        },
        {
          id: 'assetType',
          numeric: false,
          disablePadding: false,
          label: 'Asset Type',
          isDate: false
        },
        {
          id: 'cliponSerialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Clip-on Serial Number',
          isDate: false
        },
        {
          id: 'installationDate',
          numeric: false,
          disablePadding: false,
          label: 'Install Date',
          isDate: true
        },
        {
          id: 'lastCommunicationDate',
          numeric: false,
          disablePadding: false,
          label: 'Last Communicated Date',
          isDate: true
        },
        {
          id: 'firstCommDate',
          numeric: false,
          disablePadding: false,
          label: 'Communicated Date',
          isDate: true
        },
        {
          id: 'value',
          numeric: false,
          disablePadding: false,
          label: 'Battery Percentage',
          isDate: false
        }
      ]
    case 'asset-details-with-state':
      return [
        {
          id: 'serialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Meter Serial Number',
          isDate: false
        },
        {
          id: 'assetType',
          numeric: false,
          disablePadding: false,
          label: 'Asset Type',
          isDate: false
        },
        {
          id: 'cliponSerialNumber',
          numeric: false,
          disablePadding: false,
          label: 'Clip-on Serial Number',
          isDate: false
        },
        {
          id: 'installationDate',
          numeric: false,
          disablePadding: false,
          label: 'Install Date',
          isDate: true
        },
        {
          id: 'lastCommunicationDate',
          numeric: false,
          disablePadding: false,
          label: 'Last Communicated Date',
          isDate: true
        },
        {
          id: 'lcState',
          numeric: false,
          disablePadding: false,
          label: 'Lifecycle State',
          isDate: false
        },
        {
          id: 'value',
          numeric: false,
          disablePadding: false,
          label: 'Transition Date',
          isDate: true
        }
      ]
    default:
      return []
  }
}
