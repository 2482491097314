import { Box, CircularProgress, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, styled, tableCellClasses } from '@mui/material'
import Pagination from '../../../components/TablePagination'
import { visuallyHidden } from '@mui/utils'
import React, { useEffect } from 'react'
import { type ConnectedProps, connect } from 'react-redux'
import { type RootState } from '../../../store'
import { fetchConfigurationDetails } from '../redux/actionCreator'
import { type CommandFailureMessage, type ConfigurationDetailsObj } from '../services/configurationDetailsService'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { type ConfigurationDetailsState } from '../redux/configurationDetailsSlice'
import successBadge from '../../../assets/successBadge.svg'
import progressBadge from '../../../assets/progressBadge.svg'
import failedBadge from '../../../assets/failedBadge.svg'
import { type TemplateDetailsState } from '../redux/selectedTemplateDetailsSlice'
import LanguageStrings from '../../../i18n/locales'
import iButton from '../../../assets/iButton.svg'
import Modal from '../../../components/Modal'
import notStarted from '../../../assets/notStarted.svg'
import notApplicableBadge from '../../../assets/notApplicableBadge.svg'

const ConfigurationDetailStrings = LanguageStrings().ConfigurationUploadStrings.components.ConfigurationDetails

const style = {
  width: '99%',
  bgcolor: '#313030',
  border: '1px solid #101010',
  borderRadius: '10px',
  boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
  marginBottom: '16px',
  padding: 0
}

function EnhancedTableHead (props: any): any {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler =
      (newOrderBy: keyof ConfigurationDetailsObj) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, newOrderBy)
      }
  return (
          <TableHead data-testid='table-header'>
              <TableRow>
                  {headCells.map((headCell: HeadCell) => (
                      <StyledTableCell
                          key={headCell.id}
                          align={'left'}
                          padding={headCell.disablePadding ? 'none' : 'normal'}
                          sortDirection={orderBy === headCell.id ? order : false}
                      >
                          <TableSortLabel
                              direction={orderBy === headCell.id ? order : 'asc'}
                              onClick={createSortHandler(headCell.id)}
                              aria-label={headCell.label}
                              data-testid='test-sort'

                          >
                              {headCell.label}
                              {orderBy === headCell.id
                                ? (
                                  <Box component='span' sx={visuallyHidden}>
                                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                  </Box>
                                  )
                                : null}
                          </TableSortLabel>
                      </StyledTableCell>
                  ))}
              </TableRow>
          </TableHead>
  )
}

interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 16px',
    fontFamily: 'Honeywell Sans Web'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web'
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& th': {
    border: '1px solid #202020'
  }
}))

const headCells: readonly HeadCell[] = [
  {
    id: 'serial_number',
    numeric: false,
    disablePadding: false,
    label: ConfigurationDetailStrings.METER_SERIAL_NUMBER
  },
  {
    id: 'asset_name',
    numeric: false,
    disablePadding: false,
    label: ConfigurationDetailStrings.ASSET_NAME
  },
  {
    id: 'upload status',
    numeric: false,
    disablePadding: false,
    label: ConfigurationDetailStrings.UPLOAD_STATUS
  }
]

type Order = 'asc' | 'desc'

interface Sort {
  order: Order
  orderBy: string
}

const ConfigurationDetailsComp = (props: PropsFromRedux): JSX.Element => {
  const [configDetails, setConfigDetails] = React.useState<ConfigurationDetailsObj[]>([])
  const [page, setPage] = React.useState(1)
  const [sort, setSort] = React.useState<Sort>({ order: 'asc', orderBy: 'serial_number' })
  const [anchorEl, setAnchorEl] = React.useState<HTMLImageElement | null>(null)
  const [isModalOpen, setModalOpen] = React.useState(false)
  const rowsPerPage = 10

  const handleIButtonClick = (event: React.MouseEvent<HTMLImageElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (page >= 1 && props.templateDetailsState.template_id !== '') {
      props.fetchConfigurationDetails(props.templateDetailsState.template_id, page, rowsPerPage, sort.order, sort.orderBy)
    }
  }, [page, sort])

  useEffect(() => {
    if (props.configurationDetailsState.configurationDetails?.configuration_status !== undefined && props.configurationDetailsState.configurationDetails.configuration_status.length > 0) {
      setConfigDetails(props.configurationDetailsState.configurationDetails.configuration_status)
    }
  }, [props.configurationDetailsState.configurationDetails])

  const handleRetry = (): void => {
    props.fetchConfigurationDetails(props.templateDetailsState.template_id, page, rowsPerPage, sort.order, sort.orderBy)
  }

  const handleRequestSort =
        (event: React.MouseEvent<unknown>, newOrderBy: keyof ConfigurationDetailsObj): void => {
          const isAsc = sort.orderBy === newOrderBy && sort.order === 'asc'
          const toggledOrder = isAsc ? 'desc' : 'asc'
          setSort({ order: toggledOrder, orderBy: newOrderBy })
        }

  const getReasonsModal = (commandFailureMessage: CommandFailureMessage[]): JSX.Element => {
    const tdrStyle: React.CSSProperties = {
      padding: '10px 0'
    }
    return (
      <Modal sx={{ paddingBottom: '10px' }} height='auto' width='600px' title={`Reason For Failure (${commandFailureMessage.length})`} onClose={handleModalClose}>
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          <table style={{ width: '100%', paddingRight: '5px' }}>
            <thead style={{ position: 'sticky', top: 0, backgroundColor: 'rgb(32, 32, 32)' }}>
              <tr>
                <th style={tdrStyle}>{ConfigurationDetailStrings.PARAMETER}</th>
                <th style={tdrStyle}>{ConfigurationDetailStrings.REASON}</th>
              </tr>
            </thead>
            <tbody>
              {
                commandFailureMessage.length > 0
                  ? commandFailureMessage.map((message: CommandFailureMessage, index: number) => (
                      <tr key={index}>
                        <td style={{ margin: '1em 0 0 0', fontSize: '14px', ...tdrStyle, borderBottom: '1px solid #000' }}>{message.failed_command_param}</td>
                        <td style={{ margin: '1em 0 0 0', fontSize: '14px', ...tdrStyle, borderBottom: '1px solid #000' }}>{message.failure_reason}</td>
                      </tr>
                  ))
                  : ''
              }
            </tbody>
          </table>
        </div>
      </Modal>
    )
  }

  const handleModalClose = (): void => {
    setModalOpen(false)
  }

  const handleModalOpen = (): void => {
    setModalOpen(true)
    setAnchorEl(null)
  }

  const getCompletionStatusBadge = (status: string, commandFailureMessage: CommandFailureMessage[]): JSX.Element => {
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined
    const popOver = (
      <Popover
      id={id}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}>
      <div style={{ fontSize: '14px', display: 'flex', justifyContent: 'space-between', width: '210px', padding: '0.55em' }}>
        <div>
          <p style={{ fontWeight: 700, margin: 0 }}>{ConfigurationDetailStrings.Parameter}</p>
          {
            commandFailureMessage.length <= 2
              ? (commandFailureMessage.length > 0
                  ? commandFailureMessage.map((message: CommandFailureMessage, index: number) => (
              <p key={index} style={{ margin: 0 }}>{message.failed_command_param}</p>
                  ))
                  : '')
              : (
                  <>
                    <p style={{ margin: 0 }}>{commandFailureMessage[0].failed_command_param}</p>
                    <p style={{ margin: 0 }}>{commandFailureMessage[1].failed_command_param}</p>
                  </>
                )
          }
          {
            commandFailureMessage.length > 2 ? <p data-testid='plusReasonsLink' onClick={handleModalOpen} style={{ color: '#005EAC', cursor: 'pointer' }}>+{commandFailureMessage.length - 2} more</p> : ''
          }
        </div>
        <div>
          <p style={{ fontWeight: 700, margin: 0 }}>{ConfigurationDetailStrings.Reason}</p>
          {
            commandFailureMessage.length <= 2
              ? (commandFailureMessage.length > 0
                  ? commandFailureMessage.map((message: CommandFailureMessage, index: number) => (
              <p key={index} style={{ margin: 0 }}>{message.failure_reason.slice(0, 12)}{message.failure_reason.length > 12 ? '...' : ''}</p>
                  ))
                  : '')
              : (
                  <>
                    <p style={{ margin: 0 }}>{commandFailureMessage[0].failure_reason.slice(0, 12)}{commandFailureMessage[0].failure_reason.length > 12 ? '...' : ''}</p>
                    <p style={{ margin: 0 }}>{commandFailureMessage[1].failure_reason.slice(0, 12)}{commandFailureMessage[1].failure_reason.length > 12 ? '...' : ''}</p>
                  </>
                )
          }
        </div>
      </div>
    </Popover>
    )
    switch (status) {
      case 'Success':
        return <img data-testid='successBadge' src={successBadge} />
      case 'In Progress':
        return <img data-testid="progressBadge" src={progressBadge} />
      case 'Not Started':
        return <img data-testid="notStartedBadge" src={notStarted} />
      case 'Not Applicable':
        return <img data-testid="notApplicableBadge" src={notApplicableBadge} />
      case 'Failure':
        return <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '102px' }}> {isModalOpen ? getReasonsModal(commandFailureMessage) : ''} <img data-testid='failedBadge' src={failedBadge} />{popOver} <img data-testid='iButtonicon' onMouseOver={handleIButtonClick} src={iButton} /></span>
      default:
        return <span data-testid='na'>{ConfigurationDetailStrings.Not_Applicable}</span>
    }
  }

  if (props.configurationDetailsState.configurationDetails?.configuration_status !== undefined && props.configurationDetailsState.configurationDetails.configuration_status.length > 0 && props.configurationDetailsState.httpStatus === 200) {
    return (
      <>
        <Box sx={style} className="devices">
          <Paper
            sx={{
              width: '100%',
              mb: 2,
              backgroundColor: '#272727',
              boxShadow: 'unset',
              color: '#F0F0F0',
              marginBottom: '0'
            }}
          >
            <TableContainer className="device-table">
              <Table
                sx={{ minWidth: 750, color: '#f0f0f0' }}
                aria-labelledby="tableTitle"
                size={'small'}
              >
                <EnhancedTableHead
                  order={sort.order}
                  orderBy={sort.orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {configDetails.length > 0 &&
                    configDetails.map((row: ConfigurationDetailsObj, index: number) => {
                      const labelId = `enhanced-table-checkbox-${row.asset_serial_number}`

                      return (
                        <StyledTableRow
                          hover
                          tabIndex={-1}
                          key={row.asset_serial_number}
                          aria-label="devices-list-row"
                        >
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            aria-label="device-name"
                            data-testid="device-name"
                          >
                            {row.asset_serial_number}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.asset_name}
                          </StyledTableCell>
                          <StyledTableCell>
                          {getCompletionStatusBadge(row.asset_status_summary, row.command_failure_message)}
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {props.configurationDetailsState.configurationDetails?.total_count > 10 && (<Pagination count={props.configurationDetailsState.configurationDetails?.total_count} page={page} setPage={setPage} rowsPerPage={rowsPerPage} siblingCount={1}
                        boundaryCount={1} />)}
          </Paper>
        </Box>
      </>
    )
  } else if (props.configurationDetailsState.isLoading) {
    return (
      <div className='CircularprogressAmi'>
        <CircularProgress />
      </div>
    )
  } else if (props.configurationDetailsState.httpStatus === 401) {
    return (
            <div className='authError'><AuthError errorMessage="Unauthorized"/></div>
    )
  } else if (props.configurationDetailsState.httpStatus === 403) {
    return (
        <div className='authError'><AuthError errorMessage="accessForbidden"/></div>
    )
  } else if ((props.configurationDetailsState.configurationDetails === null || (props.configurationDetailsState.configurationDetails.configuration_status !== undefined && props.configurationDetailsState.configurationDetails.configuration_status.length === 0)) && props.configurationDetailsState.httpStatus === 200) {
    return (
        <div className='authError'><AuthError errorMessage="NoDataPresent"/></div>
    )
  } else if ((props.configurationDetailsState.configurationDetails === null || (props.configurationDetailsState.configurationDetails.configuration_status !== undefined && props.configurationDetailsState.error === 'Not found')) && props.configurationDetailsState.httpStatus === 404) {
    return (
        <div className='authError'><AuthError errorMessage="NoDataPresent"/></div>
    )
  } else {
    return (
        <div className='authError'><AuthError errorMessage="cannotFetch" retry={handleRetry}/></div>
    )
  }
}

interface DispatchToProps {
  fetchConfigurationDetails: (templateId: string, page: number, size: number, sortdir: string, sortfield: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchConfigurationDetails: (templateId: string, page: number, size: number, sortdir: string, sortfield: string) => dispatch(fetchConfigurationDetails(templateId, page, size, sortdir, sortfield))
})

interface StateToProps {
  configurationDetailsState: ConfigurationDetailsState
  templateDetailsState: TemplateDetailsState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  configurationDetailsState: state.configurationDetailsForStatus,
  templateDetailsState: state.templateDetails
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ConfigurationDetailsComp)
