interface ClusterSvgProps {
  color: string
  name: string
  size: string
  fontSize: string
  uniqueId: string
}

export const ClusterSvg = (props: ClusterSvgProps): JSX.Element => {
  return (
    <svg data-testid={props.uniqueId} fill={props.color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width={props.size} height={props.size} transform="translate(0 25)">
      <circle cx="120" cy="120" opacity=".6" r="70"></circle>
      <circle cx="120" cy="120" opacity=".3" r="90"></circle>
      <circle cx="120" cy="120" opacity=".2" r="110"></circle>
      <text x="50%" y="50%" style={{ fill: '#fff', userSelect: 'none' }} textAnchor='middle' fontSize={props.fontSize} dominantBaseline="middle" fontFamily="roboto,arial,sans-serif">{props.name}</text>
    </svg>
  )
}
