import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type TopIssuesForPosition, type TopIssuesForPositionState } from '../types'

const initialState: TopIssuesForPositionState = {
  topIssues: [],
  loading: false,
  httpStatus: 0,
  error: undefined
}

export const TopIssuesForPositionSlice = createSlice({
  name: 'TopIssuesForPositionState',
  initialState,
  reducers: {
    TopIssuesForPositionLoadingAction: (state) => {
      return {
        ...initialState,
        loading: true
      }
    },
    TopIssuesForPositionUpdateAction: (state, action: PayloadAction<{ topIssuesForPosition: TopIssuesForPosition[], httpStatus: number }>) => {
      return {
        ...initialState,
        topIssues: action.payload.topIssuesForPosition,
        httpStatus: action.payload.httpStatus
      }
    },
    TopIssuesForPositionErrorAction: (state, action: PayloadAction<{ error: string | undefined, httpStatus: number }>) => {
      return {
        ...initialState,
        error: action.payload.error,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  TopIssuesForPositionLoadingAction,
  TopIssuesForPositionUpdateAction,
  TopIssuesForPositionErrorAction
} = TopIssuesForPositionSlice.actions

export default TopIssuesForPositionSlice.reducer
