import React from 'react'
import BackButton from '../../components/BackButton'
import { Button } from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { changeConfigId } from './redux/configIdSlice'
import { Box, Tab, Tabs } from '@mui/material'
import { TabPanel, a11yProps } from '../../components/TabsHelper'
import AmiMeterCollectionDP from './DataPublisherConfigs'
import ReportScheduleMain from './components/ReportSchedulerComponents/ReportScheduleMain'
import ReportTemplate from './components/ReportTemplate'
import LanguageStrings from '../../i18n/locales'
import './DataPublisher.css'

const SchedulerTableStrings = LanguageStrings().AMIDataCollectionStrings.ConfigurationUploadStrings.SchedulerStrings.SchedulerTableStrings

const DataPublisherMainComponentStrings = LanguageStrings().DataPublisherStrings.DataPublisherMainComponentStrings

const DataPublisherMainComponent = (): JSX.Element => {
  const currSelectedTab = sessionStorage.getItem('currentSessionTab')
  const activeStyle = { fontSize: '12px', color: '#F0F0F0' }
  const inActiveStyle = { fontSize: '12px', color: '#A0A0A0' }
  const [currTab, setCurrTab] = React.useState((currSelectedTab === '0' ? 0 : currSelectedTab === '1' ? 1 : currSelectedTab === '2' ? 2 : 0))
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleChange = (event: React.SyntheticEvent, newValue: number): any => {
    setCurrTab(newValue)
    sessionStorage.setItem('currentSessionTab', '0')
  }

  const getStyle = (index: number): any => {
    if (index === currTab) return activeStyle
    return inActiveStyle
  }

  const getTopUtilityButtons = (): JSX.Element => {
    if (currTab === 0) {
      return (
        <span id='top-right-ami-utilities'>
          <span id='top-right-btn-span'>
            <Button id='top-right-ami-btn' className='deviceList' sx={{ margin: 5 }} width='fit-content' onClick={() => {
              navigate('/create-report-template')
            }} dataTestId='create-report-template-btn'>{DataPublisherMainComponentStrings.Create_New_Report_Template}</Button>
          </span>
        </span>
      )
    } else if (currTab === 2) {
      return (
        <span id='top-right-ami-utilities'>
          <span id='top-right-btn-span'>
            <Button id='top-right-ami-btn' className='deviceList' sx={{ margin: 5 }} width='fit-content' onClick={() => {
              navigate('/filestatus')
            }} dataTestId='create-config-btn'>{DataPublisherMainComponentStrings.View_Status_Files}</Button>
            <Button id='top-right-ami-btn' className='deviceList' sx={{ margin: 0 }} width='fit-content' onClick={() => {
              navigate('/addnewconfig')
              sessionStorage.setItem('configId', '')
              dispatch(changeConfigId(''))
            }} dataTestId='create-config-btn'>{DataPublisherMainComponentStrings.Add_Data_Publisher}</Button>
          </span>
        </span>
      )
    } else if (currTab === 1) {
      return (
        <span id='top-right-ami-utilities'>
          <span id='top-right-btn-span'>
            <Button id='top-right-ami-btn' className='deviceList' sx={{ margin: 5 }} width='fit-content' onClick={() => {
              navigate('/create-report-scheduler')
            }} dataTestId='create-report-template-btn'>{SchedulerTableStrings.createSchdulerbtn}</Button>
          </span>
        </span>
      )
    } else {
      return <></>
    }
  }

  return (
    <div style={{ width: 'calc(100vw - 3.5rem)', paddingRight: '1rem' }}>
      <div className='topUtilityBar'>
        <BackButton onclick={() => { navigate('/') }}/>
        <span id='componentTitle'>{DataPublisherMainComponentStrings.Data_Publisher}</span>
        { getTopUtilityButtons() }
      </div>
      <div className='reports-template'>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingLeft: 0 }}>
            <Tabs value={currTab} onChange={handleChange} aria-label="basic tabs example">
              <Tab style={getStyle(0)} label={DataPublisherMainComponentStrings.Report_Template} {...a11yProps(0)} />
              <Tab style={getStyle(1)} label={DataPublisherMainComponentStrings.Report_Schedule} {...a11yProps(1)} />
              {/* <Tab style={getStyle(1)} label={DataPublisherMainComponentStrings.Data_Publisher} {...a11yProps(1)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={currTab} index={0}>
            <ReportTemplate />
          </TabPanel>
          <TabPanel value={currTab} index={1}>
            <ReportScheduleMain />
          </TabPanel>
          <TabPanel value={currTab} index={2}>
            <AmiMeterCollectionDP />
          </TabPanel>
        </Box>
      </div>
    </div>
  )
}

export default DataPublisherMainComponent
