/* eslint-disable */
import * as React from 'react'
import InputLabel from '@mui/material/InputLabel'
import './assetHistory.css'
import MenuItem from '@mui/material/MenuItem'
import { CircularProgress } from '@mui/material'
import ReplayIcon from '@mui/icons-material/Replay'
import FilterIcon from '../../../assets/filterIcon.png'
import DataProcessing from '../../../assets/data_processing.svg'
import { type DeviceListState } from '../../ami-meter-data-collection/redux/deviceListSlice'
import { useAuth } from 'react-oidc-context'
import Drawer from '@mui/material/Drawer'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import AuthError from '../../../components/ErrorComponents/AuthError'
import Stack from '@mui/material/Stack'
import Autocomplete from '@mui/material/Autocomplete'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import DoNotDisturb from '@mui/icons-material/DoNotDisturb'
import CloseIcon from '@mui/icons-material/Close'
import Search from '@mui/icons-material/Search'
import FileExport from '../../../assets/exportIcon.svg'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import { useNavigate } from 'react-router-dom'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { visuallyHidden } from '@mui/utils'
import Pagination from '../../../components/TablePagination'
import LanguageStrings from '../../../i18n/locales'
import { connect, type ConnectedProps } from 'react-redux'
import { type RootState } from '../../../store'
import { type deviceDetailState } from '../redux/ShowAssetDetailsSlice'
import {
  getDeviceData,
  exportAssetProvisioningDetails,
  getAssetProvisioningDetailsFilterData,
  getAssetProvisioningDetailsFilterDeviceType,
  getAssetProvisioningDetailsFilterState,
  getProvisioningHistoryFilterStatus,
} from '../redux/actionCreators'
import { useSelector } from 'react-redux'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import { Button } from '@mui/material'
import { fetchDeviceList } from '../../ami-meter-data-collection/redux/actionCreators'
import AssignGroupPopUp from './AssignGroupPopUp'

const provisioningDetailStrings =
  LanguageStrings().ProvisioningDetailStrings.ProvisioningDetailTable
let searchValue: any

interface Data {
  provisioningDetailsId: string
  assetId: string
  name: string
  serialNumber: string
  configuration: string
  state: string
  assetGroup: string
  provisioningDetailsStatus: string
  provisioningErrorDescription: string
  deviceType: string
  provisioningHistoryId: string
}

const style = {
  position: 'absolute',
  top: '52%',
  left: '52%',
  transform: 'translate(-50%, -50%)',
  width: '93%',
  bgcolor: '#313030',
  border: '1px solid #101010',
  borderRadius: '10px',
  boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
  maxHeight: '90%',
  overflow: 'auto',
  padding: 0,
}

type Order = 'asc' | 'desc'

interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: 'assetId',
    numeric: false,
    disablePadding: true,
    label: provisioningDetailStrings.deviceId,
  },
  {
    id: 'serialNumber',
    numeric: false,
    disablePadding: false,
    label: provisioningDetailStrings.serialNumber,
  },
  {
    id: 'deviceType',
    numeric: false,
    disablePadding: false,
    label: provisioningDetailStrings.deviceType,
  },
  {
    id: 'provisioningDetailsStatus',
    numeric: false,
    disablePadding: false,
    label: provisioningDetailStrings.provisioningDetailsStatus,
  },
  {
    id: 'provisioningErrorDescription',
    numeric: false,
    disablePadding: false,
    label: provisioningDetailStrings.provisioningErrorDescription,
  }
]

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    newOrderBy: keyof Data
  ) => void
  order: Order
  orderBy: string
  rowCount: number
  numSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  assetRowCount: number
  dataList: any[]
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort,  onSelectAllClick, numSelected, assetRowCount, dataList } = props
  const createSortHandler =
    (newOrderBy: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy)
    }
  return (
    <TableHead data-testid="device-table-header">
      <TableRow>
      <StyledTableCell padding="checkbox">
                        <Checkbox
                            sx={{ color: '#909090' }}
                            data-testid="device-table-header-checkbox"
                            indeterminate={numSelected > 0 && numSelected < assetRowCount}
                            checked={assetRowCount > 0 && numSelected === assetRowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                              'aria-label': 'Select all Assets',
                              id: `select-all-items`
                            }}
                        />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              aria-label={headCell.label}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 16px',
    fontFamily: 'Honeywell Sans Web',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web',
  },
}))
function EnhancedTableToolbar(props: any) {
  const {
    rows,
    tenantId,
    handleClose,
    handleRefresh,
    clearAllClicked,
    setClearAllClicked,
    filterCalled,
    filterParams,
    status,
    statusState,
    setStatusState,
    assetType,
    assetTypeState,
    setAssetTypeState,
    detailState,
    state,
    setState,
    filterData,
    clearAllFilters,
  } = props
  const count = useSelector((state: any) => state.deviceDetail.totalCount)
  const [value, setValue] = React.useState('')
  const [searched, setSearched] = React.useState('')

  const onchangehandleSearch = (e: any) => {
    setValue(e.target.value)
    localStorage.setItem('Search', e.target.value)
  }

  React.useEffect(() => {
    const value = localStorage.getItem('Search')
    if (value) {
      setValue(value)
    }
  }, [])

  const handleCloseSearch = () => {
    localStorage.removeItem('Search')
    props.inputSearchFunction('')
    setValue('')
  }

  React.useEffect(() => {
    searchValue = searched
  }, [searched])
  const [openFilter, setOpenFilter] = React.useState(false)
  const handleStatusChange = (e: any) => {
    setStatusState({ ...statusState, [e.target.name]: e.target.checked })
  }
  const handleAssetTypeChange = (e: any) => {
    setAssetTypeState({ ...assetTypeState, [e.target.name]: e.target.checked })
  }
  const handleStateChange = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.checked })
  }

  const populateValues = () => {
    const tmp: any = {}
    const tmp1: any = {}
    const tmp2: any = {}

    for (let i = 0; i < status.length; i++) {
      if (
        filterParams.filter(
          (par: any) => par.field === 'provisioningDetailsStatus'
        ).length &&
        filterParams
          .filter((par: any) => par.field === 'provisioningDetailsStatus')[0]
          .values.includes(status[i])
      ) {
        tmp[status[i]] = true
      } else {
        tmp[status[i]] = false
      }
    }
    for (let i = 0; i < assetType.length; i++) {
      if (
        filterParams.filter((par: any) => par.field === 'deviceType').length &&
        filterParams
          .filter((par: any) => par.field === 'deviceType')[0]
          .values.includes(assetType[i])
      ) {
        tmp1[assetType[i]] = true
      } else {
        tmp1[assetType[i]] = false
      }
    }
    for (let i = 0; i < detailState.length; i++) {
      if (
        filterParams.filter((par: any) => par.field === 'state').length &&
        filterParams
          .filter((par: any) => par.field === 'state')[0]
          .values.includes(detailState[i])
      ) {
        tmp2[detailState[i]] = true
      } else {
        tmp2[detailState[i]] = false
      }
    }
    setStatusState(tmp)
    setAssetTypeState(tmp1)
    setState(tmp2)
  }

  return (

    <Toolbar
      sx={{
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
      }}
    >
      {' '}
      <IconButton
        sx={{ position: 'absolute', right: '10px', top: '5px' }}
        onClick={() => {
          handleClose(), localStorage.removeItem('Search')
        }}
      >
        <CloseIcon sx={{ color: '#f0f0f0' }} />
      </IconButton>
      <div style={{ width: '100%' }}>
        <Typography
          sx={{
            flex: '1 1 100%',
            fontFamily: 'Honeywell Sans Web',
            fontSize: '16px',
            fontWeight: 600,
            padding: '12px 24px',
            borderBottom: '2px solid #202020',
            borderRadius: '4px',
          }}
          id="tableTitle"
          component="div"
        >
          <span
            data-testid="provisionDetails"
            style={{
              fontSize: '16px',
              color: '#f0f0f0',
              borderRight: '2px solid #e0e0e0',
              paddingRight: '16px',
              marginRight: '10px',
            }}
          >
            {provisioningDetailStrings.showDetailsTitle}
          </span>
          <span style={{ fontSize: '16px', color: '#d0d0d0' }}>
            {count} {provisioningDetailStrings.assetCount}
          </span>
          <span>
          <Button className="refresh-btn" style={{ fontSize: '14px', color: '#d0d0d0', float: 'right', textTransform: 'capitalize', marginRight: '40px', backgroundColor: '#43b9ff', padding: '4px 10px', marginTop: '-4px' }} onClick={() => { props.handleRefresh()}}>Refresh</Button>
          </span>
        </Typography>
        <div className="filter-section">
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            sx={{ padding: '10px' }}
          >
            <div className="searchSection" style={{ width: '100%' }}>
              <Paper
                className="searchBoxComponent"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'left',
                  width: 370,
                }}
              >
                {!assetType.length && !status.length && !detailState.length ? (
                  <Tooltip title={provisioningDetailStrings.noDataFilter}>
                    <Button
                      data-testid="filter-data"
                      className="filter-btn"
                      sx={{ background: '#707070', cursor: 'not-allowed' }}
                    >
                      <img
                        src={FilterIcon}
                        height={16}
                        width={16}
                        style={{ marginLeft: '7px' }}
                      />
                      {provisioningDetailStrings.filterTitle}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    data-testid="filter-data"
                    className="filter-btn"
                    sx={{ background: '#707070', color:'#D0D0D0' }}
                    onClick={() => {
                      setOpenFilter(true)
                      populateValues()
                    }}
                  >
                    <img
                      src={FilterIcon}
                      height={16}
                      width={16}
                      style={{ marginLeft: '7px' }}
                    />
                    {provisioningDetailStrings.filterTitle}
                  </Button>
                )}

                <InputBase
                  className="searchBox"
                  sx={{ ml: 1, p: 1 }}
                  placeholder="Search the (placeholder)"
                  inputProps={{ 'aria-label': 'searchInput' }}
                  value={value}
                  onChange={onchangehandleSearch}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setSearched(value), props.inputSearchFunction(value)
                    }
                  }}
                />
                {value != '' ? (
                  <CloseIcon
                    onClick={handleCloseSearch}
                    className="search-close"
                  />
                ) : (
                  ''
                )}
                <IconButton
                  onClick={() => {
                    setSearched(value), props.inputSearchFunction(value)
                  }}
                  className="searchBtn"
                  type="button"
                  sx={{ p: '10px' }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
              <Drawer
                anchor="right"
                open={openFilter}
                className="filter-drawer"
                onClose={() => {
                  setOpenFilter(false)
                  if (filterCalled !== 'end') {
                    clearAllFilters()
                  }
                }}
              >
                <div className="filter-headar">
                  <div className="title">Filter</div>
                  <div>
                    <Button
                      aria-label="clear-all-filter"
                      className="cancel"
                      style={{ marginTop: '-2px', marginLeft: '80px' }}
                      onClick={() => {
                        clearAllFilters()
                        setClearAllClicked(true)
                      }}
                    >
                      <ReplayIcon
                        sx={{
                          width: '16px',
                          height: '16px',
                          marginRight: '8px',
                        }}
                      />
                      Clear All
                    </Button>
                  </div>
                  <div>
                    <CloseIcon
                      aria-label="close-drawer"
                      sx={{ color: '#f0f0f0', cursor: 'pointer' }}
                      onClick={() => setOpenFilter(false)}
                    />
                  </div>
                </div>

                {status && status.length !== 0 && (
                  <>
                    <div className="type-name status">Status</div>
                    <FormGroup>
                      {status.map((val: string) => (
                        <FormControlLabel
                          aria-label="status-change"
                          name={val}
                          onChange={(e) => {
                            handleStatusChange(e)
                          }}
                          checked={statusState[val]}
                          control={<Checkbox inputProps={{ id: 'select-item' }}  />}
                          label={val}
                        />
                      ))}
                    </FormGroup>
                    <div className="divider" />
                  </>
                )}
                {assetType && assetType.length !== 0 && (
                  <>
                    <div className="type-name">Asset Type</div>
                    <FormGroup>
                      {assetType.map((val: string) => (
                        <FormControlLabel
                          aria-label="asset-type-change"
                          name={val}
                          onChange={(e) => {
                            handleAssetTypeChange(e)
                          }}
                          checked={assetTypeState[val]}
                          control={<Checkbox inputProps={{ id: 'select-item' }} />}
                          label={val}
                        />
                      ))}
                    </FormGroup>
                    <div className="divider" />
                  </>
                )}
                {detailState && detailState.length !== 0 && (
                  <>
                    <div className="type-name">State</div>
                    <FormGroup>
                      {detailState.map((val: string) => (
                        <FormControlLabel
                          aria-label="state-change"
                          name={val}
                          onChange={(e) => {
                            handleStateChange(e)
                          }}
                          checked={state[val]}
                          control={<Checkbox inputProps={{ id: 'select-item' }} />}
                          label={val}
                        />
                      ))}
                    </FormGroup>

                    <div className="divider" />
                  </>
                )}
                <div className="buttons-container">
                  <Button
                    className="cancel"
                    aria-label="cancel-drawer"
                    onClick={() => {
                      setOpenFilter(false)
                      if (filterCalled !== 'end') {
                        clearAllFilters()
                      }
                    }}
                  >
                    {provisioningDetailStrings.cancelButton}
                  </Button>
                  <Button
                    className="confirm"
                    aria-label="confirm-filter"
                    onClick={() => {
                      filterData()
                      setOpenFilter(false)
                    }}
                  >
                    {provisioningDetailStrings.showResultButton}
                  </Button>
                </div>
              </Drawer>
            </div>
            <Tooltip title="Filter list">
              <IconButton
                sx={{ position: 'absolute', right: '25px' }}
                data-testid="exportFile"
                onClick={() => {
                  props.exportAssetProvisioningDetails(props.fileId, tenantId)
                }}
              >
                <img src={FileExport} height={25} width={25} />
              </IconButton>
            </Tooltip>
          </Stack>
        </div>
      </div>
    </Toolbar>
  )
}

interface Sort {
  order: Order
  orderBy: string
}

function ShowAssetDetails(props: Props) {
  const rowsPerPage = 10
  const navigate = useNavigate()
  const [assets, setAssets] = React.useState<any[]>([])
  const [selected, setSelected] = React.useState<string[]>([])
  const [page, setPage] = React.useState(1)
  const [sort, setSort] = React.useState<Sort>({
    order: 'asc',
    orderBy: 'name',
  })
  const count = useSelector((state: any) => state.deviceDetail.totalCount)
  const rows = useSelector((state: any) => state.deviceDetail.deviceData)
  const fileId = useSelector(
    (state: any) => state.deviceInfo?.message?.data?.fileId
  )
  const httpStatus = useSelector((state: any) => state.deviceDetail.httpStatus)
  const errorMessage = useSelector(
    (state: any) => state.deviceDetail.loadingError
  )
  const [statusState, setStatusState] = React.useState<any>({})
  const [assetTypeState, setAssetTypeState] = React.useState<any>({})
  const [state, setState] = React.useState<any>({})

  const [filterCalled, setFilterCalled] = React.useState<string>('')

  const handleRowClick = (assetId: string): void => {
    const isIdSelected = selected.includes(assetId)
    if (!isIdSelected && assetId !== null && assetId !== undefined ) {
      setSelected([...selected, assetId])
    } else {
      setSelected(selected.filter((selectedId) => selectedId !== assetId))
    }
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const currentPageAssets = rows.map((n: { assetId: any }) => n.assetId)
    if (event.target.checked) {
      const newSelected: string[] = []
      currentPageAssets.forEach((assetId: string) => {
        if (assetId !== null && !selected.includes(assetId)) {
          newSelected.push(assetId)
        }
      })
      setSelected([...selected, ...newSelected])
    } else {
      const newSelected: string[] = []
      selected.forEach((assetId) => {
        if (assetId !== null && !currentPageAssets.includes(assetId)) {
          newSelected.push(assetId)
        }
      })
      setSelected(newSelected)
    }
  }

  const isSelected = (assetId: string): boolean => selected.includes(assetId)


  const status = useSelector(
    (state: any) =>
      state.provisioningHistoryFilterStatus.provisioningHistoryFilterStatus
  )
  const detailState = useSelector(
    (state: any) =>
      state.provisioningHistoryDetailsStateFilter
        .provisioningHistoryDetailsStateFilter
  )
  const assetType = useSelector(
    (state: any) =>
      state.provisioningHistoryDetailsFilterDeviceType
        .provisioningHistoryDetailsFilterDeviceType
  )

  let search: any = ''
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& th': {
      border: '1px solid #202020',
    },
  }))

  React.useEffect(() => {
    resetStatus()
  }, [status])
  React.useEffect(() => {
    resetAssetType()
  }, [assetType])
  React.useEffect(() => {
    resetState()
  }, [detailState])
  const resetStatus = () => {
    const tmp: any = {}
    for (const item of status) {
      tmp[item] = false
    }
    setStatusState(tmp)
  }
  const resetAssetType = () => {
    const tmp: any = {}
    for (const item of assetType) {
      tmp[item] = false
    }
    setAssetTypeState(tmp)
  }
  const resetState = () => {
    const tmp: any = {}
    for (const item of detailState) {
      tmp[item] = false
    }
    setState(tmp)
  }
  const clearAllFilters = () => {
    resetAssetType()
    resetState()
    resetStatus()
  }

  const [filterParams, setFilterParams] = React.useState<any>([])
  const [selectedStatus, setSelectedStatus] = React.useState<any>([])
  const [selectedAssetType, setSelectedAssetType] = React.useState<any>([])
  const [selectedState, setSelectedState] = React.useState<any>([])
  const [clearAllClicked, setClearAllClicked] = React.useState(false)
  const [showAssignGroupPopUp, setShowAssignGroupPopUp] = React.useState('')
  const auth = useAuth()


  const onAssetGroupPopUpClose = (): void => {
    setShowAssignGroupPopUp('')
    props.handleClose()
  }

  React.useEffect(() => {
    const extractedTrueValues = Object.keys(statusState).filter(
      (key) => statusState[key] === true
    )
    setSelectedStatus(extractedTrueValues)
  }, [statusState])
  React.useEffect(() => {
    const extractedTrueValues = Object.keys(assetTypeState).filter(
      (key) => assetTypeState[key] === true
    )
    setSelectedAssetType(extractedTrueValues)
  }, [assetTypeState])
  React.useEffect(() => {
    const extractedTrueValues = Object.keys(state).filter(
      (key) => state[key] === true
    )
    setSelectedState(extractedTrueValues)
  }, [state])
  React.useEffect(() => {
    if (filterCalled === 'start') {
      const arr = []
      if (selectedStatus.length) {
        arr.push({
          field: 'provisioningDetailsStatus',
          operator: 'IN',
          values: selectedStatus,
        })
      }

      if (selectedAssetType.length) {
        arr.push({
          field: 'deviceType',
          operator: 'IN',
          values: selectedAssetType,
        })
      }
      if (selectedState.length) {
        arr.push({
          field: 'state',
          operator: 'IN',
          values: selectedState,
        })
      }
      if (!arr.length && clearAllClicked) {
        props.getDeviceData(
          page,
          rowsPerPage,
          sort.order,
          sort.orderBy,
          fileId,
          search,
          auth.user?.profile.customer as string
        )
      }
      setFilterParams(arr)
    }
  }, [filterCalled])

  const callSearchValue = (e: any) => {
    search = e
    setPage(1)
    props.getDeviceData(
      1,
      rowsPerPage,
      sort.order,
      sort.orderBy,
      fileId,
      search,
      auth.user?.profile.customer as string
    )
  }

  React.useEffect(() => {
    if (filterParams.length !== 0) {
      props.getAssetProvisioningDetailsFilterData(
        page,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        fileId,
        filterParams,
        auth.user?.profile.customer as string
      )
    } else {
      props.getDeviceData(
        page,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        fileId,
        search != ''
          ? search
          : localStorage.getItem('Search') == null
          ? search
          : localStorage.getItem('Search'),
        auth.user?.profile.customer as string
      )
    }
  }, [page, sort])

  React.useEffect(() => {
    props.getAssetProvisioningDetailsFilterDeviceType(
      auth.user?.profile.customer as string
    )
    props.getAssetProvisioningDetailsFilterState(
      auth.user?.profile.customer as string
    )
    props.getProvisioningHistoryFilterStatus(
      auth.user?.profile.customer as string
    )
  }, [])

  const getFilterDeviceType =() =>{
    props.getAssetProvisioningDetailsFilterDeviceType(
      auth.user?.profile.customer as string
    )
  }

  const filterData = () => {
    setFilterCalled('start')
  }
  React.useEffect(() => {
    setPage(1)
    if (filterParams.length !== 0) {
      props.getAssetProvisioningDetailsFilterData(
        1,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        fileId,
        filterParams,
        auth.user?.profile.customer as string
      )
      setFilterCalled('end')
    } else {
      if (filterCalled === 'start') {
        props.getDeviceData(
          1,
          rowsPerPage,
          sort.order,
          sort.orderBy,
          fileId,
          search != ''
            ? search
            : localStorage.getItem('Search') == null
            ? search
            : localStorage.getItem('Search'),
          auth.user?.profile.customer as string
        )
        setFilterCalled('end')
      }
    }
  }, [filterParams])

  const handleRetry = () => {
    props.getDeviceData(
      page,
      rowsPerPage,
      sort.order,
      sort.orderBy,
      fileId,
      search,
      auth.user?.profile.customer as string
    )
  }

  const handleRefresh = () => {
    props.getDeviceData(
      page,
      rowsPerPage,
      sort.order,
      sort.orderBy,
      fileId,
      search,
      auth.user?.profile.customer as string
    )
  }

  const showAsseList = () => {
    props.fetchDeviceList(
      0,
      10,
      'asc',
      'id',
      auth.user?.profile.customer as string as string
    )
    props.handleClose()
    navigate('/asset-list')
    localStorage.removeItem('Search')
  }
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    newOrderBy: keyof Data
  ) => {
    const isAsc = sort.orderBy === newOrderBy && sort.order === 'asc'
    const toggledOrder = isAsc ? 'desc' : 'asc'
    setSort({ order: toggledOrder, orderBy: newOrderBy })
  }
  if (rows !== null && rows !== undefined && httpStatus === 200) {
    return (
      <>
        <Box sx={style} className="devices">
          <Paper
            sx={{
              width: "100%",
              mb: 2,
              backgroundColor: "#272727",
              color: "#F0F0F0",
            }}
          >
            <EnhancedTableToolbar
              handleClose={props.handleClose}
              handleRefresh={handleRefresh}
              rows={rows}
              inputSearchFunction={callSearchValue}
              fileId={fileId}
              tenantId={auth.user?.profile.customer as string}
              exportAssetProvisioningDetails={
                props.exportAssetProvisioningDetails
              }
              filterParams={filterParams}
              clearAllClicked={clearAllClicked}
              setClearAllClicked={setClearAllClicked}
              filterCalled={filterCalled}
              status={status}
              statusState={statusState}
              setStatusState={setStatusState}
              assetType={assetType}
              assetTypeState={assetTypeState}
              setAssetTypeState={setAssetTypeState}
              detailState={detailState}
              state={state}
              setState={setState}
              filterData={filterData}
              clearAllFilters={clearAllFilters}
            />

            {filterParams.length !== 0 && (
              <div
                style={{
                  padding: "12px 24px",
                  borderTop: "2px solid #202020",
                  color: "#d0d0d0",
                }}
              >
                {filterParams.length === 0 ? (
                  "No filters applied"
                ) : (
                  <div className="classNAme">
                    {count} Results | Filters :
                    <>
                      {filterParams.filter(
                        (par: any) => par.field === "provisioningDetailsStatus"
                      ).length !== 0 &&
                        filterParams.filter(
                          (par: any) =>
                            par.field === "provisioningDetailsStatus"
                        )[0].values.length !== 0 && (
                          <div className="box">
                            {" "}
                            Status:{" "}
                            {filterParams.filter(
                              (par: any) =>
                                par.field === "provisioningDetailsStatus"
                            )[0].values.length + " selected"}
                          </div>
                        )}
                      {filterParams.filter(
                        (par: any) => par.field === "deviceType"
                      ).length !== 0 &&
                        filterParams.filter(
                          (par: any) => par.field === "deviceType"
                        )[0].values.length !== 0 && (
                          <div className="box">
                            {" "}
                            AssetType:{" "}
                            {filterParams.filter(
                              (par: any) => par.field === "deviceType"
                            )[0].values.length + " selected"}
                          </div>
                        )}
                      {filterParams.filter((par: any) => par.field === "state")
                        .length !== 0 &&
                        filterParams.filter(
                          (par: any) => par.field === "state"
                        )[0].values.length !== 0 && (
                          <div className="box">
                            {" "}
                            State:{" "}
                            {filterParams.filter(
                              (par: any) => par.field === "state"
                            )[0].values.length + " selected"}
                          </div>
                        )}
                    </>
                    <em
                      onClick={() => {
                        clearAllFilters();
                        setFilterParams([]);
                        props.getDeviceData(
                          page,
                          rowsPerPage,
                          sort.order,
                          sort.orderBy,
                          fileId,
                          search,
                          auth.user?.profile.customer as string
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Clear Filters{" "}
                    </em>
                  </div>
                )}
              </div>
            )}
            {rows.length !== 0 ? (
              <>
                <TableContainer className="device-table">
                  <Table
                    sx={{ minWidth: 750, color: "#f0f0f0" }}
                    aria-labelledby="tableTitle"
                    size={"small"}
                  >
                    <EnhancedTableHead
                      numSelected={
                        selected.filter((assetId) =>
                          rows
                            .filter(
                              (asset: { assetId: null }) =>
                                asset.assetId !== null
                            )
                            .map((asset: { assetId: any }) => asset.assetId)
                            .includes(assetId)
                        ).length
                      }
                      onSelectAllClick={handleSelectAllClick}
                      assetRowCount={
                        rows.filter(
                          (asset: { assetId: null }) => asset.assetId !== null
                        ).length
                      }
                      dataList={assets}
                      order={sort.order}
                      orderBy={sort.orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={10}
                    />
                    <TableBody>
                      {rows &&
                        rows.map((row: any, index: any) => {
                          const isItemSelected = isSelected(row.assetId);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <StyledTableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              aria-checked={isItemSelected}
                              key={row.provisioningDetailsId}
                              aria-label="devices-list-row"
                              selected={isItemSelected}
                              sx={{ cursor: "pointer" }}
                            >
                              <StyledTableCell padding="checkbox">
                                <Checkbox
                                  sx={{ color: "#909090" }}
                                  data-testid={`row-checkbox-${index}`}
                                  checked={isItemSelected}
                                  onChange={() => {
                                    handleRowClick(row.assetId);
                                  }}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                    "aria-label": "test-checkbox",
                                    id: `select-item-${index}`
                                  }}
                                />
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                sx={{ color: "#64C3FF !important" }}
                              >
                                {row.assetId}
                              </StyledTableCell>
                              <StyledTableCell>
                                {row.serialNumber}
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{ color: "#64C3FF !important" }}
                              >
                                {row.deviceType}
                              </StyledTableCell>
                              <StyledTableCell>
                                <div
                                  className={
                                    row.provisioningDetailsStatus.toLowerCase() ===
                                    "success"
                                      ? "success-provision"
                                      : "error-provision"
                                  }
                                  style={{
                                    padding: "4px 8px",
                                    height: "24px",
                                    borderRadius: "16px",
                                  }}
                                >
                                  {row.provisioningDetailsStatus.toLowerCase() ===
                                  "success" ? (
                                    <TaskAltIcon
                                      sx={{
                                        fontSize: "medium",
                                        paddingRight: "3px",
                                      }}
                                    />
                                  ) : (
                                    <DoNotDisturb
                                      sx={{
                                        fontSize: "medium",
                                        paddingRight: "3px",
                                      }}
                                    />
                                  )}
                                  <span style={{}}>
                                    {row.provisioningDetailsStatus}
                                  </span>
                                </div>
                              </StyledTableCell>
                              <StyledTableCell>
                                {row.provisioningErrorDescription}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                {count > 10 && (<Pagination
                  count={count}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  siblingCount={1}
                  boundaryCount={1}
                />)}
              </>
            ) : filterParams?.length === 0 &&
              localStorage.getItem("Search") === null ? (
              <>
                <div className="authError">
                  {" "}
                  <div className="errorComp showDetailsDataError">
                    <img src={DataProcessing} className="errorComp1" />
                    <div className="errorCompContent">
                      {provisioningDetailStrings.dataProcessing}
                    </div>
                    <div>{provisioningDetailStrings.refreshText}</div>
                    <Button
                      className="DataErrorBtn"
                      onClick={() => {
                        props.getDeviceData(
                          1,
                          rowsPerPage,
                          sort.order,
                          sort.orderBy,
                          fileId,
                          search != ""
                            ? search
                            : localStorage.getItem("Search") == null
                            ? search
                            : localStorage.getItem("Search"),
                          auth.user?.profile.customer as string
                        );
                        props.getAssetProvisioningDetailsFilterDeviceType(
                          auth.user?.profile.customer as string
                        );
                      }}
                    >
                      Refresh
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <div className="authError">
                <AuthError errorMessage="NoDataPresent" />
              </div>
            )}
          </Paper>
          <div className="button-container">
            {selected.length > 0 && (
              <button
                onClick={() => {
                  setShowAssignGroupPopUp("true");
                }}
                style={{ width: "129px" }}
              >
                Group Assets
              </button>
            )}
            <button
              data-testid="goto-asset-list"
              onClick={() => {
                showAsseList();
              }}
              style={{ width: "129px" }}
            >
              {provisioningDetailStrings.assetListBtn}
            </button>
          </div>
        </Box>
        {showAssignGroupPopUp !== "" && (
          <AssignGroupPopUp
            onAssetGroupPopUpClose={onAssetGroupPopUpClose}
            selected={selected}
          />
        )}
      </>
    );
  } else if (props.deviceDetail?.isLoading) {
    return (
      <div className="CircularprogressAmi">
        <CircularProgress />
      </div>
    )
  } else if (httpStatus === 401) {
    return (
      <div className="authError">
        <AuthError errorMessage="Unauthorized" />
      </div>
    )
  } else if (httpStatus === 403) {
    return (
      <div className="authError">
        <AuthError errorMessage="accessForbidden" />
      </div>
    )
  } else if (
    httpStatus === 500 &&
    errorMessage === 'Asset Provisioning is in Progress'
  ) {
    return (
      <Box sx={style} className="devices">
        <Paper
          sx={{
            width: '100%',
            mb: 2,
            backgroundColor: '#272727',
            color: '#F0F0F0',
          }}
        >
          <EnhancedTableToolbar
            handleClose={props.handleClose}
            rows={rows}
            inputSearchFunction={callSearchValue}
            fileId={fileId}
            tenantId={auth.user?.profile.customer as string}
            exportAssetProvisioningDetails={
              props.exportAssetProvisioningDetails
            }
            filterParams={filterParams}
            clearAllClicked={clearAllClicked}
            setClearAllClicked={setClearAllClicked}
            filterCalled={filterCalled}
            status={status}
            statusState={statusState}
            setStatusState={setStatusState}
            assetType={assetType}
            assetTypeState={assetTypeState}
            setAssetTypeState={setAssetTypeState}
            detailState={detailState}
            state={state}
            setState={setState}
            filterData={filterData}
            clearAllFilters={clearAllFilters}
          />
          {filterParams.length !== 0 && (
            <div
              style={{
                padding: '12px 24px',
                borderTop: '2px solid #202020',
                color: '#d0d0d0',
              }}
            >
              {filterParams.length === 0 ? (
                'No filters applied'
              ) : (
                <div className="classNAme">
                  {count} Results | Filters :
                  <>
                    {filterParams.filter(
                      (par: any) => par.field === 'provisioningDetailsStatus'
                    ).length !== 0 &&
                      filterParams.filter(
                        (par: any) => par.field === 'provisioningDetailsStatus'
                      )[0].values.length !== 0 && (
                        <div className="box">
                          {' '}
                          Status:{' '}
                          {filterParams.filter(
                            (par: any) =>
                              par.field === 'provisioningDetailsStatus'
                          )[0].values.length + ' selected'}
                        </div>
                      )}
                    {filterParams.filter(
                      (par: any) => par.field === 'deviceType'
                    ).length !== 0 &&
                      filterParams.filter(
                        (par: any) => par.field === 'deviceType'
                      )[0].values.length !== 0 && (
                        <div className="box">
                          {' '}
                          AssetType:{' '}
                          {filterParams.filter(
                            (par: any) => par.field === 'deviceType'
                          )[0].values.length + ' selected'}
                        </div>
                      )}
                    {filterParams.filter((par: any) => par.field === 'state')
                      .length !== 0 &&
                      filterParams.filter(
                        (par: any) => par.field === 'state'
                      )[0].values.length !== 0 && (
                        <div className="box">
                          {' '}
                          State:{' '}
                          {filterParams.filter(
                            (par: any) => par.field === 'state'
                          )[0].values.length + ' selected'}
                        </div>
                      )}
                  </>
                  <em
                    onClick={() => {
                      clearAllFilters()
                      setFilterParams([])
                      props.getDeviceData(
                        page,
                        rowsPerPage,
                        sort.order,
                        sort.orderBy,
                        fileId,
                        search,
                        auth.user?.profile.customer as string
                      )
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    Clear Filters{' '}
                  </em>
                </div>
              )}
            </div>
          )}
          <div className="authError">
            <div className="errorComp showDetailsDataError">
              <img src={DataProcessing} className="errorComp1" />
              <div className="errorCompContent">
                {provisioningDetailStrings.dataProcessing}
              </div>
              <div>{provisioningDetailStrings.refreshText}</div>
              <Button
                className="DataErrorBtn"
                onClick={() => {
                  props.getDeviceData(
                    1,
                    rowsPerPage,
                    sort.order,
                    sort.orderBy,
                    fileId,
                    search != ''
                      ? search
                      : localStorage.getItem('Search') == null
                      ? search
                      : localStorage.getItem('Search'),
                    auth.user?.profile.customer as string
                  )
                  props.getAssetProvisioningDetailsFilterDeviceType(
                    auth.user?.profile.customer as string
                  )
                }}
              >
                Refresh
              </Button>
            </div>
          </div>
        </Paper>
        <div className="button-container">
          <button
            data-testid="goto-asset-list"
            onClick={() => {
              showAsseList()
            }}
            style={{ width: '129px' }}
          >
            {provisioningDetailStrings.assetListBtn}
          </button>
        </div>
      </Box>
    )
  } else {
    return (
      <div className="authError">
        <AuthError errorMessage="cannotFetch" retry={handleRetry} />
      </div>
    )
  }
}
const mapDispatchToProps = (dispatch: any) => ({
  fetchDeviceList: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    tenantId: string
  ) => dispatch(fetchDeviceList(page, size, sortdir, sortfield, tenantId)),
  getDeviceData: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    fileId: number,
    search: any,
    tenantId: string
  ) =>
    dispatch(
      getDeviceData(page, size, sortdir, sortfield, fileId, search, tenantId)
    ),
  getAssetProvisioningDetailsFilterData: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    fileId: number,
    filterParams: any,
    tenantId: string
  ) =>
    dispatch(
      getAssetProvisioningDetailsFilterData(
        page,
        size,
        sortdir,
        sortfield,
        fileId,
        filterParams,
        tenantId
      )
    ),
  getAssetProvisioningDetailsFilterDeviceType: (tenantId: string) =>
    dispatch(getAssetProvisioningDetailsFilterDeviceType(tenantId)),
  getAssetProvisioningDetailsFilterState: (tenantId: string) =>
    dispatch(getAssetProvisioningDetailsFilterState(tenantId)),
  getProvisioningHistoryFilterStatus: (tenantId: string) =>
    dispatch(getProvisioningHistoryFilterStatus(tenantId)),
  exportAssetProvisioningDetails: (fileId: string, tenantId: string) =>
    dispatch(exportAssetProvisioningDetails(fileId, tenantId)),
})

interface StateToProps {
  deviceDetail: deviceDetailState
  deviceList: DeviceListState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  deviceDetail: state.deviceDetail,
  deviceList: state.deviceList,
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
interface Props extends PropsFromRedux {
  handleClose: any
}
export default connector(ShowAssetDetails)
