/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useEffect, useState } from 'react'
import { type AlarmFeatureCollection } from './points'
import { APIProvider, Map, useMap } from '@vis.gl/react-google-maps'
import ClusteredMarkers from './GeoJson/clustered-markers'
import lowBadge from '../../assets/lowBadge.svg'
import highBadge from '../../assets/maps/highBadge.png'
import mediumBadge from '../../assets/mediumBadge.svg'
import CriticalBadge from '../../assets/criticalBadge.svg'
import mapOptions from '../../assets/maps/mapOptions.json'
import './Maps.css'
import { connect, type ConnectedProps } from 'react-redux'
import { type RootState } from '../../store'
import { type TopIssuesForPositionState, type TopIssuesForPosition, type TopIssuesGeoJsonState } from './types'
import { getTopIssuesGeoJson } from './redux/actionCreator'
import { CircularProgress } from '@mui/material'

export const nortBadge = (severity: string): any => {
  switch (severity.toLowerCase()) {
    case 'low':
      return lowBadge
    case 'medium':
      return mediumBadge
    case 'high':
      return highBadge
    case 'critical':
      return CriticalBadge
    default:
      return lowBadge
  }
}

export const options: google.maps.MapOptions = {
  styles: mapOptions
}

export interface InfoDataType {
  topIssues: TopIssuesForPosition[]
  position: google.maps.LatLngLiteral
}

const GeoJsonMap = (props: PropsFromRedux): JSX.Element => {
  const GOOGLE_MAPS_API_KEY =
    typeof window._env_?.REACT_APP_GOOGLE_MAPS_API_KEY === 'string'
      ? window._env_.REACT_APP_GOOGLE_MAPS_API_KEY
      : ''

  const [geojson, setGeojson] = useState<AlarmFeatureCollection | null>(null)

  useEffect(() => {
    if (props.topIssuesGeoJson.httpStatus !== 200 && !props.topIssuesGeoJson.loading) {
      props.getTopIssuesGeoJson(true, 20000)
    }
  }, [])

  useEffect(() => {
    if (props.topIssuesGeoJson.geoJson !== undefined && props.topIssuesGeoJson.httpStatus === 200) {
      setGeojson(props.topIssuesGeoJson.geoJson)
    }
  }, [props.topIssuesGeoJson])

  if (props.topIssuesGeoJson.loading) {
    return (
      <>
        {
          (props.topIssuesGeoJson.loading || props.topIssuesForPosition.loading) &&
          <div data-testid='map-loading-element' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', zIndex: 1000 }}>
            <CircularProgress />
          </div>
        }
      </>
    )
  }

  return (
    <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
      <Map
        reuseMaps
        mapId={'87ad6eef2f598422'}
        defaultCenter={{ lat: 20, lng: 20 }}
        defaultZoom={3}
        gestureHandling={'greedy'}
        disableDefaultUI
        className={'custom-marker-clustering-map'}
        zoomControl={true}
        fullscreenControl={true}>
        {geojson !== null && (
          <ClusteredMarkersWrapper
            geojson={geojson}
          />
        )}
      </Map>
    </APIProvider>
  )
}

// istanbul ignore next
const ClusteredMarkersWrapper = (props: { geojson: AlarmFeatureCollection }): JSX.Element => {
  const map = useMap()

  const handleClusterClick = (position: google.maps.LatLngLiteral): void => {
    if (map !== null) {
      map.panTo(position)
      map.setZoom((map.getZoom() as number) + 1)
    }
  }

  return (
    <ClusteredMarkers
      handleClusterClick={handleClusterClick}
      geojson={props.geojson}
    />
  )
}

interface DispatchToProps {
  getTopIssuesGeoJson: (unPaged: boolean, limit: number) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  getTopIssuesGeoJson: (unPaged: boolean, limit: number) => dispatch(getTopIssuesGeoJson(unPaged, limit))
})

interface StateToProps {
  topIssuesGeoJson: TopIssuesGeoJsonState
  topIssuesForPosition: TopIssuesForPositionState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  topIssuesGeoJson: state.topIssuesGeoJson,
  topIssuesForPosition: state.topIssuesForPosition
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(GeoJsonMap)
