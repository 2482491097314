import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type DonutKpiDataObject, type DonutKpiState } from '../../types'

const initialState: DonutKpiState = {
  isLoading: true,
  error: false,
  data: undefined,
  httpStatus: 0
}

const firmwareBreakdownDonutSlice = createSlice({
  name: 'firmwareBreakdownDonut',
  initialState,
  reducers: {
    firmwareBreakdownDonutLoadingAction: (state) => {
      return {
        isLoading: true,
        error: false,
        data: undefined,
        httpStatus: 0
      }
    },
    firmwareBreakdownDonutUpdateAction: (state, action: PayloadAction<{ data: DonutKpiDataObject, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: false,
        data: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    firmwareBreakdownDonutErrorAction: (state, action: PayloadAction<number>) => {
      return {
        isLoading: false,
        error: true,
        data: undefined,
        httpStatus: action.payload
      }
    }
  }
})

export const {
  firmwareBreakdownDonutLoadingAction,
  firmwareBreakdownDonutUpdateAction,
  firmwareBreakdownDonutErrorAction
} = firmwareBreakdownDonutSlice.actions

export default firmwareBreakdownDonutSlice.reducer
